import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import React, {Component} from "react";
import SimpleCompForTesting from "../features/SimpleCompForTesting";
import SecondCompForTesting from "../features/SecondCompForTesting";
import Container from "@material-ui/core/Container";
const styles = {
    initial: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    description: {
        fontWeight: '100',
        fontSize: '2rem',
    },
};

const stage_components = {
    initial: <SimpleCompForTesting/>,
    secondary: <SecondCompForTesting/>
};

class PageComponentRouter extends Component{

    constructor(props) {
        super(props);
    }


    render () {

        console.log('> [SinglePageComponentRouter] - Props: ', this.props);
        const {classes, specifiedClassName} = this.props;

        return (
            <Container>
                {stage_components[specifiedClassName]}
            </Container>

        );
    }


}

//{ app_classes, specifiedClassName }
export default withStyles(styles)(PageComponentRouter)

//                <Button className={classes[specifiedClassName]} variant={"contained"}> Ballsack MacGee</Button>