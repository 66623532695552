import React, {useState, useEffect, Component, Fragment} from 'react';
import { Backdrop, Button, Container, Paper, Link, CircularProgress, Modal, Grid, Typography} from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImagesFromFlickr from "./ImagesFromFlickr";
import BackdropImageButton from "./BackdropImageButton";
import CustomBackdrop from "./CustomBackdrop";
import BackdropExample from "./BackdropExample";
import Carousel from "react-images";


const useStyles = makeStyles((theme) => ({


    backdrop: {
        //color: '#fff',
        //height: '100%',
        //width: '100%',
        //paddingTop: '40%',
        //paddingBottom: '40%',
        //overflowY: 'scroll',
        //zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down(900)]: {
            //maxHeight: '600px',
        },
        [theme.breakpoints.up(901)]: {
           // maxHeight: '1200px',
        },


    },


    ImageGallery: {/* overflowX: 'scroll*/},


    BackdropContainer: {
        [theme.breakpoints.down(900)]: {
            //marginTop: '40%',
            //paddingRight: '16px',
            //paddingTop: '45%',
            //paddingBottom: '30%',
            //marginBottom: '30%',
        },
        [theme.breakpoints.up(901)]: {
            //marginTop: '40%',
            //paddingRight: '16px',
            //paddingTop: '45%',
            //paddingBottom: '30%',
            //marginBottom: '30%',
        },
    },

    ButtonAndBackdropContainer: {
        //flex: '0 0 auto',
        marginLeft: 'auto',
        marginRight: 'auto',

    },

    ShowcaseElementPaperMaster: {
        //marginBottom: '3rem',
        //backgroundImage: "url('../../bluegreypaper.jpg')",
        overflowX: 'hidden',
    },

    ShowcaseElementPaperChild: {
        marginBottom: '-0.4rem',
        //paddingBottom: '1rem',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
        overflowX: 'hidden',
        width: '100%',
        borderLeft: 'double',
        border: 'outset',
        borderLeftWidth: 'thick',
        borderLeftColor: '#feffff',
        borderTopWidth: 'thin',
        borderBottomWidth: 'medium',
    },

    ToggleBackdropButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderTop: 'inset',
        borderTopWidth: 'thin',
        width: '100%',
        color: '#2b2b2b',
        backgroundImage: "url('../../blue-button.jpg')",
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 800,
        marginBottom: '2rem',
        marginTop: '1.15rem',
        border: 'outset',
        //borderLeftWidth: 'thick',
    },

}));






export default class PopupGallery extends Component{


    constructor(props) {
        super(props);
        this.state = {
            firstTime: true,
        };
    }


    componentDidMount() { this.setState({firstTime: false}) }


    render () {

        //console.log("POPUPGALLERY PROPS: ", this.props);
        const showcase_details = this.props.showcase_details;


        return (
            <Fragment>
                    {showcase_details.map((showcase, index) => {
                        return (
                            <Fragment>
                                <ButtonImageAndBackdrop {...showcase} index={showcase.index}/>
                            </Fragment>

                            );
                    })}
            </Fragment>
        );
    }
}








function ButtonImageAndBackdrop(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [zIndex, setZIndex] = React.useState(0);
    const [startedLoading, setStartedLoading] = React.useState(0); // init with not t/f, but with 0
    const timer = React.useRef();
    // Using on Backdrop component results in entire screen becoming button b/c backdrop covers whole screen, even
    // when not visible. This is why we use handleClose()


    const toggleOnceLoaded = async () => {
        setStartedLoading(true) 
        let divelement = document.getElementById('refined-gallery'+ props.index)

        const handleHasLoaded = async (divelement) => {
            divelement.style.display = 'grid';
            setStartedLoading(false)
            setOpen(!open);
            setZIndex(1400);
        }

        const twoSecondTimer = async () => {
            timer.current = window.setTimeout(() => {
              //setSubmissionComplete(true);
              toggleOnceLoaded(divelement)
            }, 2000);
        };

        const hasLoaded = async(divelement, cb) => {
            if (divelement === null) cb(divelement, false)
            else cb(divelement, true)
        }

        
        hasLoaded(divelement, async (divelement, loaded) => {
            if (loaded === true) { handleHasLoaded(divelement) }
            else {twoSecondTimer()} // call the parent function
        })
        

    }

    

    const handleClose = () => {
        let refinedGalleryDIV = 'refined-gallery'+ props.index;
        document.getElementById(refinedGalleryDIV).style.display = 'none';
        setOpen(false);
        setZIndex(0);
    };

    const handleToggle = () => {
        let refinedGalleryDIVName = 'refined-gallery'+ props.index;
        let refinedGalleryDIV = document.getElementById(refinedGalleryDIVName)
        toggleOnceLoaded()
        //if (refinedGalleryDIV === null) twoSecondTimer(alert(refinedGalleryDIV.style.display))
        //let refinedGalleryDIV = 'refined-gallery'+ props.index;
        //document.getElementById(refinedGalleryDIV).style.display = 'grid';
        //setOpen(!open);
        //setZIndex(1400);
    };


    //console.log('ButtonImageANdBackdrop: ', props.index);
    return (

        <div className={classes.ButtonAndBackdropContainer} id={'ButtonAndBackdropContainer' + props.index}>

            < p style={{marginBottom: '.9rem'}}>
                ⭐ <b>Max McGill Production Co.</b>
                —  <Link href="https://www.maxmcgillproduction.com">https://www.maxmcgillproduction.com </Link>
            </p>
            <Paper elevation={3} className={classes.ShowcaseElementPaperMaster} >


                <Paper elevation={2} id={'LightGreenBackgroundTexture'} className={classes.ShowcaseElementPaperChild}>

                    <BackdropImageButton id={'BackdropImageButton' + props.index}
                                         {...{
                                             image_file: props.cover_image_url,
                                             index: props.index
                                         }}
                                         onClick={handleToggle}  // onClick ={ () => {} }
                    />

                </Paper>



            {startedLoading === true ? (
               <Modal 
               open={true}
               
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description" 
               >
                   <Grid
                   container
                   spacing={0}
                   direction="column"
                   alignItems="center"
                   justify="center"
                   style={{ minHeight: '100vh' }}
                   >

                       <Grid item xs={6} justify="center" alignItems="center"
                       style={{
                           alignItems: "center",
                           justifyContent: "center",
                           display: "flex",
                           flexFlow: "column",
                       }}>
                           <Typography
                           style={{
                               color: 'floralwhite',
                               fontSize: '1.15rem',
                               marginBottom: '1rem',
                               marginLeft: '.5rem'
                           }}>
                               Loading HD images...
                           </Typography>
                           <CircularProgress justify="center" style={{display: 'block'}}/>
                           
                       </Grid>   

                   </Grid> 
             </Modal>
            ):null}                        
             

            {window.screen.availWidth <= 901 ? (
                    <Backdrop id={'custom-backdrop' + props.index}
                              index={props.index}
                              className={classes.backdrop}
                              open={open}
                              onClick={handleClose}
                              style={{
                                  //marginTop: '40%',
                                  //paddingRight: '16px',
                                  //paddingTop: '45%',
                                  //paddingBottom: '30%',
                                  //marginBottom: '30%',
                                  zIndex: zIndex,
                              }}
                    >
                        <Container className={'BackdropContainer'}>
                            <ImagesFromFlickr className={classes.ImageGallery}
                                              {...{album_name: props.flickr_album_name,index: props.index}}/>
                        </Container>
                    </Backdrop>
                ) :

                <Backdrop id={'custom-backdrop' + props.index}
                          className={classes.backdrop}
                          open={open}
                          onClick= {handleClose}
                          style={{
                              //marginTop: '40%',
                              //paddingRight: '16px',
                              //paddingTop: '45%',
                              //paddingBottom: '30%',
                              //marginBottom: '30%',
                              zIndex: zIndex,
                          }}
                >
                    <ImagesFromFlickr className={classes.ImageGallery} {...{album_name: props.flickr_album_name, index: props.index}} />

                </Backdrop>




            }
            <Button className={classes.ToggleBackdropButton} onClick={() =>  {/** Desktop toggle for the gallery**/handleToggle()}} variant="contained" color="primary">View Highlights</Button>
            </Paper>
        </div>
    );
}

/**
 <Container className={'BackdropContainer'}>
 <ImagesFromFlickr className={classes.ImageGallery} {...{album_name: props.flickr_album_name, index: props.index}}
 />
 </Container>

 **/