import React, {useCallback} from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { Box } from '@material-ui/core';
import { HappelSolutionsFullLogoGrey } from '../../Assets/photos';
import { Fade } from '@material-ui/core';


const MatrixBanner = () => {

    const location = window.location.pathname;
    
    let height = "40vh";
    let logoMarginTop = "15vh";

    switch (window.location.pathname) {
        case "/":
          height = "100vh";
          logoMarginTop = "40vh";
          break;
        case "/new":
            height = "100vh";
            logoMarginTop = "40vh";
          break;
      }

    

    const viewportHeight = window.innerHeight;
    
    let ht = location === "/" ? ("100vh") : "40vh"; 
    //let logoMarginTop = location === "/" ? ("40vh") : "15vh";

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
        console.log("PathName: " + location);
        console.log(height)
    }, []);
  
    return (
    
        <Box style={{ height: height, width:"100%", position: 'relative', marginBottom: "0rem", marginTop: "4rem", background: "linear-gradient(90deg, rgb(233 237 238 / 99%) 0%, rgb(255 255 255 / 99%) 44%, rgb(233 237 238) 100%)", marginLeft: 'auto', marginRight: 'auto', display: 'block', textAlign: 'center', boxShadow: 'rgb(0 0 0 / 11%) 0px 2px 20px 11px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}}>
        
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    style: {
                        height: {height},
                        width: "100%",
                        position: "absolute",
                        display: "block"
                    },
                    background: {
                        color: {
                            //value: "#0d47a1",
                            //value: "rgb(244 246 250 / 33%)",
                            //value: "rgb(255 255 255 / 0%)",
                            value: "linear-gradient(90deg, rgba(197,197,204,1) 0%, rgba(255,255,255,1) 44%, rgba(197,197,204,1) 100%);",
                        },
                    },
                    height: "300px",
                    widthL: "500px",
                    fpsLimit: 120,
                    
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: false,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    
                    
                    particles: {
                        color: {
                            //value: "#ffffff",
                            //value: "#bcbcbc",
                            value: "#fffff",
                        },
                        links: {
                            //color: "#ffffff",
                            color: "#bcbcbc",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 700,
                            },
                            value: 120,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            >

               
            </Particles>
            <Fade in={true} {...(true ? { timeout: 600 } : {})}>
                <img src={HappelSolutionsFullLogoGrey} style={{height: '6vh', marginLeft: 'auto', marginRight: 'auto', marginTop: logoMarginTop, diplay: 'block'}} />
            </Fade>

        </Box>
    
    )



}
//linear-gradient(90deg, rgb(197 197 204 / 58%) 0%, rgb(255 255 255 / 49%) 44%, rgb(197 197 204 / 33%) 100%)
//linear-gradient(90deg, rgba(197,197,204,1) 0%, rgba(255,255,255,1) 44%, rgba(197,197,204,1) 100%)
//linear-gradient(90deg, rgb(233 237 238 / 99%) 0%, rgb(255 255 255 / 99%) 44%, rgb(233 237 238) 100%)

//box shadows
// 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
//rgb(0 0 0 / 11%) 0px 2px 20px 11px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px

export default MatrixBanner