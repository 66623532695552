import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import React from "react";


const styles = {
    BoldASF: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    LightASF: {
        fontWeight: '100',
        fontSize: '2rem',
    },
};

const SimpleCompForTesting = () => {

    return(
        <Button variant={"contained"}>  U  R  COOL</Button>
    )
};


export default withStyles(styles)(SimpleCompForTesting)