import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {

};


// Local Storage -- homeHeaderPreviouslyShown
class Template extends Component{


    constructor(props) {
        super(props);
        this.state = {};
    }



    componentDidMount() {}


    render () {
        const { classes } = this.props;

        return (
            <p>Template</p>
        );
    }



}

export default withStyles(styles, { withTheme: false })(Template);
