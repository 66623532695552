import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import React, {Component} from "react";
import SimpleCompForTesting from "../../features/SimpleCompForTesting";
import SecondCompForTesting from "../../features/SecondCompForTesting";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
//import PopupGallery from "../features/PopupGallery";
import BackdropGallery from "../../features/BackdropGallery";
//import PopupGallery from "../features/PopupGalleryBeforeReplacemtn";
import PopupGallery from "../../features/PopupGallery";
import AutoRotatingCarouselPopup from "../../features/AutoRotatingCarouselPopup";
import MobileAutoCarousel from "../../features/MobileAutoCarousel";
import Gal from "../../features/Gal";
import BackdropExample from "../../features/BackdropExample";
import AutoRotatingExample from "../../features/AutoRotatingExample";
import TitledContainer from "../../BuildingComponents/TitledContainer/TitledContainer";
import DrawerTitleContainer from "../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer";
// noinspection JSDuplicatedDeclaration
const styles = {




    ShowcaseContainer: {


//#e2eceb
    },


    // Sick! Remember that this gets passed along to TitledContainer
    main_title: {
        backgroundColor: '#da8d8d !important',
    },

    initial: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    description: {
        fontWeight: '100',
        fontSize: '2rem',
    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },

    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },

    SubsectionHeaderFirst: {
        fontWeight: 'bold',
        color: '#1d1d1d',
        paddingTop: '1rem',
    },

    SubsectionHeader: {
        fontWeight: 'bold',
        color: '#1d1d1d',
    },


    PopupGalleryContainer: {
        position: 'relative',
        //display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
        marginTop: '3rem',
        
      //  backgroundColor: 'rgba(0,0,0,.05)',
    },


    homepageBody: {
        backgroundColor: 'transparent',
    },
};

const stage_components = {
    initial: <SimpleCompForTesting/>,
    secondary: <SecondCompForTesting/>
};



class Showcase extends Component{

    constructor(props) {
        super(props);
    }




    render () {
        //console.log('> [SinglePageComponentRouter] - Props: ', this.props);
        const { classes } = this.props;
        const { directory } = this.props;
        //const availHeight = ;

        const handleChange = () => {
            // Set expansionPanel state, so if fading out main component, replace with expansionPanel
            //alert('Handle Change');
        };

        const getContent = () => {
            return (
                <body className={classes.homepageBody}>

                    

                <div className="home-body-container" id={"home-body-container-before"}>
                    <Container classname={classes.ShowcaseContainer}
                               style={{
                                   borderRadius: '6px',
                                   paddingLeft: '30px !important',
                                   paddingRight: '18px',
                                   background: '#e2eceb', //fallback for old browsers
                                   background: '-webkit-linear-gradient(to right, rgb(255 255 255), rgb(226, 232, 236) )',  //Chrome 10-25, Safari 5.1-6
                                   background: 'linear-gradient(to right, rgb(255 255 255), rgb(226, 232, 236))', // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
                                   marginTop: '1rem',
                                   borderTopColor: "rgb(219, 219, 219)",
                                   borderTopStyle: "solid",
                                   borderTopWidth: "1px",
                                   borderRightColor: "rgb(219, 219, 219)",
                                   borderRightStyle: "solid",
                                   borderRightWidth: "1px",
                                   borderBottomColor: "rgb(219, 219, 219)",
                                   borderBottomStyle: "solid",
                                   borderBottomWidth: "1px",
                                   borderLeftColor: "rgb(219, 219, 219)",
                                   borderLeftStyle: "solid",
                                   borderLeftWidth: "1px",
                                   borderImageSource: "initial",
                                   borderImageSlice: "initial",
                                   borderImageWidth: "initial",
                                   borderImageOutset: "initial",
                                   borderImageRepeat: "initial",
                               }}>



                        <p className={classes.SubsectionHeaderFirst}> Website Showcase </p>

                        <p className={classes.infoSectionBody} id={'infoSectionBodyStart'}>
                            As our clients websites are completed, they will be added to the gallery below for the purposes
                            of showcasing our work.
                            <br/>

                        </p>
                        <Divider className={classes.infoSectionEndDivider}/>


                        <div className={classes.PopupGalleryContainer}>

                            {window.screen.availHeight >= 901 ? (

                                    <PopupGallery
                                        {...
                                            {
                                                showcase_details:
                                                    [
                                                        {
                                                            cover_image_url: '../../MaxMcgillProductionLoop.gif',
                                                            flickr_album_name: 'Instruments_Prod',
                                                            index: 0,
                                                        },

                                                    ]
                                            }

                                        }
                                    />



                                ) :

                                <MobileAutoCarousel {...
                                    {
                                        showcase_details:
                                            [
                                                {
                                                    cover_image_url: 'https://media.giphy.com/media/1o7EnwaZ3KwSiwFa4w/giphy.gif',
                                                    flickr_album_name: 'Instruments_Prod',
                                                    info_slides:  [

                                                        { title: 'Beautiful Mobile Design',
                                                            subtitle: 'maxmcgillproduction.com',
                                                            slide_image_url: 'https://media.giphy.com/media/gsonRJB7BFZfUpgO8D/giphy.gif'
                                                        },

                                                        {
                                                            title: 'Desktop Version ',
                                                            subtitle: 'maxmcgillproduction.com',
                                                            slide_image_url: 'https://media.giphy.com/media/BVf1P3yaTJyRkVPZe8/giphy.gif',
                                                        },

                                                    ]

                                                },


                                            ]
                                    }
                                                    } />

                            }
                        </div>

                    </Container>
                </div>
                </body>
            )
        };

        const content = getContent();

        const container_details = {
            title: 'Website Showcase',
            emoji: '💬',
        };

        return (
            <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}
                             {
                                 ...
                                     {
                                         classes: classes,
                                         directory: directory,
                                         details: container_details,
                                         content: content,
                                         onchange: {handleChange}
                                     }
                             }
            />
        );

    }


}

//{ app_classes, specifiedClassName }
export default withStyles(styles)(Showcase)
