import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppToolBarTitle from '../../../BuildingComponents/TitledContainer/AppToolBarTitle';
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const styles = theme => ({

    ExpansionPanelMainContainer: {
       // height: '80vh',
       // width: '80vw',
        //top: 0,
       // left: 0,
        //position: 'fixed',
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },
});


// Local Storage -- homeHeaderPreviouslyShown
class ServicesExpansionPanel extends Component{


    constructor(props) {
        super(props);
        this.state = {
            local_isChecked: false
        };
    }



    componentDidMount() {}




    render () {
        const { classes } = this.props;
        const {services_info} = this.props;
        //const {}
        //console.log('Services Expansion Panel Props: ', this.props);
        //console.log('Services Classes from Props: ', this.props);

        const container_id = this.props.services_info.index;

        const handleChange = () => {
            //alert('Setting State of Expansion Panel, local_isChecked: ' + !this.state.local_isChecked);
            this.setState({local_isChecked: !this.state.local_isChecked});
            this.props.onChange();
        };

        // Before changing 3/19/2021 @ 11:28
        //                <div className={classes.ExpansionPanelMainContainer} id={"expansion-panel-container-" + container_id} onClick={this.props.onChange}>

        // Change made but inevitably taken out
        // <div className={classes.ExpansionPanelMainContainer} id={"expansion-panel-container-" + container_id} onClick={handleChange}>
        return (


            <div className={classes.ExpansionPanelMainContainer} id={"expansion-panel-container-" + container_id} onClick={this.props.onChange}>
                    <Container className={classes.ServicesContainer} id={'services-info-container-unselected-' + container_id} style={{
                        borderTopColor: "rgb(219, 219, 219)",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        borderRightColor: "rgb(219, 219, 219)",
                        borderRightStyle: "solid",
                        borderRightWidth: "1px",
                        borderBottomColor: "rgb(219, 219, 219)",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        borderLeftColor: "rgb(219, 219, 219)",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1px",
                        borderImageSource: "initial",
                        borderImageSlice: "initial",
                        borderImageWidth: "initial",
                        borderImageOutset: "initial",
                        borderImageRepeat: "initial",
                    }}>
                        <React.Fragment>

                            <AppToolBarTitle
                                {
                                    ...
                                    {
                                        classes: classes,
                                        title: services_info.title,
                                        emoji: services_info.emoji
                                    }
                                }
                            />

                            <Toolbar /** Separating? **/ />

                            <Paper id={'ServicesAppBarPaper'}>
                                <Box my={2} style={{marginTop: '0', padding: '10px'}}>
                                    {[...new Array(12)]
                                        .map(
                                            () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et. magna, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.`,
                                        )
                                        .join('\n')}
                                </Box>
                            </Paper>
                        </React.Fragment>
                    </Container>
                </div>




        );
    }



}

export default withStyles(styles, { withTheme: false })(ServicesExpansionPanel);


