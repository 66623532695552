import React, { useState, useEffect } from 'react';
import { Typography, Button, Divider} from '@material-ui/core';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import copy from 'copy-to-clipboard';
import Review from './Review';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);



const PaymentForm = ( { checkoutToken, nextStep, backStep, shippingData, onCaptureCheckout}) => {


    const [open, setOpen] = useState(true);
    

    const handleClose = () => {
        setOpen(false);
    }

    const getInputFields = async () => {};

    const setDefaultCardValue = async () => {};

    useEffect(() => {}, []);

    const handleSubmit = async (event, elements, stripe) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);
        
        const { error, paymentMethod } = await stripe.createPaymentMethod( { type: 'card', card: cardElement } );

        if (error) {
            console.log('Payment Form Error: ', error)
        } else {
            console.log('@ OrderData creation data object creation');
            console.log('@ OrderData - shippingData: ', shippingData);
            const orderData = {
                line_items: checkoutToken.live.line_items,
                customer: { firstname: shippingData.firstName, lastName: shippingData.lastName, email: shippingData.email},
                shipping: { name: 'International',
                            street: shippingData.address1,
                            town_city: shippingData.city,
                            county_state: shippingData.shippingSubdivision,
                            postal_zip_code: shippingData.zip,
                            country: shippingData.shippingCountry,
                        },
                fulfillment: { shipping_method: shippingData.shippingOption},
                payment: {
                    gateway: 'stripe',
                    stripe: {
                        payment_method_id: paymentMethod.id
                    }
                }
            }

            console.log('@PaymentForm - orderData: ', orderData);

            onCaptureCheckout(checkoutToken.id, orderData);

            nextStep();
        }
    };

    return (
        <>
            <Review checkoutToken={checkoutToken}/>
            <Divider/>
            <Typography variant="h6" gutterBottom style={{ margin: '20px 0'}}> Payment method</Typography>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({elements, stripe}) => (
                        <form onSubmit={ (e) => handleSubmit(e, elements, stripe)}>  
                            <CardElement id="StripeExampleCard"/>
                            <br/> <br />
                            <div style={{display: 'flex', justifyContent:'space-between'}}>
                                <Button variant="outlined" onClick={backStep}> Back</Button>
                                <Button type="submit" variant="contained" disabled={!stripe} color="primary">
                                    Pay { checkoutToken.live.subtotal.formatted_with_symbol}
                                </Button>
                            </div>
                        </form> 
                    )}
                </ElementsConsumer>
            </Elements>


            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={"Copy test card number"}
                action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick= { () => { copy('4242424242424242' )}}>
                    COPY
                    </Button>
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    
                    onClick={handleClose}
                    >
                    <CloseIcon style={{height:'0.8em', width: '0.8em'}} />
                    </IconButton>
                </React.Fragment>
                }
            />



           
        </>
    )
}

export default PaymentForm


/**
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                            
                                                <Alert 
                                                    onClose={handleClose}
                                                    severity="success"
                                                    onClick= { () => { copy('4242424242424242' )}}
                                                >
                                                    Click Here: Copy test card number
                                                </Alert>
                                    </Snackbar>
 */