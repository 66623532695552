import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    WaveCornerLogo: {

        position: 'fixed',
        zIndex: 5,
        //left: 0,


        filter: 'drop-shadow(0px 2px 9px #666)',
        webkitFilter: 'drop-shadow(0px 2px 9px #666)',
        [theme.breakpoints.down(900)]: {
            height: '14vw',
            top: '-0.1rem',
            right: '-0.1rem',
        },

        [theme.breakpoints.up(901)]: {
            height: '13vw',
            float: 'right',
            top: '-2rem',
            right: '-2rem',
        },
        [theme.breakpoints.up(1650)]: {
            height: '8vw',
            float: 'right',
            top: '-1.2rem',
            right: '-1.01rem'
        },

    },
});


// Local Storage -- homeHeaderPreviouslyShown
class WaveCornerLogo extends Component{


    constructor(props) {
        super(props);
        this.state = {viewType: 'mobile'};
    }



    componentDidMount() {}


    render () {
        const { classes } = this.props;

        return (
            <Fragment>


                    <img id={'wave-image'} src={'../../HDRCorner.png'} alt={'i'} className={classes.WaveCornerLogo} />


            </Fragment>
        );
    }



}

export default withStyles(styles, { withTheme: false })(WaveCornerLogo);
