import React, { Component, Fragment } from 'react';
import Carousel, {ModalGateway} from "react-images";
import Modal from "@material-ui/core/Modal";
import Grow from "@material-ui/core/Grow";
import withStyles from "@material-ui/core/styles/withStyles";

const Flickr = require('flickr-sdk');


const styles = {

    MainZone: {
        //backgroundColor: '#F9F9F9',
        //backgroundImage: 'linear-gradient(to top, #e6b980 0%, #eacda3 50%)',
        //backgroundColor: 'rgba(255,255,255,.8)',
        //backgroundColor: '#f8f8ff',
        backgroundColor: '#fff',
        borderRadius: '4px',
        //flexGrow: 2,  // Causing over-extension of background color on mobile version
        width: '90%',

    },
    refinedGallery: {
        padding: '20px',
        //display: 'grid',
        //display: 'none',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '15px',
        boxShadow: '1px 2px 0px #aaaaaa',
        //rowGap: '30px',
        //columnGap: '30px',
    },
    ImageComponent: {
        cursor:'pointer',
        opacity: '1',
        boxShadow: '2px 2px 3px #aaaaaa;',
    },
    GalleryImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    ModalGateway: {
    },
};



class GalleryImages extends Component {
    constructor(props) {
        super(props);
        this.classes = this.props.classes; // For styling
        this.state = {
            isSorted: false,
            sortedImages: null,
            formattedImages: null,
            imageObjects: null,
        };
    }

    toggleLightbox = (selectedIndex) => {
        this.setState(state => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex: selectedIndex,
        }));
    };


    /**Some images have column property with value of 1, others with 2
     Sort images, arranging the images with a value of 1 first, putting those with a value of 2 at the end **/
    sortImagesBySize = () => {
        const images = this.props.props.images;
        let formatted_images = [];
        images.sort(function(a, b) {return parseFloat(a.cols) - parseFloat(b.cols);}); //
        images.map(((image, x) => (formatted_images[x] = {src: image.source.large_size.source})));
        this.setState(state => ({
            sortedImages: images,
            formattedImages: formatted_images,
            isSorted: true,
        }));
    };


    render () {
        if (!this.state.isSorted){(this.sortImagesBySize())}
        //console.log('This.state.isSorted: ', this.state.isSorted);
        //console.log('Render - Images Component, props: ', this.props);
        //console.log('Render - Image Component, state: ', this.state);
       // console.log('Render - this.classes: ', this.classes);
       // console.log('Render - this.classes.refinedGallery: ', this.classes.refinedGallery);

        //console.log('Setting REFGALLERY ID, props: ', this.props);

        return (
            <div className={this.props.classes.refinedGallery} id={'refined-gallery' + this.props.index}>
                {this.state.isSorted ? (
                    this.state.sortedImages.map((image, x) => (
                        <div className={this.classes.ImageComponent}
                             onClick={() => {
                                 this.toggleLightbox(x);
                                 //console.log('CLICK');
                                 //console.log('Selected Index: ', this.state.selectedIndex);
                                 //console.log("Lightbox State: ", this.state.lightboxIsOpen);
                             }} // x represents index
                             key={image.source.medium_size.source}
                        >
                            <Grow in={this.state.hasMounted}
                                  style={{ transformOrigin: '0 0 0' }}
                                  {...(this.state.hasMounted ? { timeout: 2000 } : {})}
                            >
                                <img className={this.props.classes.GalleryImage}
                                     src={image.source.medium_size.source}
                                     alt={x.toString()}
                                     style={{
                                         width: '100%',
                                         height: '100%',
                                         objectFit: 'cover',
                                         transform: 'initial',
                                         visibility: 'visible',
                                         opacity: 1,
                                     }}
                                />
                            </Grow>
                        </div>
                    ))):<p>Rendering Images</p>}

                <ModalGateway >
                    {this.state.lightboxIsOpen ? (
                        <Modal  onClose={this.toggleLightbox}>
                            <Carousel
                                currentIndex={this.state.selectedIndex}
                                views={this.state.formattedImages}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>

        );
    }
}




class ImagesFromFlickr extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            flickr_key: '7c9b3630d78042478d9946955360dd6f',
            user_id: "190684174@N02",
            albumDataRetrieved: false,
            photoDataRetrieved: false,
            date: new Date(),
            isLoading: true,
            images: []
        };



        this.getAlbumData = this.getAlbumData.bind(this);
        this.locateAlbumWithinResponseData = this.locateAlbumWithinResponseData.bind(this);
        //this.photoRetrievalProcess = this.photoRetrievalProcess.bind(this);
        //this.setAlbumFoundConfirmationState = this.setAlbumFoundConfirmationState.bind(this);
    }


    getAlbumData = async (FlickrAPI, user_id, cb) => {
        try {
            FlickrAPI.photosets.getList({user_id: user_id})
                .then(function (res) {
                    cb( {albums: res.body.photosets, total: res.body.photosets.total} )
                });
        } catch (err) { console.log("Error occurred when fetching albums") }
    };


    locateAlbumWithinResponseData = async (data, album_title, cb) => {
        for (let i = 0; i <= data.total - 1; i++) {
            let album_title_i = data.albums.photoset[i].title._content;
            if (album_title_i === album_title) {
                //console.log("Success - located album: ", data.albums.photoset[i]);
                this.setAlbumStates(data.albums.photoset[i], true, async function () {
                    cb(data.albums.photoset[i], true)
                });
            }
        }
    };


    setAlbumStates = async (album_data, wasFound, cb) => {
      this.setState({
          albumData: album_data,
          correctAlbumFound: wasFound,
          albumDataRetrieved: true,
      });  cb();
    };


    componentDidMount() {
            //console.log('Componenet Mnted!!');
    }


    tick() {
        //console.log('Current state of flick_album, on tick: ', this.state.images);
        this.setState({
            date: new Date()
        });
    }




    render() {
        //console.log("Props: ", this.props);
        //console.log('Image Gallery State: ', this.state);

        //console.log('Image Gallery Props: ', this.props);

        const {classes} = this.props;
        const {album_name} = this.props;
        const FlickrAPI = new Flickr(this.state.flickr_key);


        const checkForAndGetAlbumData = async (cb) => {
            let images_ = new Array();
            const user_id = this.state.user_id;

            await this.getAlbumData(FlickrAPI, this.state.user_id,  async (album_data) =>  {
                //console.log('Album Data & Title: ', album_data, album_name);
                await this.locateAlbumWithinResponseData(album_data, album_name,
                    async function (specific_album_data, wasFound) {

                    if (wasFound) {
                        FlickrAPI.photosets.getPhotos({
                            photoset_id: specific_album_data.id,
                            user_id: user_id,
                        })
                            .then( function (res) {
                                let album_photos_object = (JSON.parse(res.text)).photoset.photo; // JSON

                                for (let r = 0; r <= album_photos_object.length - 1; r++) {
                                    FlickrAPI.photos.getSizes({photo_id: album_photos_object[r].id}).then(function(sizes) {
                                        let sizes_json = (JSON.parse(sizes.text)).sizes.size;
                                        let image = new Array();

                                        if (sizes_json[(sizes_json).length - 1].width > sizes_json[(sizes_json).length - 1].height)
                                        {
                                            image.cols = 2;
                                        } else {
                                            image.cols = 1
                                        };

                                        image.caption = ""; image.author = {};

                                        image.source = {
                                            thumbnail: sizes_json[5],
                                            medium_size: sizes_json[6],
                                            large_size: sizes_json[9],
                                            original_size: sizes_json[(sizes_json).length - 1],
                                        };

                                        images_.push(image);

                                        if (images_.length === album_photos_object.length) {
                                            //console.log("Done gathering images");
                                            cb(album_photos_object, true, images_);
                                        }
                                    });
                                }
                            })
                    }
                })
            });
        };



        if (!this.state.albumDataRetrieved) {
            checkForAndGetAlbumData(  (photos_object, wasFound, images) =>  {
                this.setState({
                    photo_data: photos_object,
                    photoDataRetrieved: wasFound,
                    images: images
                });
               //console.log('BOOM Setting State: ', wasFound)
            });
        }



        const CreateImageComponentsFromImageData = () => {
            return (
                <Fragment>
                {
                    this.state.images.map( (image, i) => <img key={i} src={image.src.medium_size.source} /> )

                }
                </Fragment>
            )
        };


        return (

            <Fragment>
                {this.state.photoDataRetrieved ? (
                        <GalleryImages props={this.state} {...{index: this.props.index}} classes={classes}/>
                ) : <p> Gallery dawg</p>}


            </Fragment>

        )
    }
}


export default withStyles(styles, { withTheme: false })(ImagesFromFlickr, GalleryImages);


/**
 <div id={'gallery-root'}>
 <ImageGallery album_name={"Instruments_Prod"}/>
 <InitFingerprintJS/>
 </div>

 {this.state.images ? (
                    <div>
                        {
                        this.state.images.map( (image, i) => <img key={i} src={image.src.medium_size} /> )
                        }
                    </div>
                ) : null}

 *  else if (this.state.albumDataRetrieved && !this.state.photoDataRetrieved) {
            getPhotoData(FlickrAPI, this.state.albumData, this.state.user_id);
        }


 {!this.state.isLoading ? (
                    <RefinedGallery images={this.state}/>
                ): <p></p>}

 this.getFlickrImages = (FlickrAPI, user_id, album_title, cb) => {
            let images_ = new Array();
            try {

                FlickrAPI.photosets.getList({user_id: user_id})
                    .then(function (res) {
                        let albums = res.body.photosets;
                        let total = albums.total;
                        return {albums: albums, total: total}

                    }).then(function (res) {
                    for (let i = 0; i <= res.total - 1; i++) {
                        if (res.albums.photoset[i].title._content === album_title) {
                            console.log("album_title does equal _content: ", res.albums.photoset[i]);
                            return res.albums.photoset[i];
                        }
                    }

                }).then(function (instruments_album_object) {
                    console.log("Instrument ALbum: ", instruments_album_object);
                    console.log('FLickr api type: ', typeof FlickrAPI);
                    return FlickrAPI.photosets.getPhotos({
                        photoset_id: instruments_album_object.id,
                        user_id: user_id
                    });
                }).then(function (instrument_images_object) {
                    console.log("Instrument Images OBject: ", instrument_images_object);
                    let instrument_images_object_json = (JSON.parse(instrument_images_object.text)).photoset.photo;
                    let image_total = instrument_images_object_json.length;
                    //setImageTotalFunction(image_total);
                    //console.log(instrument_images_object_json); console.log(image_total);
                    for (let r = 0; r <= image_total - 1; r++) {
                        FlickrAPI.photos.getSizes({photo_id: instrument_images_object_json[r].id}).then(function (sizes) {
                            let sizes_json = (JSON.parse(sizes.text)).sizes.size;
                            console.log("These are sizes: \n", sizes_json);
                            console.log("Length of sizes: ", (sizes_json).length);
                            let image = new Array();
                            if (sizes_json[(sizes_json).length - 1].width > sizes_json[(sizes_json).length - 1].height){
                                image.cols = 2;
                            } else {image.cols = 1};
                            image.caption = "";
                            image.author = {};
                            image.source = {
                                thumbnail: sizes_json[5],
                                medium_size: sizes_json[6],
                                large_size: sizes_json[9],
                                original_size: sizes_json[(sizes_json).length - 1],
                            };
                            console.log('Image before pushing: ', image);
                            images_.push(image);
                            if (images_.length === image_total) {
                                console.log("Done gathering images");
                                cb(images_);
                            }

                            console.log("Current Images: ", images_);
                        });

                    }
                    console.log("End");
                })

            } catch (error) {
                console.log('Error Occurred When Getting Photos: \n', error);
            }
        };


 **/