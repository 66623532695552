import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'

import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';  
import { Typography, Box, Divider} from '@mui/material';
import { WebsiteDeskMin } from '../../Assets/photos';



const WebsitesBasic = ( {classes, directory}) => {

    const container_details = {
        title: 'Websites',
        emoji: '💬',
    };
    
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    useEffect(() => {
        // Update the document title using the browser API
        console.log("Classes: " + JSON.stringify(classes));

      });
    
    
    const getContent = () => {
        
        return (
            <Box my={2} style={{ 
                marginTop: '0', padding: '10px',
                ...(viewportWidth < 600 && { padding: '0px', }), // Apply styles for mobile
            }}>

                <Helmet>
                    <title>Websites | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="Websites | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="Websites | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/services/websites" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }}>
                    <Box style={{ margin: '10px' }}>
                        <Box style={{ background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '3px' }}>
                            
                           
                            
                            

                            <div style={{ padding: '20px' }}>
                                
                                <div style={{ overflow: 'hidden' }}>
                                    
                                    
                                    
                                    <Box id='intro-text'
                                        style={{
                                        ...(viewportWidth >= 600 && { fontSize: '1.4rem', paddingLeft: "15%", paddingRight: "9%"}), // Apply styles for non-mobile
                                        ...(viewportWidth < 600 && { maxWidth: "900px", fontSize: '1.05rem', }), // Apply styles for mobile
                                        }}>

                                         <Typography variant="h3" 
                                            style={{ marginTop: '2.9rem', marginBottom: '0.2rem', textTransform: 'uppercase', fontWeight: '700', fontStyle: 'italic',
                                                     ...(viewportWidth >= 600 && { margin: '4rem', /*textAlign: "center",*/ fontSize: '1.8rem', marginLeft: '11vw'}), // Apply styles for Desktop
                                                     ...(viewportWidth < 600 && { fontSize: '1.35rem', textAlign: 'center', marginBottom: '2rem' }), // Apply styles for mobile
                                            }}>

                                                                        Websites and Web Development
                                        </Typography>

                                        <Box id='pic'
                                        style= {{
                                                ...(viewportWidth >= 600 && {width: '400px', float: 'right', paddingRight: "2%", paddingLeft: "10%" }), // Apply styles for Desktop
                                                ...(viewportWidth < 600 && { width: '331', float: 'none', marginLeft: 'auto', textAlign: 'center'}), // Apply styles for mobile
                                        }}>
                                    
                                        <img src={WebsiteDeskMin} alt="blueprint" style={{ 
                                            maxWidth: '100%', height: 'auto', borderRadius: '4pc',
                                            ...(viewportWidth < 600 && { maxWidth: '63%', borderStyle: 'solid', borderColor: 'lightslategrey' }),
                                            }} />
                                    </Box>
                                        

                                        <Typography style={{
                                             ...(viewportWidth < 600 && { marginTop: '2rem', marginLeft:'15px' }),
                                        }}>        
                                          At Happel Solutions, we specialize in creating dynamic digital experiences through exceptional web development. The digital realm is the frontline of customer interaction, and in this fast-paced environment, you need more than just a basic online presence. A well-designed website acts as your 24/7 global storefront, inviting users in and providing them with the information or services they seek. Yet, a comprehensive digital strategy doesn't end there; web applications elevate user engagement, offering functionalities that are as intricate as they are tailored to specific tasks.
                                          <br/> <br/>
                                            Our approach is holistic, marrying visual aesthetics with functionality and future-readiness. The websites we build are not only visually appealing and SEO-friendly, but also engineered for speed, reliability, and cross-platform compatibility. And we extend that same meticulous care to the web applications we develop, ensuring they are both powerful and user-friendly. What sets us apart is our focus on creating an integrated digital experience; both the website and web application are designed to be interconnected, each enhancing the utility of the other.
                                            <br/> <br/>
                                            This introduction is just the tip of the iceberg. As we delve deeper, we'll explore how this integrated approach to web development serves various business needs, how we ensure your digital assets are future-proof, and why a long-term relationship with Happel Solutions means your online presence will not just be launched, but continually optimized for enduring success.
                                        </Typography>
                                        
                                        {/**Added this for testing */}
                                        
                                        <Divider style={{marginTop: "2rem"}}/>
                                        
                                        
                                        
                                        

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{ marginRight: '20px', marginBottom: '2.5rem'  }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Transforming Digital Presence Through Exceptional Websites <br/> <br/> </Typography>
                                                    In the realm of digital interactions, your website serves as your brand's ambassador, your business card, and your storefront all rolled into one. To ensure that it resonates with a diverse audience, our approach is nuanced and multifaceted. Aesthetically, we aim to capture the essence of your brand in the design, ensuring that it not only looks good but also aligns closely with your brand's values and message. On the functional side, we delve into elements like load times, mobile responsiveness, and intuitive navigation to create a seamless user experience.
                                                    <br/> <br/> 
                                                    For projects that require specialized focus, we have a whole arsenal of tools and strategies at our disposal. Depending on your unique requirements, this could range from implementing rigorous UX testing to improve user engagement and conversion rates to optimizing for SEO to connect your site with the right audience. The goal is to construct a website that is not only visually appealing but also functionally robust, tailored to meet your specific objectives.
                                                </Typography>


                                            </Box>
                                        </Box>

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{   marginRight: '20px', marginBottom: '2.5rem', }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Beyond Websites With Web Applications <br/> <br/> </Typography>
                                                    Web applications serve as an integral component in modern business operations, going beyond merely augmenting your website. These dynamic platforms provide specific functionalities tailored to optimize various aspects of your enterprise, from inventory management to customer engagement.
                                                    <br/> <br/>
                                                    Recognizing that every business has its own unique operational requirements, our web applications are designed for versatility. They act as specialized digital resources that can be leveraged for a multitude of tasks. For instance, they can facilitate real-time collaboration among teams, streamline complex workflows, or even offer advanced analytical tools to aid in strategic decision-making.
                                                    <br/> <br/>
                                                    At the core of our approach is a focus on state-of-the-art technology. By employing a range of programming languages and frameworks, from Python to React, we ensure that our applications meet industry standards for speed, security, and reliability.
                                                    <br/> <br/>
                                                    A key advantage of our web applications is their scalability. As your business evolves, so should your digital tools. Our architecture is designed to be adaptable, making it possible to introduce new features or expand existing functionalities with minimal disruption to your operations.
                                                    <br/> <br/>
                                                    In certain cases, we also offer the option of rigorous user experience testing to validate the design and functionality of the application. This ensures that the end product is not only technologically sound but also intuitively usable, aligning with the needs and expectations of its intended users.
                                                    <br/> <br/>
                                                    In essence, our custom web applications are more than just technological add-ons; they are strategic investments that offer long-term benefits, enhancing both operational efficiency and customer satisfaction.

                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Choosing the Right Digital Platform: Websites, Web Applications, or a Hybrid Approach? <br/> <br/> </Typography>
                                                    Navigating today's digital landscape can be confusing, especially when it comes to deciding between a website, a web application, or a combination of both. While they may seem similar, each serves a unique purpose and addresses different business needs.
                                                    <br/> <br/>
                                                    Websites act as your digital storefront, offering essential information and promoting your brand. They're generally easier to manage and are a cost-effective way to establish an online presence. Perfect for small businesses, freelancers, or anyone wanting to make their mark on the digital world without requiring complex functionalities.
                                                    <br/> <br/>
                                                    Web applications, in contrast, are interactive platforms designed to perform specific tasks. They're your go-to tools for managing customer orders, handling large data sets, or offering a more personalized user experience. Ideal for businesses requiring more specialized functionalities, web applications are generally more interactive and sophisticated than traditional websites.
                                                    <br/> <br/>
                                                    But what if you need both? Enter the hybrid approach, which combines the simplicity of a website with the functionalities of a web application. Imagine having a digital storefront that not only showcases your products but also lets customers place orders, track deliveries, and customize their shopping experience without ever leaving your site. It's like having the best of both worlds, tailored to serve your unique business needs.
                                                    <br/> <br/>
                                                    If you're still not sure which approach is right for you, we're here to help. Our team will work with you to assess your needs, weigh the pros and cons, and develop a strategy that aligns with your business goals and customer expectations.
                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                      

                                        <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Future-Ready: Adaptable Assets and Ongoing Partnerships <br/><br/> </Typography>
                                                In a technological landscape where change is the only constant, adaptability and ongoing support are essential for lasting success. Our commitment doesn't end with the launch of your website or web application. We work on the principle of creating digital assets that are both flexible and future-proof. By employing modular designs and scalable architectures, we prepare your online platforms to evolve alongside emerging technologies and market trends, avoiding the need for costly overhauls.
                                                <br/><br/>
                                                Our approach extends to cultivating lasting partnerships with our clients, offering customized support packages that provide the ongoing care your digital assets require. Whether it's routine updates, performance tuning, or integrating new features, we stay ahead of industry trends to ensure your digital presence continues to lead the way. Our long-term relationship aims to make sure your digital assets not only meet but exceed current benchmarks, while being well-equipped for the future.
                                            </Typography>
                                            
                                        </Box>

                                        <Box style={{ marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Let's Get Started <br/><br/> </Typography>
                                                Our collaborative approach is designed to ensure that we're not just service providers, but partners invested in your long-term digital success. We understand that technology isn't a one-size-fits-all solution, so our first step is always to understand your unique circumstances, requirements, and ambitions. Whether you are new to the digital landscape or looking to upgrade, we are here to provide you with insights and options that can best serve your goals.
                                                <br/><br/>
                                                So let's initiate that crucial first step in building a strong and enduring partnership. A simple conversation can pave the way for a host of opportunities, setting the stage for a successful and technologically sound future for your business. Contact us today, and together, let's lay the groundwork for an online presence that not only fulfills your current needs but is also prepared for tomorrow's challenges!
                                            </Typography>
                                            
                                        </Box>

                                       


                                    </Box>

                                </div>

                            </div>
                            

                            
                        </Box>
                    </Box>
                </Box>

                

                
            </Box>

        );
    };
    
    



    const content = getContent();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };


  return (
    <> 
    <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

        style={{ background: '#353535', }}
        {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
        }}
        /> 
    </>
  )
}

export default withStyles({}, { withTheme: false }) (WebsitesBasic);