import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//import AppToolBarTitle from "./AppToolBarTitle";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import MatrixBanner from '../../headers/MatrixBanner';
import Footer from '../../footers/Footer';

import useStyles from "./styles";
import FixedDrawerMenu from './FixedDrawerMenu';
// Local Storage -- homeHeaderPreviouslyShown
class TitledContainer extends Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}




    render () {

        const { classes } = this.props;
        const { details } = this.props;
        const { directory } = this.props;
        const { content } = this.props;

        //console.log('TitledContainer Props: ', this.props);

        return (
            <div className="home-body-container"
            style=
            {{
                //paddingTop: '10vh',
               
                marginLeft: 'auto',
                marginRight: 'auto',
            }} 
            id={"home-body-container-before"}>

                

                    <FixedDrawerMenu 
                    
                    

                    className={classes.title_toolbar} containerDetailsAndDirectory={
                            {
                                classes: classes,
                                directory: directory,
                                title: details.title,
                                emoji: details.emoji,
                                isMain: true, // ??
                            }
                    } />

                    <MatrixBanner className="matrix" style={{width:"100%", height: "40vh"}}/>
                    
                    <Box styles={{ backgroundColor: 'rgb(242, 241, 241)',}}>
                        {content}
                    </Box>

                    <Footer/>

            </div>

        );
    }



}

export default withStyles(useStyles, { withTheme: false })(TitledContainer);
//id={'separating-gutter'}
//<Toolbar  /** Separating! **/ />