import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
import headerImage from '../../Assets/photos/studio.jpg'
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import ParticleImage, { ParticleOptions } from "react-particle-image";
const styles = {

};


// Local Storage -- homeHeaderPreviouslyShown
class ParticleLogo extends Component{


    constructor(props) {
        super(props);
        this.state = {};
    }



    componentDidMount() {}


    render () {
        const { classes } = this.props;

        const particleOptions: ParticleOptions = {
            filter: ({ x, y, image }) => {
                // Get pixel
                const pixel = image.get(x, y);
                // Make a particle for this pixel if blue > 50 (range 0-255)
                return pixel.b > 50;
            },
            color: ({ x, y, image }) => "#61dafb"
        };

        return (
            <ParticleImage
                src={"../../../public/CroppedBlackedHappelSolutions.png"}
                scale={0.75}
                entropy={20}
                maxParticles={4200}
                particleOptions={particleOptions}
            />
        );
    }



}

export default withStyles(styles, { withTheme: false })(ParticleLogo);
