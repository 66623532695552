import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { Box } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';  


const styles = theme => ({

    ExpansionPanelMainContainer: {
        // height: '80vh',
        // width: '80vw',
        //top: 0,
        // left: 0,
        //position: 'fixed',
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    boxContent: {
        margin: '10px',
    },


    epDescription: {

    },

    epTitle: {

    },


    epH3: {
        marginTop: '2.9rem',

        marginBottom: '0.2rem',
        textTransform: 'uppercase',
        marginRight: '0.25rem',
        paddingLeft: '1rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '4.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epH4: {
        marginLeft: '1rem',
        
        marginTop: '0.6rem',
        marginBottom: '0.5rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem'
        },
    },

    epDescriptionIndent1: {
        marginTop: '2vh',
        marginLeft: '1rem',
        marginBottom: '2rem',

        paddingRight: '5px',
        textAlign: 'justify',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epDescriptionIndent2: {
        marginLeft: '2rem',
        
        marginBottom: '1.5rem',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },


    epListLeft: {
        float: 'left',
        marginRight: '20px',
        marginBottom: '2.5rem',
        listStyle: 'circle',
    },

    epListRight: {
        listStyle: 'circle',
    },

    sectionBackground: {
        ///background: '#e2e3ec',
        background: '#d3d3d36e',
        //margin: '-10px',
        //padding: '10px',
        marginTop: '0px',
        paddingBottom: '1px',
        borderRadius: '10px',
    },


    subsectionTitleAndBody: {
        background: '#f2f1f1',
        borderRadius: '2px',
        //borderRadius: '5px',
        boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
        padding: '3px',
    },


    whatCanWeDoForYouContainer: {
        display: 'inline-block'
    },

});


const WebsitesPage = ( {classes, directory}) => {

    const container_details = {
        title: 'Websites',
        emoji: '💬',
    };

    useEffect(() => {
        // Update the document title using the browser API
        console.log("Classes: " + JSON.stringify(classes));

      });
    
    

    const getContent = () => {
        
        //const {classes} = this.props;
        const r = " ";

        return (
            <Box my={2} style={{marginTop: '0', padding: '10px'}}>


                <div className={classes.sectionBackground}>

                    <div className={classes.boxContent}>

                        <div className={classes.subsectionTitleAndBody}>

                            <h3 className={classes.epH3}> Types of Websites </h3>

                            <div className={classes.epDescriptionIndent1}
                                style={{
                                    textAlign: 'unset',
                                }}
                            >
                                Whether it's creating comprehensive social media platforms, elegant web scheduling tools, captivating portfolios, or any other visionary concept you can imagine, our team is here to turn your dreams into finely-crafted websites, precisely tailored to your goals and aspirations.
                            </div>

                            <h4 className={classes.epH4}> Examples of Concepts: </h4>

                            <div className={classes.epDescriptionIndent2}>

                                <div className={classes.epListLeft}
                                     style={{

                                     }}
                                >
                                    <li>Online Store</li>
                                    <li>Art Gallery</li>
                                    <li>General Business Website</li>
                                    <li>Appointment/Scheduler Tool</li>
                                    <li
                                    style={{
                                        textAlignLast: 'end',
                                        marginRight: '2rem',
                                    }}>Restaurant Online Ordering Platform</li>


                                </div>

                            </div>

                            <div className={classes.whatCanWeDoForYouContainer}>

                                <h4 className={classes.epH4}> What can we do for you? </h4>
                                <div className={classes.epDescriptionIndent1}>
                                    Some clients are unsure of exactly what they want, whereas as others have an idea, color scheme, and
                                    site-flow already decided upon.
                                </div>

                                <div className={classes.epDescriptionIndent1}
                                style={{

                                }}>
                                    In either situation, websites will be developed incrementally with continually feedback being
                                    provided to the client. Decisions regarding layout, fonts, images, color-schemes, etc. will all
                                    be made and decided upon before the developmental lifecycle begins.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div className={classes.sectionBackground} id={'testing'} style={{

                }}>

                    <div className={classes.boxContent} >

                        <div className={classes.subsectionTitleAndBody}
                             style={{

                             }}>

                            <h3 className={classes.epH3}
                                style={{
                                    marginBottom: '2rem',
                                    marginTop: '1.5rem'
                                }}
                            >
                                The Framework We Use </h3>

                            <div className={classes.epDescriptionIndent1}
                            style={{
                                textAlign: 'unset',
                            }}>
                                    Happel Solutions uses <i><b>React </b></i>{r.replace(/ /g, "\u00a0")}as the primary web-framework in the development of all of it's websites.
                            </div>

                                <h4 className={classes.epH4}> What is React? </h4>

                            <div className={classes.epDescriptionIndent2}>
                                Developed by Facebook, React is a web-framework which allows for easy compartmentalization, interaction and cohesion between components of a website.
                            </div>


                            <h4 className={classes.epH4}> Who uses React? </h4>

                            <div className={classes.epDescriptionIndent2}
                                 style={{
                                     marginBottom: '3rem',
                                 }}>

                                <div className={classes.epListLeft}>
                                    <li>Instagram</li>
                                    <li>Airbnb</li>
                                    <li>Dropbox</li>
                                    <li>Many more</li>

                                </div>

                                <div className={classes.epListRight}>
                                    <li>Yahoo! Mail</li>
                                    <li>VSCO</li>
                                    <li>Snapchat</li>
                                </div>


                            </div>

                        </div>


                    </div>

                </div>

                <div className={classes.sectionBackground}>

                    <div className={classes.boxContent} >

                        <div className={classes.subsectionTitleAndBody}
                             style={{

                             }}>

                            <h3 className={classes.epH3}
                                style={{
                                    marginTop: '3.9rem',
                                }}> Hosting </h3>


                            <h4 className={classes.epH4}
                            style={{
                                marginTop: '0.75rem',
                                marginBottom: '0.2rem'
                            }}> Domain Name </h4>

                            <div className={classes.epDescriptionIndent1}>
                                A domain name is the website address:
                            </div>
                            <div className={classes.epDescriptionIndent1}
                            style={{
                                marginTop: '0.1rem',
                                marginBottom: '0.35rem',

                            }}>
                                <i> e.g. google.com </i>
                            </div>

                            <div className={classes.epDescriptionIndent1}>
                                Already have a website? Great! We can use that domain name. If you don't, we can help you
                                purchase one.
                            </div>

                            <h4 className={classes.epH4}> How Do Websites Stay Online? </h4>

                            <div className={classes.epDescriptionIndent2}>
                               Essentially, a website's content is hosted on a server and is made accessible through various
                                authentication and identification processes and protocols.
                            </div>

                            <h4 className={classes.epH4}> How Your Website Will Be Hosted </h4>

                            <div className={classes.epDescriptionIndent2}>
                                Happel Solutions currently hosts our client's websites on Google Cloud. The reason for this is
                                two-fold; not only is Google Cloud a reliable web hosting platform, but also, their system allows
                                for ease of shared-access to websites. <br/> <br/>
                                In essence, our clients are able to maintain full ownership of their website while allowing a
                                third-party to access and monitor it's health.

                            </div>

                        </div>

                    </div>
                </div>


            </Box>
        );
    }



    const content = getContent();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };


  return (
    <> 
    <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

        style={{ background: '#353535', }}
        {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
        }}
        /> 
    </>
  )
}

export default withStyles(styles, { withTheme: false }) (WebsitesPage);