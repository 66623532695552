import React, {useState, useEffect, Component} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ImagesFromFlickr from "./ImagesFromFlickr";
import Container from "@material-ui/core/Container";
import BackdropImageButton from "./BackdropImageButton";
import CustomBackdrop from "./CustomBackdrop";



const useStyles = makeStyles((theme) => ({


    backdrop: {
        color: '#fff',
        height: '100%',
        width: '100%',
        paddingTop: '40%',
        paddingBottom: '40%',
        overflowY: 'scroll',

        [theme.breakpoints.down(900)]: {
            maxHeight: '600px',
        },
        [theme.breakpoints.up(901)]: {
            maxHeight: '1200px',
        },


    },


    ImageGallery: {

        // overflowX: 'scroll'
    },


    BackdropContainer: {
        [theme.breakpoints.down(900)]: {
            marginTop: '40%',
            paddingRight: '16px',
            paddingTop: '45%',
            paddingBottom: '30%',
            marginBottom: '30%',
        },
        [theme.breakpoints.up(901)]: {
            marginTop: '40%',
            paddingRight: '16px',
            paddingTop: '45%',
            paddingBottom: '30%',
            marginBottom: '30%',
        },
    },

    ButtonAndBackdropContainer: {

    },


}));






export default class BackdropGallery extends Component{


    constructor(props) {
        super(props);
        this.state = {
            firstTime: true,
        };
    }


    componentDidMount() {
        this.setState({firstTime: false})
    }


    ImagesForGallery = () => {
        return (
            <ImagesFromFlickr {...{album_name: 'Instruments_Prod'}} />
        )
    };

    render () {

        const {classes} = this.props;

        console.log("POPUPGALLERY PROPS: ", this.props);

        const data = {album_name: 'Instruments_Prod'};

        const showcase_details = this.props.showcase_details;



        return (

            <div >

                <div className={classes.CustomBackdropMasterDiv}>

                    {showcase_details.map((showcase, index) => {

                        return ( <CustomBackdrop {...showcase} index={index}/> );

                    })}

                </div>


            </div>

        );

    }



}


















function ButtonImageAndBackdrop(props) {
    const {classes} = props;
    const [open, setOpen] = React.useState(false);
    const [zIndex, setZIndex] = React.useState(0);

    // Using on Backdrop component results in entire screen becoming button b/c backdrop covers whole screen, even
    // when not visible. This is why we use handleClose()


    const handleToggle = index => {
        //alert(index);
        if (open === 'false') {
            setOpen(true);
            setZIndex(1400);
            //document.getElementById('custom-backdrop' + index).style.zIndex = 1400;
            document.getElementById('custom-backdrop' + index).style.display = 'grid';
            document.getElementById('custom-backdrop' + index ).style.visibility = 'initial';
            document.getElementsByTagName("BODY")[0].style.overflow = 'hidden'; // X and Y
            document.getElementById('custom-backdrop' + index ).style.opacity = '1';
            //se
        }

        if (open === 'true') {
            setOpen(false);
            setZIndex(0);
            //document.getElementById('custom-backdrop' + index).style.zIndex = 0;
            document.getElementById('custom-backdrop' + index).style.display = 'none';
            document.getElementsByTagName("BODY")[0].style.overflowX = 'hidden ';
            document.getElementsByTagName("BODY")[0].style.overflowY = 'scroll';
        }

    };



    const handleClose = index => {
        setOpen(false);
        //alert('Handle Close', index);
        setZIndex(0);
        //document.getElementById('custom-backdrop' + index).style.zIndex = 0;
        document.getElementById('custom-backdrop' + index).style.display = 'none';
        document.getElementsByTagName("BODY")[0].style.overflowX = 'hidden ';
        document.getElementsByTagName("BODY")[0].style.overflowY = 'scroll';
        //useEffect(false);

    };






    return (

        <div className={classes.ButtonAndBackdropContainer}>

            <BackdropImageButton {...{image_file: props.cover_image_url, index: props.index}}
                                 index={props.index}
                                 onClick =
                                     {
                                         () => {
                                             console.log("On CHANGE, props: ", props);
                                             //alert(props.index);
                                             handleToggle(props.index);
                                         }
                                     }

            />


            {window.screen.availWidth <= 901 ? (
                    <Backdrop id={'custom-backdrop' + props.index}
                              index={props.index}
                              className={classes.backdrop}
                              open={open}
                              onClick=
                                  {
                                      () => {
                                          handleClose(props.index);
                                      }
                                  }
                              style={{
                                  marginTop: '40%',
                                  paddingRight: '16px',
                                  paddingTop: '45%',
                                  paddingBottom: '30%',
                                  marginBottom: '30%',
                                  zIndex: zIndex,
                              }}
                    >
                        <Container className={'BackdropContainer'}>
                            <ImagesFromFlickr className={classes.ImageGallery}
                                              {...{album_name: props.flickr_album_name,index: props.index}}/>
                        </Container>
                    </Backdrop>
                ) :

                <Backdrop id={'custom-backdrop' + props.index}
                          className={classes.backdrop}
                          open={open}
                          index={props.index}
                          onClick=
                              {
                                  () => {
                                      handleClose(props.index);
                                  }
                              }
                          style={{
                              marginTop: '40%',
                              paddingRight: '16px',
                              paddingTop: '45%',
                              paddingBottom: '30%',
                              marginBottom: '30%',
                              zIndex: zIndex,
                          }}
                >
                    <Container className={'BackdropContainer'}>
                        <ImagesFromFlickr className={classes.ImageGallery} {...{album_name: props.flickr_album_name, index: props.index}}
                        />
                    </Container>
                </Backdrop>


            }

        </div>
    );
}