import React, {useState, useEffect, Component} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ImagesFromFlickr from "./ImagesFromFlickr";
import Container from "@material-ui/core/Container";
import BackdropImageButton from "./BackdropImageButton";
import CustomBackdrop from "./CustomBackdrop";
import BD from './BD'



export default class Gal extends Component{


    constructor(props) {
        super(props);
        this.state = {
            firstTime: true,
        };
    }


    componentDidMount() { this.setState({firstTime: false}) }


    render () {

        console.log("GAL PROPS: ", this.props);
        const showcase_details = this.props.showcase_details;


        return (
            <div >
                {showcase_details.map((showcase, index) => {
                    return (
                        <div>
                            <BD {...showcase} index={index}/>
                        </div>

                    );
                })}
            </div>
        );
    }
}
