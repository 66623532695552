import React, {Component, Fragment} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import { ListItem, Container, SvgIcon } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { OldIntro, Services, ServicesOffered, Pricing, Quotes, Contact, Showcase } from '.'
//import Intro from "./Intro";

import AutoRotatingCarouselPopup from "../features/AutoRotatingCarouselPopup";
import FixedCloudClickToScroll from "../headers/FixedCloudClickToScroll";
import WaveCornerLogo from "../features/WaveCornerLogo";
import VerticalStepper from "../menu/VerticalStepper";
//import Showcase from "./Showcase";

//import Services from "./Services";
//import ServicesOffered from "./ServicesOffered";
//import Pricing from "./Pricing";
//import Quotes from "./Quotes";
//import Contact from "./Contact";
import FooterText from "../text/FooterText";
import BannerImage from "../features/BannerImage";
import SnowWrapper from "../features/SnowWrapper";


import useStyles from "./old_styles";


class OldMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            refDOMsLoaded: false,
            refTopOffsetLoaded: false,
            renderMsg: false,
            currentStage: 0,
            currentStageID: '',
            scrollRequired: false,
            date: new Date(),
            timeOfLastUIUpdate: new Date(),
            windowYAxis: window.top.scrollY,
            isScrolling: false,
        };
        this.myRef = React.createRef();
        this.aboutStartActual = React.createRef();
        this.aboutStart = React.createRef();
        this.servicesStart = React.createRef();
        this.showcaseStart = React.createRef();
        this.pricingStart = React.createRef();
        this.contactStart = React.createRef();
        this.quotesStart = React.createRef();
        this.refEnd = React.createRef();
       

        this.getTopOfRefs = this.getTopOfRefs.bind(this);
        this.getRefDOMObjects = this.getRefDOMObjects.bind(this);
        this.getScrollRef = this.getScrollRef.bind(this);
        //this.setCurrentStageState = this.setCurrentStageState.bind(this);

        this.headImageShown = localStorage.getItem('headImageShown');

        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };

        this.refIndex = {
            aboutStart: {
                index: 0,
                id: 'aboutStart',
                topOffset: null,
            },
            servicesStart: {
              index: 1,
              id: 'servicesStart',
              topOffset: null,
            },
            showcaseStart: {
                index: 2,
                id: 'showcaseStart',
                topOffset: null,
            },
            contactStart: {
                index: 3,
                id: 'contactStart',
                topOffset: null,
            },
            quotesStart: {
              index: 4,
              id: 'quotesStart',
              topOffset: null,
            },
            refEnd: {
                index: 5,
                id: 'refEnd',
                topOffset: null,
            },

        }
    }
    

    componentDidMount() {

        
        
        this.timerID = setInterval(
            () => {
                let r = new Date();
                let timeDiff = r - this.state.timeOfLastUIUpdate;
                //console.log('Time Difference: ', timeDiff);
               if (timeDiff >= 50) {
                    //this.getUpdatedScrollPositionAndRefOffsets();
                }
            },
            75
        );

        //console.log('Mounted - refDOMsLoaded: ', this.state.refDOMsLoaded);

        if (!Boolean(this.state.refDOMsLoaded)) { window.addEventListener('load', this.getRefDOMObjects);
        } else { }

        if (window.innerWidth >= 901) {
            const x = 'Desktop: ' + window.innerWidth;
            //alert(x)
        }

    }
    
    componentWillUnmount() {
        {
            this.state.refDOMsLoaded ? window.removeEventListener('load', this.getRefDOMObjects)
                : console.log('UM');
        }

        clearInterval(this.timerID);

    }

    getRefDOMObjects() {
        this.setState({
            refIndex: {
                aboutStartActual: {
                    DOM: document.getElementById('aboutStartActual'),
                    ref: this.aboutStartActual,
                },
                aboutStart: {
                    index: 0,
                    id: 'aboutStart',
                    topOffset: null,
                    DOM: document.getElementById('aboutStart'),
                    ref: this.aboutStart,
                },
                servicesStart: {
                    index: 1,
                    id: 'servicesStart',
                    topOffset: null,
                    DOM: document.getElementById('servicesStart'),
                    ref: this.servicesStart,
                },
                showcaseStart: {
                    index: 2,
                    id: 'showcaseStart',
                    topOffset: null,
                    DOM: document.getElementById('showcaseStart'),
                    ref: this.showcaseStart,
                },
                contactStart: {
                    index: 3,
                    id: 'contactStart',
                    topOffset: null,
                    DOM: document.getElementById('contactStart'),
                    ref: this.contactStart,
                },
                quotesStart: {
                    index: 4,
                    id: 'quotesStart',
                    topOffset: null,
                    DOM: document.getElementById('quotesStart'),
                    ref: this.quotesStart,
                },
                refEnd: {
                    index: 5,
                    id: 'refEnd',
                    topOffset: null,
                    DOM: document.getElementById('refEnd'),
                    ref: this.refEnd,
                },
            }
        });
        this.setState({refDOMsLoaded: true});
        //this.componentWillUnmount();
    };

    getTopOfRefs() {
       // console.log('GET TOP TEST: ', this.aboutStart.current.offsetTop);
    }

    isCurrentlyScrolling () {
       // if (window.top.scrollY === this.state.windowYAxis) {

        let y_difference = Math.abs(window.top.scrollY - this.state.windowYAxis);
        let is_slow_scroll_mobile = y_difference >= 15;
        let is_slow_scroll_desktop = y_difference >= 60;

        // Check for first initial 'large' scroll  (actually checking if it doesn't exist, but point stands)
        // if LESS THAN our parameter defined for scrolling, 400px movement


        // Mobile
        if (window.screen.availHeight <= 901) {


            // If less than 200
            if (y_difference <= 200) {

                // Check if previous state declared isScrolling as true &&&&& there continues to be some minute movement
                if (this.state.isScrolling && is_slow_scroll_mobile) {
                    //console.log("Yes. Scrolling");
                    return true // True, still scrolling
                } else {
                    return false; // Not scrolling
                }
            } else {
               // console.log("Yes. Scrolling");
                return true;
            }

            // Desktop
        } else {

            if (y_difference <= 400) {

                // Check if previous state declared isScrolling as true AND there continues to be some minute movement
                if (this.state.isScrolling && is_slow_scroll_desktop) {
                    //console.log("Yes. Scrolling");
                    return true
                } else {
                    //console.log("No. Scrolling");
                    return false;
                }
            } else {
               // console.log("Yes. Scrolling");
                return true;
            }
        }

    }

    getScrollRef = selected_step => {
        switch (selected_step) {
            case 0:
                return this.aboutStart;
            case 1:
                return this.servicesStart;
            case 2:
                return this.showcaseStart;
            case 3:
                return this.contactStart;
            case 4:
                return this.quotesStart;
            case 5:
                return this.refEnd;
            default:
                return 'ooo'; // Not sure what to do with default
        }
    };
    
    getVerticalStepperDIV = () => {
        return document.getElementsByClassName('MuiStepper-vertical')[0];
    };

    stepEventHandler = data => {
        //console.log('> [App.js] - VerticalStepper stepEventHandler: ', data);

        /** Manually updates currentStage, as opposed to it being updated via scrolling **/
        this.setState({currentStage: data});
        let ref_to_scroll_to = this.getScrollRef(data);
        //console.log('Returned Scroll Reference: ', ref_to_scroll_to);

        this.scrollToMyRef(ref_to_scroll_to).then(r => {
            //console.log('Scroll To Ref. Promise SUCCESSFUL, r: ', r);
        });
    };

    /***
     *
     *
     *  adjustBodyContentMargin = data => {
        let isScrolling = this.isCurrentlyScrolling();
        let containers = document.getElementsByClassName('home-body-container');

        if (isScrolling) {
            //alert('Containers Length: ', containers);
            for (let i = 0; i <= containers.length - 1; i++) { //console.log('Container ', i, ' : ', containers[i]);
                containers[i].setAttribute('id', 'home-body-container-after');
            }
        }

        else if (data.LessThanA) {

            for (let i = 0; i <= containers.length - 1; i++) {//console.log('Container ', i, ' : ', containers[i]);
                containers[i].setAttribute('id', 'home-body-container-before');
            }
        }

        else {

            for (let i = 0; i <= containers.length - 1; i++) {//console.log('Container ', i, ' : ', containers[i]);
                containers[i].setAttribute('id', 'home-body-container-before');
            }
        }
        //let shouldDecreaseContentContainerMargin = Boolean(data['displayingInitialStyling']);
        //console.log('Should Decrease Margin: ', shouldDecreaseContentContainerMargin);
        //document.getElementById('home-body-container-before').setAttribute('id', 'home-body-container-after');
    };

     *
     *
     * @param data
     */

    adjustBodyContentMargin = data => {
        let isScrolling = this.isCurrentlyScrolling();
        let containers = document.getElementsByClassName('home-body-container');




        if (isScrolling) {
            //alert('Containers Length: ', containers);
            for (let i = 0; i <= containers.length - 1; i++) { //console.log('Container ', i, ' : ', containers[i]);
                containers[i].setAttribute('id', 'home-body-container-after');
            }
        } else {

            for (let i = 0; i <= containers.length - 1; i++) {//console.log('Container ', i, ' : ', containers[i]);
                containers[i].setAttribute('id', 'home-body-container-before');
            }
        }

        if (!data.GreaterThanFirstOffset) {

           // console.log('DAta: ', data);
            for (let i = 0; i <= containers.length - 1; i++) {//console.log('Container ', i, ' : ', containers[i]);
                containers[i].setAttribute('id', 'home-body-container-after');
            }
        }


        //let shouldDecreaseContentContainerMargin = Boolean(data['displayingInitialStyling']);
        //console.log('Should Decrease Margin: ', shouldDecreaseContentContainerMargin);
        //document.getElementById('home-body-container-before').setAttribute('id', 'home-body-container-after');
    };

    adjustVerticalStepperPositioning = data => {

        let isScrolling = this.isCurrentlyScrolling();
        let verticalStepperDIV = document.getElementsByClassName('MuiStepper-vertical')[0];
        //console.log('ZILLA: ', isScrolling);
        //console.log('Scroll Data: ', data);
        // Scrolling
        if (isScrolling) {
            //console.log('Scrollzilla');
            verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-Scrolling'); // New name change needs to be extrapolated to the rest of the below
            // Not Scrolling
        } else {
            //console.log('Nozilla');

            if (data.displayingInitialStyling) {
                //let verticalStepperIsReady  = document.getElementById('VerticalStepperDiv').attributes.ready.value === "true";

                //console.log('Is Ready?????????????????? : ', verticalStepperIsReady);
                //console.log(document.getElementById('VerticalStepperDiv').attributes);
                if (data.LessThanA && data.withinFirstStage) {
                    this.adjustBodyContentMargin(data);
                    verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-NotScrolling');
                    //document.getElementById('VerticalStepperDiv')
                     //   .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveContent');
                }

                //Edge
                if (data.GreaterThanA && data.withinFirstStage) {
                    verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-NotScrolling');
                       // document.getElementById('VerticalStepperDiv')
                         //   .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');

                }



            }

            if (data.GreaterThanA && data.withinFirstStage) {
               // verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');
                verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-NotScrolling');
            }



            // Edge kinda
            if (data.GreaterThanFirstOffset && data.displayingAboveStyling) {
                //verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');
                verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-NotScrolling');
            }


            if (data.LessThanFirstOffset && data.displayingTransitionedStyling) {
                //console.log('Less THan A, Displaying Transitioned Styling - TransitionedStyling: ', data.displayingTransitionedStyling);
                //verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveContent');
                verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-NotScrolling');
            }

        }



        /**if (data.LessThanA && data.LessThanFirstOffset) {
            verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveContent');
            // document.getElementById('VerticalStepperDiv')
            //   .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');

        } **/



    };

    getUpdatedScrollPositionAndRefOffsets = () => {
        let isScrolling = this.isCurrentlyScrolling();
        let locatedCurrentStage = 0;
        let offsets = [];
        let offsets_array = {};
        for (let stage in this.state.refIndex) {
            //console.log('Stage:', stage);
            let stage_dom = this.state.refIndex[stage].DOM;
            //console.log('Stage Dom:', stage_dom);
            let stage_dom_top = stage_dom.offsetTop;
            //console.log('Stage Dom Offset: ', stage_dom_top);
            offsets.push(stage_dom_top);
        }

        for (let i = 0; i<= offsets.length - 1; i++) {
            offsets_array[i] = offsets[i];
        }

        //console.log('> Offsets: ', offsets);
        //console.log('> Offsets Array: ', offsets_array);

        let currentYAxis = window.scrollY;
       // console.log('Current Y Axis: ', currentYAxis);

        for (let r = 0; r <= offsets.length - 1; r++) {

            let offset_to_compare_to = offsets_array[r];

           try {

                let a_offset = offsets[r];
                let b_offset = offsets[r + 1];
                let GreaterThanA = currentYAxis + 200 >= a_offset;
                let LessThanA = currentYAxis < a_offset;
                let LessThanB = currentYAxis < b_offset;

                let BOffsetUndefined = typeof b_offset === "undefined";
                //console.log('BOffsetUndefined: ', BOffsetUndefined);




                let displayingInitialStyling = document.getElementById('VerticalStepperDiv') !== null;
                let displayingTransitionedStyling = document.getElementById('VerticalStepperDiv-AfterTitleTyped') !== null;
                let displayingAboveStyling = document.getElementById('VerticalStepperDiv-AfterTitleTyped-AboveContent') !== null;

                let withinFirstStage = Boolean(r === 0);

                let GreaterThanFirstOffset = currentYAxis >=  offsets_array[0];
                let LessThanFirstOffset = currentYAxis + 300 < offsets_array[0];

                let current_data = {
                    displayingInitialStyling: displayingInitialStyling,
                    displayingTransitionedStyling: displayingTransitionedStyling,
                    displayingAboveStyling: displayingAboveStyling,
                    withinFirstStage: withinFirstStage,
                    LessThanA: LessThanA,
                    GreaterThanA: GreaterThanA,
                    GreaterThanFirstOffset: GreaterThanFirstOffset,
                    LessThanFirstOffset: LessThanFirstOffset,
                    isScrolling: isScrolling,
                };

                //console.log('Current Vertical Stepper Data: ', current_data);
               this.adjustBodyContentMargin(current_data);

               this.adjustVerticalStepperPositioning(current_data);

                if (GreaterThanA) {

                    if (LessThanB || BOffsetUndefined) {
                        //console.log('\n\n\n\n\n\nCurrent Stage: ', r);
                        locatedCurrentStage = r;
                    }

                } //console.log('A, B Offsets: ', a_offset, b_offset);

            } catch (err) { console.log('Error: ') }//err

            //console.log('offset_to_compare_to: ', offset_to_compare_to);
        }



        //console.log('\n Should SET STATE here \n');
        this.setState({currentStage: locatedCurrentStage, windowYAxis: currentYAxis, timeOfLastUIUpdate: new Date(), isScrolling: isScrolling});

    };

    render () {
        const { classes } = this.props;

        let DOMVarsCreated = Boolean(this.state.refDOMsLoaded);
        let topOffsetsFound = Boolean(this.state.refTopOffsetLoaded);
        let currentWindowYAxis = Boolean(this.state.windowYAxis);


        const handleClick = () => {};

        const setHandleOpen = () => {
            console.log('Carousel Setting Open');
        };


        function DownArrow(...props) {
            console.log("Down Arrow: ", props);
            return (
                <SvgIcon style={{color: '#000000', position: 'absolute'}}
                         fontSize={"large"}
                         component={ExpandMoreIcon}
                         id={'HomepageHeaderDownArrow'}
                />
            )
        }

        const eventHandler =  data => {
           console.log('Event Handler Data: ', data);
        };
        

        

        /**
         *  Removed For Prod 4/2/2021
         *
         <div className={classes.navContainerColumn}>
         <VerticalStepper ready="false" className={classes.VerticalStepperClass} stage={this.state.currentStage} onChange={this.stepEventHandler}/>
         </div>
         */


        //const refToPassOn = document.getElementById('servicesStart');
        //let topOffsetForRef = this.state.refIndex['servicesStart'].DOM;
        //console.log('topOffsetForRef: ', topOffsetForRef);
        //this.getRefDOMObjects();
        let r = 'undefined';
        return (

            <Fragment>

                <WaveCornerLogo/>

                <Fragment>
                    {(this.state.refDOMsLoaded & (localStorage.getItem('headImageShown') !== 'true')) ? (
                        <FixedCloudClickToScroll refToScrollTo={this.state.refIndex['aboutStartActual']} style={{marginBottom: '3rem'}}/>
                    ): null}

                </Fragment>

                <div className={classes.navContainerColumn}>
                    <VerticalStepper ready="false" className={classes.VerticalStepperClass} stage={this.state.currentStage} onChange={this.stepEventHandler}/>
                </div>


                <div className={classes.bodyReplacement} >




                
                  <div className={classes.mainContentContainerColumn}>
                  
                      <div ref={this.aboutStartActual} id={'aboutStartActual'}/>
                      <ListItem className={classes.InitialAboutSeparator}/>
                      <OldIntro className={classes.Section}/>
                      <div className={classes.aboutStart} ref={this.aboutStart} id={'aboutStart'}
                      style={{
                          position: 'absolute',
                          margin: '-20vh'
                      }}/>

                      <div ref={this.servicesStart} id={'servicesStart'}/>
                      <ListItem className={classes.SectionSeparator}/>
                      <Services className={classes.Section}/>


                      <div id={"showcaseStart"} ref={this.showcaseStart}/>
                      <ListItem className={classes.SectionSeparator}/>
                      <Showcase className={classes.Section}/>
                      <AutoRotatingCarouselPopup isMobile={false} handleOpen={handleClick} setHandleOpen={setHandleOpen}/>

                      <div id={"contactStart"} ref={this.contactStart}/>
                      <ListItem className={classes.SectionSeparator}/>
                      <Contact className={classes.Section}/>

                      <div id={"quotesStart"} ref={this.quotesStart}/>
                      <ListItem className={classes.SectionSeparator}/>
                      <Quotes className={classes.Section}/>

                      <div id={"refEnd"} ref={this.refEnd}/>
                      <ListItem className={classes.SectionSeparator}/>
                      <Container style={{marginTop: '5rem'}}><p> <FooterText/> </p> </Container>


                  </div>

                </div>
            </Fragment>

        );
    }


    //<div id={"pricingStart"} ref={this.pricingStart}/>


    /**
     *  <ListItem className={classes.SectionSeparator}/>
     <Pricing className={classes.Section}/>
     *
     */
    /**
     *
     *
     *
     *
     */

    /**
     <PopupGalleryBeforeReplacemtn {...
    {
        showcase_details:
            [
                {
                    cover_image_url: '../../MaxMcgillProductionLoop.gif',
                    flickr_album_name: 'Instruments_Prod',
                    index: 0,
                },
                {
                    cover_image_url: '../../MaxMcgillProductionLoop.gif',
                    flickr_album_name: 'Instruments_Prod',
                    index: 0,
                },

            ]
    }

}
/>

  **/
    /**
     *
     *
     *   <Button id={'home-header-down-arrow'}
     className={classes.ArrowButton}
     onClick={this.scrollToMainContent}
     onChange={this.handleChange}>
     <DownArrow className={classes.ArrowSVG}/>
     </Button>
     */


    /**
    scrollToMainContent =  async (ref_to_scroll_to) => {
        //this.waitSetState();
        let xyz = document.getElementById('home-body-container-before');

        xyz.scrollIntoView({
            behavior: "smooth", block: "end", inline: "nearest"}
        );
        /**
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };




     **/

    scrollToMyRef =  async (ref_to_scroll_to) => {
        window.scrollTo({
            top: ref_to_scroll_to.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };
}


export default withStyles(useStyles, { withTheme: false })(OldMain);
//            <Showcase id={'info1'}/>


/** Removed when refactoring ******
//import ListItem from '@material-ui/core/ListItem';
//import Container from "@material-ui/core/Container";
//import SvgIcon from "@material-ui/core/SvgIcon";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import PopupGallery from "../features/PopupGallery";
import AutoRotatingExample from "../features/AutoRotatingExample";
import Button from "@material-ui/core/Button";
import ClickToScrollHeader from "../headers/ClickToScrollHeader";
import CloudClickToScrollHeader from "../headers/CloudClickToScrollHeader";
import MobileAutoCarousel from "../features/MobileAutoCarousel";
import PopupGalleryBeforeReplacemtn from "../features/PopupGalleryBeforeReplacemtn";
**/


/**
 <AutoRotatingExample isMobile={false}
 handleOpen={handleClick}
 setHandleOpen={setHandleOpen}
 />
**/

/**
 * <h2>It is {this.state.date.toLocaleTimeString()}.</h2>
 <h2>Window Y-Axis Offset:  {this.state.windowYAxis}. </h2>
 */





/**

 else {

                    // Not greater than starting initial offset, in this case --> About's
                    // Already loaded as per
                    if (r === 0) {


                        document.getElementById('VerticalStepperDiv')
                            .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveAboutYOffset');
                    } else {

                        try {
                            document.getElementById('VerticalStepperDiv')
                                .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveAboutYOffset');
                        } catch (err) {

                        }

                    }

                }

 **/


/** Scroll Backup
 adjustVerticalStepperPositioning = data => {

        let isScrolling = this.state.isScrolling;
        let verticalStepperDIV = document.getElementsByClassName('MuiStepper-vertical')[0];

        // Scrolling
        if (isScrolling !== false) {

            verticalStepperDIV.setAttribute('id', 'VerticalStepperDiv-Scrolling'); // New name change needs to be extrapolated to the rest of the below

            // using the verticalStepper element from above, itll be easy
            // Not Scrolling
        } else {

            if (data.displayingInitialStyling) {
                //let verticalStepperIsReady  = document.getElementById('VerticalStepperDiv').attributes.ready.value === "true";

                //console.log('Is Ready?????????????????? : ', verticalStepperIsReady);
                //console.log(document.getElementById('VerticalStepperDiv').attributes);
                if (data.LessThanA && data.withinFirstStage) {
                    this.adjustBodyContentMargin(data);
                    document.getElementById('VerticalStepperDiv')
                        .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveContent');
                }

                //Edge
                if (data.GreaterThanA && data.withinFirstStage) {
                    if (isScrolling) {
                        // Already positioned for scrolling
                    } else {
                        document.getElementById('VerticalStepperDiv')
                            .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');
                    }
                }



            }

            if (data.GreaterThanA && data.withinFirstStage && data.displayingAboveStyling) {
                if (isScrolling) {
                    document.getElementById('VerticalStepperDiv-AfterTitleTyped-AboveContent')
                        .setAttribute('id', 'VerticalStepperDiv');
                } else {
                    document.getElementById('VerticalStepperDiv-AfterTitleTyped-AboveContent')
                        .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');
                }

            }

            if (data.GreaterThanFirstOffset && data.displayingAboveStyling) {
                //console.log('GRETATERRRRRRRRR THan A, Displaying Transitioned Styling - TransitionedStyling: ', data.displayingTransitionedStyling);

                document.getElementById('VerticalStepperDiv-AfterTitleTyped-AboveContent')
                    .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');
            }


            if (data.LessThanFirstOffset && data.displayingTransitionedStyling) {
                //console.log('Less THan A, Displaying Transitioned Styling - TransitionedStyling: ', data.displayingTransitionedStyling);

                document.getElementById('VerticalStepperDiv-AfterTitleTyped')
                    .setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped-AboveContent');
            }

        }



    };

 **/