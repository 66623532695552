import React, { Component } from 'react';
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import FavoriteIcon from "@material-ui/icons/Favorite";

const styles = {
    FooterClientDetails: {
        fontSize: '0.55rem',
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
        paddingBottom: '0.5%',
        paddingTop: '2%',
        letterSpacing: '.5px',
    },
    FooterHappelSolutions: {
        fontSize: '0.55rem',
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
        paddingBottom: '5.5%',
        paddingTop: '1%',
        letterSpacing: '.5px',
    },
    favIcon: {
        fill: 'red',
        opacity: '.8',
        width: '.75em',
        height: '.49em',
    },

};

class FooterText extends Component<> {

    constructor(props) {
        super(props);
    }

    render () {
        const {classes} = this.props;
        return (
            <div>
                <Typography className={classes.FooterClientDetails} variant="h4" component="h5">
                    Made with <FavoriteIcon className={classes.favIcon}/> by Happel Solutions
                </Typography>
            </div>

        );
    }
}

export default withStyles(styles, { withTheme: false })(FooterText);

