import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'

import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';  
import { Typography, Box, Divider} from '@mui/material';
import { GeometricShapesMin } from '../../Assets/photos';



const ITSolutions = ( {classes, directory}) => {

    const container_details = {
        title: 'IT Services',
        emoji: '💬',
    };
    
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    useEffect(() => {
        // Update the document title using the browser API
        console.log("Classes: " + JSON.stringify(classes));

      });
    
    
    const getContent = () => {
        
        return (
            <Box my={2} style={{ 
                marginTop: '0', padding: '10px',
                ...(viewportWidth < 600 && { padding: '0px', }), // Apply styles for mobile
            }}>
                <Helmet>
                    <title>IT Services | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="IT Services | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="IT Services | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/services/tech" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }}>
                    <Box style={{ margin: '10px' }}>
                        <Box style={{ background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '3px' }}>
                            
                           
                            
                            

                            <div style={{ padding: '20px' }}>
                                
                                <div style={{ overflow: 'hidden' }}>
                                    
                                    
                                    
                                    <Box id='intro-text'
                                        style={{
                                        ...(viewportWidth >= 600 && { fontSize: '1.4rem', paddingLeft: "15%", paddingRight: "9%"}), // Apply styles for non-mobile
                                        ...(viewportWidth < 600 && { maxWidth: "900px", fontSize: '1.05rem', }), // Apply styles for mobile
                                        }}>

                                         <Typography variant="h3" 
                                            style={{ marginTop: '2.9rem', marginBottom: '0.2rem', textTransform: 'uppercase', fontWeight: '700', fontStyle: 'italic',
                                                     ...(viewportWidth >= 600 && { margin: '4rem', /*textAlign: "center",*/ fontSize: '1.8rem', marginLeft: '11vw'}), // Apply styles for Desktop
                                                     ...(viewportWidth < 600 && { fontSize: '1.35rem', textAlign: 'center', marginBottom: '2rem' }), // Apply styles for mobile
                                            }}>

                                                                        IT and Tech Services
                                        </Typography>

                                        <Box id='pic'
                                        style= {{
                                                ...(viewportWidth >= 600 && {width: '400px', float: 'right', paddingRight: "2%", paddingLeft: "10%" }), // Apply styles for Desktop
                                                ...(viewportWidth < 600 && { width: '331', float: 'none', marginLeft: 'auto', textAlign: 'center'}), // Apply styles for mobile
                                        }}>
                                    
                                        <img src={GeometricShapesMin} alt="blueprint" style={{ 
                                            maxWidth: '100%', height: 'auto', borderRadius: '4pc',
                                            ...(viewportWidth < 600 && { maxWidth: '63%', borderStyle: 'solid', borderColor: 'lightslategrey' }),
                                            }} />
                                    </Box>
                                        

                                        <Typography style={{
                                             ...(viewportWidth < 600 && { marginTop: '2rem', marginLeft:'15px' }),
                                        }}>        
                                         At Happel Solutions, we understand that businesses today, regardless of size or industry, depend on a seamless and robust IT infrastructure for day-to-day operations. For small businesses, this means simplified workflows and centralized data storage, allowing you to focus on what really matters—serving your customers and growing your business. For larger corporations, advanced IT solutions are key in data security, interdepartmental collaboration, and ensuring a competitive edge in a fast-paced market.
                                         <br/> <br/>
                                            Our comprehensive IT services are tailored to meet these diverse needs. By optimizing your technology landscape, we enable you to reduce operational costs and increase productivity. The impact goes beyond just immediate savings; a well-integrated IT infrastructure offers the flexibility needed to adapt to market changes and scales as your business grows. From enhancing customer experiences to securing valuable information, we're committed to helping you navigate the complexities of today's digital world. Together, we can forge a path that sets your business up for sustainable success.
                                            
                                        </Typography>
                                        
                                        {/**Added this for testing */}
                                        
                                        <Divider style={{marginTop: "2rem"}}/>
                                        
                                        
                                       

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{ marginRight: '20px', marginBottom: '2.5rem'  }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> The Underestimated Power of IT in Small Businesses <br/> <br/> </Typography>
                                                    At Happel Solutions, we comprehend that the backbone of any thriving enterprise today, be it a small family-run store or a global conglomerate, is a resilient and efficient IT infrastructure. Small businesses often overlook the power of technology, considering it an expenditure rather than an investment. However, a well-executed IT strategy can simplify your day-to-day administrative tasks, streamline inventory management, and even provide insightful customer behavior analytics that can help you make well-informed decisions. The idea is to automate the mundane so you can concentrate on strategic business growth, customer relationships, and workforce development.
                                                </Typography>


                                            </Box>
                                        </Box>

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{   marginRight: '20px', marginBottom: '2.5rem', }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Necessity of Robust IT for Large-Scale Organizations <br/> <br/> </Typography>
                                                    For large-scale organizations, the value proposition of effective IT solutions is even more pronounced. In an environment of fierce competition, staying ahead of the curve is not just an ambition but a necessity. An agile IT framework supports dynamic communication channels, making collaborations fluid across departments and geographies. It enhances your security measures, protecting against the ever-growing spectrum of cyber threats. Furthermore, it helps in resource management and allocation, ensuring that you maximize the utility of every asset, from human resources to the supply chain. The goal is to create a resilient, future-ready enterprise that is not only efficient but also adaptable to fast-paced market changes.
                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Tailored Solutions for Diverse Needs <br/> <br/> </Typography>
                                                    Our services are engineered to be flexible and scalable to meet these multifaceted needs of both small and large businesses. Consider the operational costs that are sliced dramatically by cutting down on manual errors, redundant tasks, or even electricity bills through cloud solutions. Or think about how a seamlessly integrated IT system adds resilience to your business model, making it easier for you to adapt to market dynamics and consumer behavior trends without going through extensive operational upheavals. The benefits of a well-planned IT infrastructure go beyond immediate monetary savings. They lay the foundation for a business that is not just surviving, but thriving and ready for the future.
                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                        <Box style={{   marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Sustaining a Long-Term Partnership <br/><br/> </Typography>
                                                Our initial engagement may involve setting up your IT infrastructure, integrating software solutions, or optimizing your tech landscape, but our service extends far beyond that. We pride ourselves on standing by our work and standing by you, our valued client, for the long haul. You can think of us as your technology allies, consistently focused on your ongoing needs and operational stability.
                                                <br/> <br/>
                                                The technology world is dynamic, with new updates and vulnerabilities appearing all the time. While the solutions we implement are designed for longevity and resilience, we recognize that periodic adjustments are not just beneficial but often essential. Whether it's a minor glitch, a necessary software update, or a hardware concern that needs attention, we're on it.
                                                <br/> <br/>
                                                We offer ongoing maintenance packages that can be customized to fit your specific operational requirements. These services include proactive monitoring of your systems, prompt troubleshooting, and regular fine-tuning to adapt to the evolving tech environment or changes in your business strategy. We'll work with you to schedule these at intervals that are least disruptive to your business, ensuring your team can stay focused on what they do best.
                                                <br/> <br/>
                                                The idea is not just to fix problems, but to anticipate them. Our team stays current on the latest tech trends and security alerts, enabling us to advise you on beneficial adjustments before a problem arises. That way, your systems aren't just maintained; they're continually optimized. This is what we mean by a long-term partnership: an ongoing relationship that adds value to your business, day in and day out.
                                            </Typography>
                                            
                                        </Box>

                                        <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Let's Start a Conversation <br/><br/>
                                                </Typography>
                                                We understand that every business has its own set of unique challenges and opportunities, especially when it comes to technology. If you've read this far, it's clear you're serious about leveraging IT to create more value for your business, whether it's a small local shop or a sprawling enterprise.
                                                <br/><br/>
                                                Why not take the next step and reach out to us for a no-obligation consultation? In this personalized session, we'll discuss your current IT landscape, identify areas for improvement, and explore new solutions that could elevate your operations. This is a chance for you to ask questions, voice concerns, and get expert insights tailored specifically to your business needs.
                                                <br/><br/>
                                                Contact us today to schedule this valuable dialogue. Together, we can lay the groundwork for a more efficient, secure, and agile future for your business.
                                            </Typography>
                                            
                                        </Box>

                                       


                                    </Box>

                                </div>

                            </div>
                            

                            
                        </Box>
                    </Box>
                </Box>

                

                
            </Box>

        );
    };
    
    



    const content = getContent();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };


  return (
    <> 
    <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

        style={{ background: '#353535', }}
        {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
        }}
        /> 
    </>
  )
}

export default withStyles({}, { withTheme: false }) (ITSolutions);