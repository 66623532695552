import { makeStyles, fade } from '@material-ui/core/styles';

const drawerWidth = 0;


const useStyles = theme => ({
    aboutStart: {
        marginBottom: '3rem',
      },
  
      MainContainer: {
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 'none',
          padding: 0,
          margin: 0,
          align: 'center',
          justifyContent: 'center',
  
          [theme.breakpoints.down('sm')]: {
  
              //backgroundColor: theme.palette.primary.main,
          },
  
          [theme.breakpoints.up('md')]: {
              //  backgroundColor: theme.palette.secondary.main,
          },
  
          [theme.breakpoints.up('lg')]: {
              //  backgroundColor: green[500],
          },
      },
      InfoPageTitle: {
          marginTop: '5rem',
          position: 'relative',
          textAlign: 'center',
          marginBottom: '3rem',
      },
  
      InfoPageTitleRowOne: {
  
          paddingTop: '4.8%',
          letterSpacing: '.45rem',
          wordSpacing: '0.5rem',
          transformText: 'uppercase',
          fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
          position: 'relative',
          textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
              fontSize: '1.65rem',
              // backgroundColor: theme.palette.secondary.main,
          },
  
          [theme.breakpoints.up('lg')]: {
              fontSize: '2.65rem',
          },
      },
      InfoPageTitleRowTwo: {
          fontSize: '1.35rem',
          paddingTop: '4.8%',
          letterSpacing: '.45rem',
          wordSpacing: '0.5rem',
          transformText: 'uppercase',
          fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
          position: 'relative',
          textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
              fontSize: '1.15rem',
              // backgroundColor: theme.palette.secondary.main,
          },
  
          [theme.breakpoints.up('lg')]: {
              fontSize: '1.35rem',
          },
      },
      root: {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          overflow: 'hidden',
      },
      header: {
  
          position: 'relative',
          backgroundColor: 'black',
          width: '1920px',
          height: '1080px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
      },
  
      img: {
          width: '100%',
          height: '100%',
          margin:'auto',
          justifyContent: 'left',
  
      },
      headerImageContainer: {
          height: '1',
          width: '1'
      },
      homeH1: {
          fontWeight: '400',
          //fontFamily: 'Work Sans, sans-serif',
          fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  
          fontStyle: 'normal',
          textAlign: 'center',
      },
      opaqueListHeader: {
          backgroundColor: '#22292554',
      },
  
  
  
      homepageBody: {
          //overflowY: 'auto',
          marginTop: '0px',
          //display: 'flex',
          //flexDirection: 'row'
      },
  
      bodyReplacement: {
          //overflowY: 'auto',
          marginTop: '0px',
          //display: 'flex',
          //flexDirection: 'row'
      },
  
      navContainerColumn: {
          //flexShrink: 6,
          //flexGrow: 6,
          //flexBasis: '8%',
      },
  
      mainContentContainerColumn: {
          //flexShrink: 1,
          //flexGrow: 1,
          //flexBasis: '70%',
          zIndex: 2,
          position: 'relative',
  
          [theme.breakpoints.down('sm')]: {
             
              // backgroundColor: theme.palette.secondary.main,
          },
          // Center the center columns
          [theme.breakpoints.up('lg')]: {
              margin: 'auto',
              width: '50%',
          },
      },
  
      infoContainer: {
          backgroundColor: 'rgba(255,255,255,.8)',
          borderRadius: '4px',
          flexGrow: 2,
          width: '100%',
          fontSize: '16px',
          fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
          boxShadow: '2px 2px 3px #aaaaaa',
          maxWidth: '1480px'
          //marginBottom: '2%',
      },
      pageHeaderTextContainer: {
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '1480px',
          flexGrow: 2,
          width: '100%',
          padding: 0,
          //marginTop:'4%',
          //marginBottom: '0.5rem',
      },
      pageHeaderText: {
          fontWeight: '400',
          fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
          //marginBottom: '3%',
          //textAlign: 'center',
          //paddingBottom: '0.5%',
          lineHeight: '1.25em',
          position: 'relative',
          textAlign: 'center',
          // textShadow: '1px 1px 0px #aaaaaa',
      },
      infoSectionHeader: {
          paddingTop: '1%',
          paddingBottom: '0%',
          marginBottom: '0',
          fontWeight: '500',
  
      },
  
      infoSectionEndDivider: {
          marginTop: '1rem',
          marginBottom: '0.5rem',
      },
      infoHeaderDivider: {
          marginBottom: '0.5rem',
      },
      infoSectionBody: {
          fontSize: '1rem',
          marginBottom: 0,
          //paddingTop: '0.5rem',
          //paddingBottom: '0.5rem',
      },
      infoSectionBodyFinal: {
          fontSize: '1rem',
          paddingBottom: '1rem',
          marginBottom: '0.5rem',
      },
      insetInfoDivider: {
          marginBottom: '0.5rem',
          marginTop: '0.5rem',
      },
      testSpacing: {
          marginTop: '15rem',
          marginBottom: '15rem',
      },
  
      VerticalStepperClass: {
        //transition: 'all .3s ease-in-out',
      },
  
      Section: {
          border: 'border: 1px solid rgba(var(--b6a,219,219,219),1)',
          borderRadius: '10px',
      },
  
      SectionSeparator: {
          height: '5rem',
      },
  
      SectionSeparatorHalfStep: {
        height: '2.5rem',
      },
  
      InitialAboutSeparator: {
          height: '4.5rem',
      }
});


export default useStyles