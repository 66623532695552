import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppToolBarTitle from '../../../BuildingComponents/TitledContainer/AppToolBarTitle';
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const styles = theme => ({

    ExpansionPanelMainContainer: {
        // height: '80vh',
        // width: '80vw',
        //top: 0,
        // left: 0,
        //position: 'fixed',
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    boxContent: {
        margin: '10px',
    },


    epDescription: {

    },

    epTitle: {

    },


    epH3: {
        marginTop: '2.9rem',
        fontSize: '4.75vw',
        marginBottom: '0.2rem',
        textTransform: 'uppercase',
        marginRight: '0.25rem',
        paddingLeft: '1rem',
    },

    epH4: {
        marginLeft: '1rem',
        fontSize: '3.75vw',
        marginTop: '0.6rem',
        marginBottom: '0.5rem',
    },

    epDescriptionIndent1: {
        marginTop: '2vh',
        marginLeft: '1rem',
        marginBottom: '2rem',
        fontSize: '3.75vw',
        paddingRight: '5px',
        textAlign: 'justify',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
        },
        [theme.breakpoints.up(901)]: {

        },
    },

    epDescriptionIndent2: {
        marginLeft: '2rem',
        fontSize: '3.75vw',
        marginBottom: '1.5rem',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
        },
        [theme.breakpoints.up(901)]: {

        },
    },


    epListLeft: {
        float: 'left',
        marginRight: '20px',
        marginBottom: '2.5rem',
    },

    epListRight: {

    },

    sectionBackground: {
        ///background: '#e2e3ec',
        background: '#d3d3d36e',
        //margin: '-10px',
        //padding: '10px',
        marginTop: '0px',
        paddingBottom: '1px',
        borderRadius: '10px',
    },

    subsectionTitleAndBody: {
        background: '#f2f1f1',
        borderRadius: '2px',
        //borderRadius: '5px',
        boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
        padding: '3px',
    },


    whatCanWeDoForYouContainer: {

    },
});


// Local Storage -- homeHeaderPreviouslyShown
class Software extends Component{


    constructor(props) {
        super(props);
        this.state = {
            local_isChecked: false
        };
    }



    componentDidMount() {}




    render () {
        const {classes} = this.props;
        return (

            <Box my={2} style={{marginTop: '0', padding: '10px'}}>


                <div className={classes.sectionBackground}>

                    <div className={classes.boxContent}>

                        <div className={classes.subsectionTitleAndBody}>

                            <h3 className={classes.epH3}> Types of Websites </h3>

                            <div className={classes.epDescriptionIndent1}
                                 style={{
                                     textAlign: 'unset',
                                 }}
                            >
                                Short of a full-blown social media website, Happel Solutions is capable of websites of any and all sorts.
                            </div>

                            <h4 className={classes.epH4}> Examples of Concepts: </h4>

                            <div className={classes.epDescriptionIndent2}>

                                <div className={classes.epListLeft}
                                     style={{

                                     }}
                                >
                                    <li>Online Store</li>
                                    <li>Art Gallery</li>
                                    <li>General Business Website</li>
                                    <li>Appointment/Scheduler Tool</li>
                                    <li
                                        style={{
                                            textAlignLast: 'end',
                                            marginRight: '2rem',
                                        }}>Restaurant Online Ordering Platform</li>


                                </div>

                            </div>

                            <div className={classes.whatCanWeDoForYouContainer}>

                                <h4 className={classes.epH4}> What can we do for you? </h4>
                                <div className={classes.epDescriptionIndent1}>
                                    Some clients are unsure of exactly what they want, whereas as others have an idea, color scheme, and
                                    site-flow already decided upon.
                                </div>

                                <div className={classes.epDescriptionIndent1}
                                     style={{

                                     }}>
                                    In either situation, websites will be developed incrementally with continually feedback being
                                    provided to the client. Decisions regarding layout, fonts, images, color-schemes, etc. will all
                                    be made and decided upon before the developmental lifecycle begins.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Box>




        );
    }



}

export default withStyles(styles, { withTheme: false })(Software);

/**
 <Paper id={'ServicesAppBarPaper'}>
 <Box my={2} style={{marginTop: '0', padding: '10px'}}>
 {[...new Array(12)]
                                    .map(
                                        () => `Balls`,
                                    )
                                    .join('\n')}
 </Box>
 </Paper>

 **/