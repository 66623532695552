import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
const styles = {

    Paper: {
        position: 'fixed',
        //backgroundColor: 'red',
        top: 0,
        left: 0
    },

    ScrollMonitorText: {
      fontSize: '2rem'
    }

};


// Local Storage -- homeHeaderPreviouslyShown
class ScrollMonitor extends Component{


    constructor(props) {
        super(props);
        this.state = {date: new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render () {
        const { classes } = this.props;

        return (
            <Paper className={classes.Paper}>
                <p className={classes.ScrollMonitorText}>
                    Scrolling
                </p>
                <h2>It is {this.state.date.toLocaleTimeString()}.</h2>

            </Paper>
        );
    }



}

export default withStyles(styles, { withTheme: false })(ScrollMonitor);
