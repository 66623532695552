import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'

import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';  
import { Typography, Box, Divider, Grid} from '@mui/material';
import { Blueprint } from '../../Assets/photos';



const SoftwarePageBasic = ( {classes, directory}) => {

    const container_details = {
        title: 'Software',
        emoji: '💬',
    };
    
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    useEffect(() => {
        // Update the document title using the browser API
        console.log("Classes: " + JSON.stringify(classes));

      });
    
      /**
      <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box style={{ marginTop: '2vh', marginRight: '1rem', marginLeft: '1rem', marginBottom: '2rem', paddingRight: '5px', textAlign: 'justify' }}>
                                            <Typography style={{ fontSize: '14px' }}>
                                                Our team is dedicated to crafting software solutions that align with your unique needs and aspirations. Whether it's enhancing efficiency, boosting customer experiences, or staying ahead in a competitive market, we're here to make it happen.
                                                <br /> <br />
                                                If you are looking to increase efficiency of your business operations, the strategic implementation of software solutions is a vector that you should consider.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box style={{ position: 'sticky', top: '20px', float: 'right', width: '300px', height: '100%' }}>
                                            <img src={Blueprint} alt="blueprint" style={{ width: '100%', height: 'auto' }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
    
**/
    const getContent = () => {
        
        //const {classes} = this.props;
        const r = " ";

        return (
            <Box my={2} style={{ marginTop: '0', padding: '10px' }}>
                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }}>  
                        <Box style={{  background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '1rem' }}>

                        
                            
                            <Typography variant="h3" 
                                style={{ 
                                marginTop: '3rem', marginBottom: '0.5rem', textTransform: 'uppercase', fontWeight: '700', fontStyle: 'italic',
                                marginRight: '0.25rem', fontSize: '1.05rem' }}
                                >
                                Software Development
                            </Typography>
                            
                            

                            <Box style={{ }}>
                                    <Box style={{maxWidth: "900px"}} >
                                        <Typography style={{fontSize: "14px"}}>
                                            Our team is dedicated to crafting software solutions that align with your unique needs and aspirations. Whether it's enhancing efficiency, boosting customer experiences, or staying ahead in a competitive market, we're here to make it happen.
                                            <br /><br />
                                            If you are looking to increase efficiency of your business operations, the strategic implementation of software solutions is a vector that you should consider.
                                        </Typography>
                                        
                                        
                                    </Box>
                                
                            </Box>

                            <Typography variant="h4" style={{ marginLeft: '1rem', marginTop: '0.6rem', marginBottom: '0.5rem', fontSize: '1.05rem' }}>
                                Examples of Concepts:
                            </Typography>
                            <Box style={{ marginLeft: '2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                <Box style={{ float: 'left', marginRight: '20px', marginBottom: '2.5rem', listStyle: 'circle' }}>
                                    <li>Online Store</li>
                                    <li>Art Gallery</li>
                                    <li>General Business Website</li>
                                    <li>Appointment/Scheduler Tool</li>
                                    <li style={{ textAlignLast: 'end', marginRight: '2rem' }}>Restaurant Online Ordering Platform</li>
                                </Box>
                            </Box>
                            <Box style={{ display: 'inline-block' }}>
                                <Typography variant="h4" style={{ marginLeft: '1rem', marginTop: '0.6rem', marginBottom: '0.5rem', fontSize: '1.05rem' }}>
                                    What can we do for you?
                                </Typography>
                                <Box style={{ marginLeft: '1rem', marginTop: '2vh', marginBottom: '2rem', paddingRight: '5px', textAlign: 'justify', fontSize: '1.05rem' }}>
                                    Some clients are unsure of exactly what they want, whereas as others have an idea, color scheme, and site-flow already decided upon.
                                </Box>
                                <Box style={{ marginLeft: '1rem', marginTop: '2vh', marginBottom: '2rem', paddingRight: '5px', textAlign: 'justify', fontSize: '1.05rem' }}>
                                    In either situation, websites will be developed incrementally with continually feedback being provided to the client. Decisions regarding layout, fonts, images, color-schemes, etc. will all be made and decided upon before the developmental lifecycle begins.
                                </Box>
                            </Box>
                        </Box>
                    
                </Box>

                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }} id="testing">
                    <Box style={{ margin: '10px' }}>
                        <Box style={{ background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '3px' }}>
                            <Typography variant="h3" style={{ marginTop: '2.9rem', marginBottom: '0.2rem', textTransform: 'uppercase', marginRight: '0.25rem', paddingLeft: '1rem', fontSize: '1.05rem' }}>
                                The Framework We Use
                            </Typography>
                            <Box style={{ marginTop: '2vh', marginLeft: '1rem', marginBottom: '2rem', paddingRight: '5px', textAlign: 'justify', fontSize: '1.05rem' }}>
                                Happel Solutions uses <i><b>React</b></i>&nbsp;as the primary web-framework in the development of all of its websites.
                            </Box>
                            <Typography variant="h4" style={{ marginLeft: '1rem', marginTop: '0.6rem', marginBottom: '0.5rem', fontSize: '1.05rem' }}>
                                What is React?
                            </Typography>
                            <Box style={{ marginLeft: '2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                Developed by Facebook, React is a web-framework which allows for easy compartmentalization, interaction and cohesion between components of a website.
                            </Box>
                            <Typography variant="h4" style={{ marginLeft: '1rem', marginTop: '0.6rem', marginBottom: '0.5rem', fontSize: '1.05rem' }}>
                                Who uses React?
                            </Typography>
                            <Box style={{ marginLeft: '2rem', marginBottom: '3rem', fontSize: '1.05rem' }}>
                                <Box style={{ float: 'left', marginRight: '20px', listStyle: 'circle' }}>
                                    <li>Instagram</li>
                                    <li>Airbnb</li>
                                    <li>Dropbox</li>
                                    <li>Many more</li>
                                </Box>
                                <Box style={{ listStyle: 'circle' }}>
                                    <li>Yahoo! Mail</li>
                                    <li>VSCO</li>
                                    <li>Snapchat</li>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }}>
                    <Box style={{ margin: '10px' }}>
                        <Box style={{ background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '3px' }}>
                            <Typography variant="h3" style={{ marginTop: '3.9rem', fontSize: '1.05rem' }}>
                                Hosting
                            </Typography>
                            <Typography variant="h4" style={{ marginTop: '0.75rem', marginBottom: '0.2rem', fontSize: '1.05rem' }}>
                                Domain Name
                            </Typography>
                            <Box style={{ marginTop: '0.1rem', marginBottom: '0.35rem', fontSize: '1.05rem' }}>
                                A domain name is the website address:
                            </Box>
                            <Box style={{ marginTop: '0.1rem', marginBottom: '0.35rem', fontSize: '1.05rem' }}>
                                <i> e.g. google.com </i>
                            </Box>
                            <Box style={{ fontSize: '1.05rem' }}>
                                Already have a website? Great! We can use that domain name. If you don't, we can help you purchase one.
                            </Box>
                            <Typography variant="h4" style={{ marginTop: '0.75rem', marginBottom: '0.2rem', fontSize: '1.05rem' }}>
                                How Do Websites Stay Online?
                            </Typography>
                            <Box style={{ marginTop: '0.1rem', marginBottom: '0.35rem', fontSize: '1.05rem' }}>
                                Essentially, a website's content is hosted on a server and is made accessible through various authentication and identification processes and protocols.
                            </Box>
                            <Typography variant="h4" style={{ marginTop: '0.75rem', marginBottom: '0.2rem', fontSize: '1.05rem' }}>
                                How Your Website Will Be Hosted
                            </Typography>
                            <Box style={{ marginTop: '0.1rem', marginBottom: '0.35rem', fontSize: '1.05rem' }}>
                                Happel Solutions currently hosts our client's websites on Google Cloud. The reason for this is two-fold; not only is Google Cloud a reliable web hosting platform, but also, their system allows for ease of shared-access to websites.
                                <br /> <br /> In essence, our clients are able to maintain full ownership of their website while allowing a third-party to access and monitor its health.
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

        );
    };
    
    



    const content = getContent();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };


  return (
    <> 
    <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

        style={{ background: '#353535', }}
        {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
        }}
        /> 
    </>
  )
}

export default withStyles({}, { withTheme: false }) (SoftwarePageBasic);