import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
import useWindowSize from "@rooks/use-window-size";

import headerImage from '../../Assets/photos/studio.jpg'
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ParticleLogo from "../features/ParticleLogo";
import ParticleImage, {
    ParticleOptions,
    Vector,
    forces,
    ParticleForce
} from "react-particle-image";
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import HomepageTitleText from "../text/HomepageTitleText";


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '100%',

        //padding: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {

            // backgroundColor: theme.palette.secondary.main,
        },

        [theme.breakpoints.up('md')]: {
            // backgroundColor: theme.palette.primary.main,
        },

        [theme.breakpoints.up('lg')]: {
            // backgroundColor: green[500],
        },

    },

    MainContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 'none',
        padding: 0,
        margin: 0,
        align: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {

            //backgroundColor: theme.palette.primary.main,
        },

        [theme.breakpoints.up('md')]: {
            //  backgroundColor: theme.palette.secondary.main,
        },

        [theme.breakpoints.up('lg')]: {
            //  backgroundColor: green[500],
        },

    },

    FlexRow: {
        width: 'auto',
        //border: '1px solid blue',
    },

    BackgroundImageAndFlexColumn: {
        width: 'auto',
        flexDirection: 'column', // Parent to ParticleImageContainer and ButtonContainer
        //border: '1px solid blue',
        [theme.breakpoints.down('sm')]: {
            height: '60vh',
        },

        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },

        [theme.breakpoints.up('lg')]: {
            height: '100vh',
        },
    },

    TitleContainer: {
        //order: 1,
        textAlign: 'center',
        marginTop: '20%',

    },

    ParticleImageContainer: {
        display: 'flex',
        order: 1,
        //paddingLeft: 0,
        //paddingRight: 0,
    },

    ParticleImageComponent: {
        //display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '8rem',
        paddingRight: '1rem',
    },

    ButtonContainer: {
        //width: '100%',
        order: 2,
        textAlign: 'center',
    },

    ArrowButton: {
        //marginLeft: '46%',
        //marginTop: '58%',
        //position: 'relative',

        [theme.breakpoints.down('sm')]: {
            // marginLeft: '40%',
        },
    },

    ArrowSVG: {
        //position: 'absolute',
    }



});



function DownArrow(...props) {
    //console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}


// Local Storage -- homeHeaderPreviouslyShown
class FixedCloudClickToScroll extends Component{


    constructor(props) {
        super(props);
        this.state = {
            firstTimeRendering: true,
            selectedIndex: 0,
            hasMounted: false,
            lightboxIsOpen: false,
            displayHeadImage: true,

        };

        this.myRef = React.createRef();

        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            ///document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }



    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    componentDidUpdate() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    componentDidMount() {
        /**** C H A N G E    B A C K    IF   U S I N G    CLICK2SCROLL     **********/

        //alert(localStorage.getItem('headImageShown')); 
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        //document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }



    render () {

        const { classes } = this.props;

        //console.log('Fixed Cloud Click Scrolle: ', this.props.refToScrollTo.current);

        const particleOptions: ParticleOptions = {
            filter: ({ x, y, image }) => {
                // Get pixel
                const pixel = image.get(x, y);
                // Make a particle for this pixel if blue > 50 (range 0-255)
                return pixel.b > 50;
            },
            color: ({ x, y, image }) => "#61dafb",
            radius: () => Math.random() * 1.5 + 0.5,
            mass: () => 40,
            friction: () => 0.15,
            initialPosition: ({ canvasDimensions }) => {
                return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
            }
        };

        const motionForce = (x: number, y: number): ParticleForce => {
            return forces.disturbance(x, y, 5);
        };

        const typistEventHandler = data => {
            if (data.typistDone === 'true') {
                console.log('> App.js - Typist Done!');
                this.setState({typistDone: true});
            }
            //console.log('Typist Event Handler: ', data);
        };


        //console.log('Local Storage, headImageShown: ', localStorage.getItem('headImageShown'));


        return (
            // FixedCloudClickToScroll class
            <div className={classes.root} style={{overflow: 'hidden'}}>

                {this.state.displayHeadImage ? (

                    <Container className={classes.MainContainer}>

                        <header id={'headimage-cloud'} className={classes.BackgroundImageAndFlexColumn} style={{show:true}}>




                                {window.screen.availWidth <= 900 ? (

                                    <Container className={classes.ParticleImageContainer}
                                               style={{
                                                   padding: '0',
                                               }}
                                    >
                                        <HomepageTitleText  onChange={typistEventHandler}/>
                                    </Container>

                                    ) :
                                    <Container className={classes.ParticleImageContainer}
                                               style={{
                                                   padding: '0',
                                               }}

                                    >
                                        <HomepageTitleText onChange={typistEventHandler}/>
                                    </Container>
                                }


                            <Container className={classes.ButtonContainer}>
                            <Button id={'home-header-down-arrow'}
                                    className={classes.ArrowButton}
                                    onClick={this.scrollToMyRef}
                                    onChange={this.handleChange}>
                                <DownArrow className={classes.ArrowSVG}/>
                            </Button>
                                


                            </Container>

                            <div className={classes.hiddenRef} ref={this.myRef}></div>

                        </header>

                    </Container>
                ):null}

            </div>

        );
    }

    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.props.refToScrollTo.ref.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };




}

export default withStyles(styles, { withTheme: true })(FixedCloudClickToScroll);


/**
 <Container className={classes.TitleContainer}>
 <Typography className={classes.denText} variant="h3" component="h4">
 Enter the Den...
 </Typography>
 </Container>
 **/
/**
 {window.screen.availWidth <= 901 ? (
    <Button id={'home-header-down-arrow'}
            className={classes.ArrowButton}
            onClick={this.scrollToMyRef}
            onChange={this.handleChange}>
        <DownArrow className={classes.ArrowSVG}/>
    </Button>
) :
<p
    style={{
        position: 'relative',
        fontSize: '2rem',
        color: 'black',
        left: '2rem',
    }}
> Please visit on a mobile device </p>
}
**/