import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {

    photo: { },

    bdi_h1: {
        position: "absolute",
        zIndex: 1,
        transform: "translate(-25%, -65%)",
        fontFamily: "'Montserrat', sans-serif",
        margin: "0",
        lineHeight: 1.2
    },

    photo_image: {},

    glow_wrap: { },

    glow: { },


};


// Local Storage -- homeHeaderPreviouslyShown
class BackdropImageButton extends Component{

//this.handleStatusChange = this.handleStatusChange.bind(this);
    constructor(props) {
        super(props);
        this.state = {open: false};
    }


    /**
    buttonPressed = () => {
        console.log('> BackdropImageButton.js ==> button pressed, state of open: ', this.state.open);
        //console.log('> BackdropImageButton.js - e: ', e);
        if (this.state.open !== 'false') {
            this.setState({open: false})
        } else {
            this.setState({open: true})
        }

        this.setState({open: !this.state.open});

        this.props.onClick(this.props.index);
    };


     **/

    handleChange = e => {
        //console.log('> BackdropImageButton.js ==> handleChange');
        //console.log('> BackdropImageButton.js - e: ', e);
    };

    componentDidMount() {}

//onChange={this.props.onChange(this.state.open, this.props.index)}
    render () {
        const { classes } = this.props;

        //console.log("> BackdropImageButton.js ===> Rendering... ");
        //console.log("> BackdropImageButton.js - Props: ", this.props);
        //console.log("> BackdropImageButton.js - State: : ", this.state);
        return (
            <Fragment>
                <div href={"#"} id={'ButtonAndBackdropContainer' + this.props.index} style={{display: 'flex'}} className={classes.photo}  onClick={this.props.onClick}>
                    <img className={classes.photo_image} id={'ButtonImage' + this.props.index} src={this.props.image_file} alt={"oops"}/>
                    <div className={classes.glow_wrap}>
                        <i className={classes.glow}></i>
                    </div>
                </div>
            </Fragment>

        );
    }



}

export default withStyles(styles, { withTheme: false })(BackdropImageButton);


//                <h1 className={classes.bdi_h1}>Amber Heard</h1> lamotrigine