import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    modifiedAlternativeLabelStep: {
        top: '0px',
        left: '0.85rem',
        right: 'initial',
        position: 'absolute',
    },
    Stepper: {
        zIndex: 1040,
        position: 'fixed',
        top: '10rem',
        [theme.breakpoints.down('sm')]: {
            width: '2rem',
            paddingLeft: '2%',
            //backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('lg')]: {
            width: '2rem',
            paddingLeft: '2%',
            paddingRight: '3%',
            //paddingTop: '1.8rem',

            marginTop: '-1.8rem',
            //backgroundColor: theme.palette.primary.main,
        },
    },
    Step: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5rem',
            //backgroundColor: theme.palette.primary.main,
        },
    }


}));

function getSteps() {
    if (window.availHeight > 768) {
        //return ['About', 'Services', 'Showcase', 'Contact', 'Quotes'];
        return ['About', 'Services', 'Showcase', 'Contact'];
    } else {
        //return ['About', 'Services', 'Showcase', 'Contact', 'Quotes'];
        return ['About', 'Services', 'Showcase', 'Contact'];
    }
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Step 1: Select campaign settings...';
        case 1:
            return 'Step 2: What is an ad group anyways?';
        case 2:
            return 'Step 3: This is the bit I really care about!';
        case 3:
            return 'Step 4: Hello';
        case 4:
            return 'Step 5: Bye';
        case 5:
            return 'Step 6 Hi';
        default:
            return 'Unknown step';
    }
}

export default function VerticalStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [skipped, setSkipped] = React.useState(new Set());
    const [selectedStep, setSelectedStep] = React.useState('initial');
    const steps = getSteps();

    useEffect(() => {
        //console.log('Vertical Stepper Props: ', props);
        setActiveStep(props.stage);
        // Update the document title using the browser API
//        document.title = ` ${selectedStep} times`;
    });


    const totalSteps = () => {
        return getSteps().length;
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const skippedSteps = () => {
        return skipped.size;
    };

    const completedSteps = () => {
        return completed.size;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed
                  // find the first step that has been completed
                steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1;

        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        //console.log("This is props: ", props.onChange);
        //console.log("VerticalStepper.js - Current Step: ", activeStep);
        //console.log("VerticalStepper.js - Handle Change: ", step);
        setActiveStep(step);
        setSelectedStep(step);
        props.onChange(step);

    };



    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);

        /**
         * Sigh... it would be much nicer to replace the following if conditional with
         * `if (!this.allStepsComplete())` however state is not set when we do this,
         * thus we have to resort to not being very DRY.
         */
        if (completed.size !== totalSteps() - skippedSteps()) {
            handleNext();
        }
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted(new Set());
        setSkipped(new Set());
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }


    const handleChange = e => {
        //console.log("VerticalStepper.js - Current Step: ", activeStep);
        //console.log("VerticalStepper.js - Handle Change: ", e);
        setSelectedStep(e);
    };


    return (
        <div className={classes.root} >
            <Stepper
                className={classes.Stepper}
                nonLinear
                activeStep={activeStep}
                orientation="vertical"
                id={"VerticalStepperDiv"}
                ready={false}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};

                    return (
                        <Step key={label} {...stepProps} className={classes.Step} style={{fontSize: '0.5rem'}}>
                            <StepButton
                                onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                onChange={handleChange}
                                {...buttonProps}
                                style={{fontSize: '0.5rem !important'}}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>

                        <div>


                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

//<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
