import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'

import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';  
import { Typography, Box, Divider} from '@mui/material';
import { MultipleHeadsCompressed} from '../../Assets/photos';



const SoftwarePageAttemptThree = ( {classes, directory}) => {

    const container_details = {
        title: 'Software',
        emoji: '💬',
    };
    
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    useEffect(() => {
        // Update the document title using the browser API
        console.log("Classes: " + JSON.stringify(classes));

      });
    
    
    const getContent = () => {
        
        return (
            <Box my={2} style={{ 
                marginTop: '0', padding: '10px',
                ...(viewportWidth < 600 && { padding: '0px', }), // Apply styles for mobile
            }}>
                <Helmet>
                    <title>Software | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="Software | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="Software | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/services/software" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }}>
                    <Box style={{ margin: '10px' }}>
                        <Box style={{ background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '3px' }}>
                            
                           
                            
                            

                            <div style={{ padding: '20px' }}>
                                
                                <div style={{ overflow: 'hidden' }}>
                                    
                                    
                                    
                                    <Box id='intro-text'
                                        style={{
                                        ...(viewportWidth >= 600 && { fontSize: '1.4rem', paddingLeft: "15%", paddingRight: "9%"}), // Apply styles for non-mobile
                                        ...(viewportWidth < 600 && { maxWidth: "900px", fontSize: '1.05rem', }), // Apply styles for mobile
                                        }}>

                                         <Typography variant="h3" 
                                            style={{ marginTop: '2.9rem', marginBottom: '0.2rem', textTransform: 'uppercase', fontWeight: '700', fontStyle: 'italic',
                                                     ...(viewportWidth >= 600 && { margin: '4rem', /*textAlign: "center",*/ fontSize: '1.8rem', marginLeft: '11vw'}), // Apply styles for Desktop
                                                     ...(viewportWidth < 600 && { fontSize: '1.35rem', textAlign: 'center', marginBottom: '2rem' }), // Apply styles for mobile
                                            }}>

                                                                        Software Development
                                        </Typography>

                                        <Box id='pic'
                                        style= {{
                                                ...(viewportWidth >= 600 && {width: '400px', float: 'right', paddingRight: "2%", paddingLeft: "10%" }), // Apply styles for Desktop
                                                ...(viewportWidth < 600 && { width: '331', float: 'none', marginLeft: 'auto', textAlign: 'center'}), // Apply styles for mobile
                                        }}>
                                    
                                        <img src={MultipleHeadsCompressed} alt="blueprint" style={{ 
                                            maxWidth: '100%', height: 'auto', borderRadius: '4pc',
                                            ...(viewportWidth < 600 && { maxWidth: '63%', borderStyle: 'solid', borderColor: 'lightslategrey' }),
                                            }} />
                                    </Box>
                                        

                                        <Typography style={{
                                             ...(viewportWidth < 600 && { marginTop: '2rem', marginLeft:'15px' }),
                                        }}>        
                                            Our team is dedicated to crafting software solutions that align with your unique needs and aspirations. Whether it's enhancing efficiency, boosting customer experiences, or staying ahead in a competitive market, we're here to make it happen. If you are looking to increase the efficiency of your business operations, the strategic implementation of software solutions is an avenue that you should consider.<br/><br/>

                                            In today's digital landscape, off-the-shelf software can often fall short of meeting the nuanced requirements that make your business unique. We believe that you should never have to adapt your business processes to fit the limitations of a software package. Instead, our skilled team of developers, designers, and analysts work closely with you to understand the specific needs, challenges, and goals of your business. This helps us tailor a software solution that not only addresses your immediate concerns but also positions you for future growth and adaptability.<br/><br/>

                                            Speed and responsiveness are of the essence in the modern business world, which is why we employ agile methodologies in our software development process. These methodologies allow us to quickly adapt to changes, whether they are shifts in the market or new insights into customer behavior. Our approach ensures not only that you get your solution promptly but also that it is adaptable and scalable. This way, your investment in custom software becomes a long-term asset that grows with your business.<br/><br/>

                                            We also understand that your organization may not operate on a single platform or device. From desktops to mobile devices, we aim to deliver solutions with cross-platform capabilities, ensuring a seamless user experience regardless of the hardware being used. Compatibility issues are a concern of the past, as we strive to create software that integrates effortlessly with existing systems and databases.<br/><br/>

                                            But our job doesn’t end once the software is deployed. We believe in building long-term relationships with our clients. That's why we offer comprehensive after-sales support, including training, maintenance, and regular updates, to ensure that the software continues to serve your needs effectively.<br/><br/>

                                            So, whether you are a startup looking for a competitive edge or an established company aiming to modernize your operations, our tailored software solutions offer a pathway to not just meet but exceed your objectives. Together, we can turn your vision into a functioning reality, giving you the tools you need to succeed in a rapidly evolving digital environment.
                                        </Typography>
                                        
                                        {/**Added this for testing */}
                                        
                                        <Divider style={{marginTop: "2rem"}}/>
                                        
                                        
                                        <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '700' }}>
                                            Need Inspiration? Here Are Some Examples:
                                        </Typography>
                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{ float: 'left', marginRight: '20px', marginBottom: '2.5rem', listStyle: 'circle' }}>
                                                
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Supply Chain Transparency Software for You <br/> </Typography>
                                                    We can craft an unparalleled software system that embeds transparency into your unique supply chain operations. This would involve meticulous tracking of raw material origins, robust monitoring of transportation phases, and ensuring that your sourcing practices are both ethical and sustainable. With such a system in place, you won't just be managing your supply chain; you'll be mastering it.
                                                </Typography>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Your Personalized Emotion Analysis for Customer Service <br/> </Typography>
                                                    If empathetic customer service is your aim, then our emotion analysis software is your game-changer. Through advanced natural language processing and sentiment analysis, this software evaluates the emotional tone of customer interactions in real-time, guiding your customer service team to respond with unparalleled tact and empathy.
                                                </Typography>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Virtual Event and Trade Show Platform, Tailored to Your Brand <br/> </Typography>
                                                    Are you missing out on connecting with a broader audience due to the constraints of physical events? We can create a virtual event and trade show platform that is an authentic extension of your brand. Host conferences, trade shows, and events that provide immersive experiences and break geographical boundaries.
                                                </Typography>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Your Automated Invoice and Payment Tracker <br/> </Typography>
                                                    Cash flow is the lifeline of your business. We offer an automated invoice and payment tracker that does the tedious work for you. It will integrate seamlessly with your existing accounting software and provide valuable analytics, making it easier to keep your finances in the green
                                                </Typography>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Streamlined Travel and Expense Manager Just for Your Team <br/> </Typography>
                                                    Your employees shouldn't have to struggle with travel bookings or expense reports. Our customized travel and expense manager simplifies these processes and even offers insights to help you fine-tune your travel budgeting. Designed with your team in mind, this tool takes the hassle out of business travel.
                                                </Typography>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Predictive Maintenance Software Designed for Your Machinery <br/> </Typography>
                                                    Downtime is costly. Our predictive maintenance software uses advanced analytics and machine learning algorithms tailored to the specifics of your machinery. The result? A smart system that alerts you well before any equipment requires maintenance, optimizing your operational schedules and saving you from expensive breakdowns.
                                                </Typography>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '600'}}> Remote Surveillance and Security That You Control <br/> </Typography>
                                                    Security shouldn't be a concern that keeps you up at night. Our remote surveillance and security system allows you to monitor all your business locations through a single interface. Designed with your peace of mind as the ultimate goal, this software ensures that you can focus on what really matters: running a successful business.
                                                </Typography>
                                        
                                                <Divider style={{marginTop: "2rem"}}/>
                                        
                                            </Box>
                                        </Box>

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{ marginRight: '20px', marginBottom: '2.5rem'  }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> What Sets Us Apart <br/> <br/> </Typography>
                                                    What truly differentiates us from others is not just the quality of our work but also the relationships we build with our clients. Each project is a partnership, and your success is our success. By collaborating closely with you, we aim to go beyond meeting your expectations—we aim to exceed them. With us by your side, you're not just getting a software solution; you're getting a long-term ally in achieving your business aspirations.
                                                </Typography>


                                            </Box>
                                        </Box>

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{   marginRight: '20px', marginBottom: '2.5rem', }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> A Future-Proof Investment <br/> <br/> </Typography>
                                                    In a rapidly evolving technological landscape, the last thing you want is to invest in software that becomes obsolete within a few years—or even months. That's why our approach to software development incorporates future-proofing elements right from the start. We incorporate modular designs and flexible architectures that can adapt to emerging technologies and business models. Your custom software solution won't be a one-time investment, but a sustainable asset that evolves with you, offering long-term value and adaptability.
                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Security and Compliance: <br/> <br/> </Typography>
                                                    In the era of data breaches and heightened scrutiny over data management practices, security isn't just a feature—it's a necessity. We adhere to industry-standard security protocols and data encryption techniques to ensure the sanctity and confidentiality of your business information. But our commitment doesn't end there. We also stay abreast of evolving compliance requirements related to data protection regulations so that your software solution is not just secure but also compliant with laws like GDPR, HIPAA, or any other applicable in your industry.
                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                        <Box style={{   marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}>
                                                    Seamless Integration
                                                    <br/><br/>
                                                </Typography>
                                                We recognize that your business may already be using various software tools and platforms for different aspects of operations. Our aim is to create software solutions that integrate effortlessly with your existing systems. Through robust APIs and thoughtful architecture, we ensure that your new software becomes a natural extension of your current tech ecosystem, facilitating a more unified and efficient operational experience.
                                            </Typography>
                                            
                                        </Box>

                                        <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}>
                                                    After-Sales Support
                                                    <br/><br/>
                                                </Typography>
                                                Your relationship with us doesn't end with the successful deployment of your custom software. Our comprehensive after-sales support includes everything from staff training and software maintenance to regular updates. We remain committed to ensuring that your software solution continues to meet your business needs effectively, long after its initial implementation.
                                            </Typography>
                                            
                                        </Box>

                                        <Box style={{ marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}>
                                                    Don't Just Take Our Word For It
                                                    <br/><br/>
                                                </Typography>
                                                While we can list a myriad of reasons why we believe our services are right for you, we understand that actions speak louder than words. We encourage you to reach out to us for a no-obligation consultation. During this meeting, we'll discuss your specific business needs and show you firsthand how our tailored solutions can solve your unique challenges. It’s an opportunity for us to learn about your vision and for you to evaluate our capabilities—a win-win scenario.
                                            </Typography>
                                            
                                        </Box>

                                        <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}>
                                                    Your Success is Our Blueprint
                                                    <br/><br/>
                                                </Typography>
                                                At the end of the day, our most significant metric for success is your success. Our passion for technology is matched only by our desire to see your business flourish. Whether you're a startup dreaming big or an established organization looking to maintain your competitive edge, our bespoke software solutions are crafted with your unique business objectives in mind. Together, let's turn your vision into a functioning reality, setting the stage for sustainable growth and enduring success.
                                            </Typography>
                                            <Divider style={{marginTop: "2rem"}}/>
                                        </Box>
                                        
                                        <Box style={{  marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}>
                                                Let's Transform Your Business Together
                                                    <br/><br/>
                                                </Typography>
                                                    We're excited to embark on a journey of innovation and growth alongside you. Our team at Happel Solutions is dedicated to crafting software solutions that align with your unique needs and aspirations. Whether it's enhancing efficiency, boosting customer experiences, or staying ahead in a competitive market, we're here to make it happen.
                                                    <br/> <br/>
                                                    To get started, feel free to reach out through our convenient contact form. If you prefer, connect with us through any of our social media pages. Your success is our priority, and we're here to answer your questions, discuss your requirements, and explore how our solutions can revolutionize your business operations.
                                                    <br/> <br/>
                                                    Let's turn your vision into reality. Reach out to us today, and let's begin this exciting transformation journey together.
                                            </Typography>
                                            
                                        </Box>


                                    </Box>

                                </div>

                            </div>
                            

                            
                        </Box>
                    </Box>
                </Box>

                

                
            </Box>

        );
    };
    
    



    const content = getContent();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };


  return (
    <> 
    <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

        style={{ background: '#353535', }}
        {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
        }}
        /> 
    </>
  )
}

export default withStyles({}, { withTheme: false }) (SoftwarePageAttemptThree);