import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, Grid } from '@material-ui/core';

const FormInput = ( { 
    register,
    name,
    label,
    type,
    default_value,
    required,
    required_message,
    pattern,
    pattern_message
    
    }) => {


    return (
        
      <Grid item xs={12} >
      

        <TextField 
                inputProps={{  style: {   height: '10px' }, }}
                label={label}
                name={name}
                type={type}                        
                required={required}
                defaultValue={default_value}
                className="input" 
            />  
        </Grid>

    );
}

export default FormInput;
//<Grid item xs={12} >
/**
 * 
 * 
 * <Grid item xs={12} sm={6}>

                name={name}
                control={control}

                render={({ field }) => (
                
                    <TextField
                        value={inputData}
                        fullWidth
                        label={label}
                        required
                        onChange={e => setInputData(e.target.value)}
                    />
                    )}
            </Grid>            
 * 
 * 
 * 
 * 
 * <Controller
                    render={({ field }) => <TextField {...field} />}
                   
                    label={label}
                    fullWidth

                    
                />
 * 
 *                     value={e.target.value}

 */


                /*
                <Controller
          //as={TextField}
          name={name}
          control={control}
          render = {({field}) => (
              <TextField 
                label={label}
                fullWidth
                required={required}
                error={isError}
                //variant="filled"
                inputProps={{
                  style: {
                    height: '10px'
                  },
                }}
                />
          )}
          
        />
        */