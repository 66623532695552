import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardMedia, CardContent, CardActions, Container, Typography, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import useStyles from './styles';

import LinearBuffer from './LinearBuffer';

const QR = () => {
    const classes = useStyles();

    const fadeOut = (phrase) => {
        phrase.classList.add("hidden");
    }

    const changeToTableCell = ( to , from ) => {
        to.style.display = "none";
        from.style.display = "table-cell";
    }

    const changeToTableRow = ( to , from ) => {
        to.style.display = "none";
        from.style.display = "table-row";
    }

    const exposePhraseAsCell= ( phrase ) => {
        phrase.style.display = "table-cell";

    }

    const exposePhraseAsRow= ( phrase ) => {
        phrase.style.display = "table-row";

    }


    const onComplete = () => {
        alert('Done')
    }

    const startSequence = (phraseOne, phraseTwo, phraseThree, phraseFour) => {

        setTimeout(()=> {
            fadeOut(phraseOne);

            setTimeout( () => {
                changeToTableCell(phraseOne, phraseTwo);
                
                setTimeout( () => {
                    fadeOut(phraseTwo);

                    setTimeout( () => {
                        // Conacting Us? Just As Easy
                        changeToTableCell(phraseTwo, phraseThree);
                        exposePhraseAsRow(phraseFour);
                        
                        setTimeout( () => {
                            // Redirect to home directory
                            redirectToHome();
                        }, 6000)

                    }, 1000)
                }, 2500)
            }, 750)
        }, 2000) // display for 3 seconds before fadeOut 
    }


    const redirectToHome = () => {
       window.location = "/"
    }

    const waitForRedirect = () => {
//const getR
    }

    useEffect(() => {
        const phraseOne = document.getElementById('popout-1');
        const phraseTwo = document.getElementById('popout-2');
        const phraseThree = document.getElementById('popout-3');
        const phraseFour = document.getElementById('popout-4');
        startSequence(phraseOne, phraseTwo, phraseThree, phraseFour);
    }, )


    return (

    
            <Container  maxWidth="sm" className={classes.QRContainer}>
                <meta name="robots" content="noindex"></meta>
                <Helmet>
                    <title>QR Code | Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="QR Code | Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="QR Code | Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/QR/" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                
                    


                    <p className={classes.popoutOne} id="popout-1">
                        <span>H</span> <span>E</span> <span>Y</span> <span>👋</span>
                    </p>

                    <p className={classes.popoutTwo} id="popout-2">
                        <span>Y</span>
                        <span>O</span>
                        <span>U</span><br/>
                        <span>S</span>
                        <span>C</span>
                        <span>A</span>
                        <span>N</span>
                        <span>N</span>
                        <span>E</span>
                        <span>D</span><br/>
                        <span>T</span>
                        <span>H</span>
                        <span>E</span>
                        <span>&nbsp;</span>
                        <span>Q</span>
                        <span>R</span><br/>
                        <span>C</span>
                        <span>O</span>
                        <span>D</span>
                        <span>E</span>
                        <span>!</span>
                        <br/> <br/>
                        <span id='that-was-easy'> T </span>
                        <span id='that-was-easy'> H </span>
                        <span id='that-was-easy'> A </span>
                        <span id='that-was-easy'> T </span>
                        <span id='that-was-easy'>&nbsp;</span>
                        <span id='that-was-easy'>W </span>
                        <span id='that-was-easy'>A </span>
                        <span id='that-was-easy'>S</span>
                        <span id='that-was-easy'>&nbsp;</span>
                        <span id='that-was-easy'>E </span>
                        <span id='that-was-easy'>A </span>
                        <span id='that-was-easy'>S</span>
                        <span id='that-was-easy'>Y</span>

                    </p>

                    <p className={classes.contactInformingTitle} id="popout-3">
                     <span> Contacting us? <br/> Just as easy </span>
                    </p>

                    <p className={classes.contactInformingParagraph} id="popout-4">
                 
                        <span> 
                        Contacting us is a simple, quick, and anxiety-free experience. In addition to email, fast responses can be expected on
                        both Facebook and Instagram. If an organization or individual would like to arrange a formal meeting, a time can be selected
                        using our scheduler tool. <br/> <br/> <strong> We look forward to hearing from you. </strong>
                        </span>
                    

                    </p>

                    <LinearBuffer onComplete={onComplete}/>
                    
                
            </Container>
    
    )
}
 
export default QR
//Thanks for scanning the QR code!
//<div class="bg123"></div>
//                    <p className={classes.eyes}> 👀 </p>

//👀
/**
<Typography variant="h6" gutterBottom component="div" className={classes.WelcomeText}>
                        I see you scanned the QR Code
                    </Typography>
                    <Typography variant="h6" gutterBottom component="div" className={classes.EyesEmojiText}>
                        👀👀
                    </Typography>

                    **/

/**
                    <span>A</span>
                    <span>W</span>
                    <span>E</span>
                    <span>S</span>
                    <span>O</span>
                    <span>M</span>
                    <span>E</span>
                    <span>!</span>

                    <span>C</span>
                       <span>O</span>                                                
                       <span>N</span>
                       <span>T</span>
                       <span>A</span>
                       <span>C</span>
                       <span>T</span>
                       <span>I</span>
                       <span>N</span>
                       <span>G</span>
                    **/