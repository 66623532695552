import React, {Component, Fragment, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet'
import { textVide } from 'text-vide';
import { useNavigate } from 'react-router-dom';

import withStyles from "@material-ui/core/styles/withStyles";

import Link from '@material-ui/core/Link';

import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Routes from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Typography from '@mui/material/Typography';

import AppToolBarTitle from '../../BuildingComponents/TitledContainer/AppToolBarTitle';
import Backdrop from '@material-ui/core/Backdrop';
import ServicesExpansionPanel from './ServicesOffered/ServicesExpansionPanel';
import Grow from "@material-ui/core/Grow";

import Websites from "./expansion_panels/websites";
import Software from "./expansion_panels/software";
import FixedDrawerMenu from '../../BuildingComponents/FixedDrawerMenu/FixedDrawerMenu';
import DrawerTitleContainer from '../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';

import ReadMoreIcon from '@mui/icons-material/ReadMore';

import useStyles from './styles';



// ----------- Hide App Bar ----------- //
function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


function HideAppBar(props) {
    return (
        <React.Fragment>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6">Scroll to Hide App Bar</Typography>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
            <Container id={'HideAppBar'}>
                <Box my={2}>
                    {[...new Array(12)]
                        .map(
                            () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                        )
                        .join('\n')}
                </Box>
            </Container>
        </React.Fragment>
    );
}

// ------------------------------- //


function returnExpansionContent(services_info, container_id, classes, onChangeFunction) {


    function getComponent(component_name) {

        switch (component_name) {
            case 'sites':
                return <Websites/>;
            case 'software':
                return <Software/>;
            case 'backup':
                return <Websites/>;
            default:
                return <Websites/>;
        }
    }


    let retrieved_component = getComponent(services_info.ref_code);
    //console.log('ABC: ', abc);

    //if (services_info.ref_code === 'sites') {

    //}


    console.log('Return Expansion Content - Services Info: ', services_info);
    return (
        <div className={classes.ExpansionPanelMainContainer} id={"expansion-panel-container-" + container_id} onClick={onChangeFunction}>
            <Container className={classes.ServicesContainer} id={'services-info-container-unselected-' + container_id} style={{
                borderTopColor: "rgb(219, 219, 219)",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
                borderRightColor: "rgb(219, 219, 219)",
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderBottomColor: "rgb(219, 219, 219)",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderLeftColor: "rgb(219, 219, 219)",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderImageSource: "initial",
                borderImageSlice: "initial",
                borderImageWidth: "initial",
                borderImageOutset: "initial",
                borderImageRepeat: "initial",
            }}>
                <React.Fragment>

                    <AppToolBarTitle
                        {
                            ...
                                {
                                    classes: classes,
                                    title: services_info.title,
                                    emoji: services_info.emoji
                                }
                        }
                    />

                    

                    <Paper className={classes.ServicesAppBarPaper} id={'ServicesAppBarPaper'}>
                        <Box my={2} style={{marginTop: '0', padding: '10px'}}>

                            {retrieved_component}
                        </Box>
                    </Paper>
                </React.Fragment>
            </Container>
        </div>

    );

}


/** Removed additional bar
<Toolbar 
style={{
    borderBottomRightRadius: '9px',
    borderBottomLeftRadius: '9px',
}}/>
 */

/** ############################################ Card ############################################ **/
class ExampleCardClass extends Component {

    constructor(props) {
        super(props);
        this.state = {checked: false};
        this.setChecked = () => {
            //console.log('Setting Checked: ', !this.state.checked);
            this.setState({checked:!this.state.checked})
        };
    }


    componentWillUnmount() { console.log('ExampleCardClass Unmounting!'); }

    render () {

        const {classes} = this.props;
        const {services_info} = this.props;
        const {bg_color} = this.props;

        /**
         const GetExpansionPanel = (services_info, classes) => {

            console.log('Get Expansion Panel services_info: ', services_info);
            console.log('Get Expansion Panel classes: ', classes);
            return (
                <Paper elevation={4} className={classes.OnClickContainer}>
                    <div className="home-body-container" id={"home-body-container-before"}>
                        <ServicesExpansionPanel {...{services_info: services_info, classes: classes}}/>
                    </div>
                </Paper>
            )
        };
         **/



        //console.log('Expansion Panel - Services Info: ', services_info);




        const changeSideColumnStyle = (index, checked) => {
            if (checked === true) {
                //console.log('Changing sideColumn style to true');
                //console.log('Index @ changeSideColumnStyle: ', index);
                //console.log('OnClickContainers: ', document.querySelectorAll('div[class^="Services-OnClickContainer"]')[0]);
                document.querySelectorAll('div[class^="Services-sideColumn"]')
                    [0].setAttribute('id', 'side-column-after');

                //document.getElementById('on-click-container-before').style.height = 'oops';
                //document.querySelectorAll('div[class^="Services-OnClickContainer"]')
                //    [0].setAttribute('id', 'on-click-container-after');



            } else if (checked === false) {
                //console.log('Changing sideColumn style to false');
                document.querySelectorAll('div[class^="Services-sideColumn"]')
                    [0].setAttribute('id', 'side-column-before');
                // document.querySelectorAll('div[class^="Services-OnClickContainer"]')
                //     [0].setAttribute('id', 'on-click-container-before');
            } else {
                //console.log('Error')
            }
        };



        /*
          if (card_el.hasChildNodes()) {
                            let children = card_el.childNodes;
                            for (let i = 0; i < children.length; i++) {
                                //alert(children[i].innerHTML);
                                let child = children[i];
                                alert('Child Length: '+ child.childNodes.length);
                                for (let r = 0; r < child.childNodes.length; r++) {
                                    let y = child.childNodes[r];
                                    alert(y.innerHTML);
                                }

                            }
                        }

         */
        const changeCardClassStyle = (index, checked) => {
            //alert('index: ' + index + ' checked: ' + checked);

            let card_elements = document.querySelectorAll('div[class^="Services-ExampleCardClassDiv"]');

            for (let i = 0; i <= card_elements.length - 1; i++) {
                let card_el = card_elements[i];
                //alert('Card El ID: ' + card_el.id + ' Index: ' + index);

                /** Identifies the card that corresponds with the index of the selected card **/
                /** triple equal sign does not work **/

                if (card_el.id == index) {
                    //alert('True!');


                    if (checked) {
                        card_el.style.position = 'fixed';
                        card_el.style.zIndex = '1';

                        let services_body_container = document.getElementById('services-info-container-unselected-' + index);
                        services_body_container.style.marginLeft =  '0';



                    } else {
                        card_el.style.position = 'revert';
                        card_el.style.zIndex = '0';
                    }

                } else { /* alert('False'); */ }


            }
        };


        const removeExampleCardClassPadding = (index) => {

        };


        const mobileExpandedViewAdjustments = () => {
            document.getElementById('on-click-container-before').setAttribute('id', 'on-click-container-after');
        };

        const reverseMobileExpandedViewAdjustments = () => {
            document.getElementById('on-click-container-after').setAttribute('id', 'on-click-container-before');
        };

        const handleChange = (r) => {
            //console.log('Card - Handle Change:', services_info.index);
            //let expPanel = GetExpansionPanel(services_info, classes);

            if (window.screen.availHeight <= 901) {
                if (!this.state.checked) {
                    mobileExpandedViewAdjustments();
                } else {
                    reverseMobileExpandedViewAdjustments();
                }


            } else {

            }

            //console.log('Setting Checked - !this.state.checked:', !this.state.checked);
            this.setChecked();
            //console.log('Setting Checked After - !this.state.checked:', !this.state.checked);

            changeSideColumnStyle(services_info.index, !this.state.checked);
            changeCardClassStyle(services_info.index, !this.state.checked);

            removeExampleCardClassPadding(services_info.index, !this.state.checked);

            this.props.onChange(services_info.index);
            // this.componentWillUnmount()
        };



        //console.log('ExampleCarClass - services info: ', services_info);
        //console.log('ExampleCarClass - Props: ', this.props);
        //console.log('ExampleCarClass - Index: ', services_info.index);

        //  STYLE INLINE IF STATEMENT WHATTT SO COOL!!

        const container_id = services_info.index;
        const retrievedExpansionPanel  = returnExpansionContent(services_info, container_id, classes, handleChange);

        return  (
            <div className={classes.ExampleCardClassDiv} id={services_info.index}>

                <Fade in={!this.state.checked} >

                    <Card
                        onClick={() => {
                            if (services_info.title === 'Websites') {
                                handleChange(services_info.index)
                            }                            
                        }}
                        className={classes.CardT}
                        style=
                            {
                                {
                                    position: !this.state.checked ? 'relative': 'absolute'
                                }
                            }
                    >
                        <CardActionArea className={classes.servicesCardActionArea} >
                            <CardContent className={classes.servicesCardContentDiv}>
                                <Typography className={classes.servicesTypographyComponent} component="div">

                                    <Box className={classes.servicesCardTitle}  m={1}>
                                        {services_info.title}
                                    </Box>

                                    <Box className={classes.servicesCardSummary} m={1}>
                                        {services_info.summary}
                                    </Box>

                                    {services_info.title === 'Websites' ?
                                        <Button className={classes.servicesLearnMoreButton} size="small" color="primary">
                                            Learn More
                                        </Button>
                                        :
                                        <Button className={classes.servicesLearnMoreButton}
                                                style={{
                                                    padding: '10px',
                                                }}
                                                    size="small" color="primary" disabled>
                                            Info Coming Soon
                                        </Button>
                                    }

                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Fade>

                <Slide direction={'up'} in={this.state.checked}>
                    <Paper elevation={4}
                           className={classes.OnClickContainer}
                           id={'expansion-slide-paper-' + services_info.index}
                           style=
                               {

                                   {
                                       position: !this.state.checked ? 'fixed' : 'relative',
                                       zIndex: !this.state.checked ? '2': '5',

                                   }
                               }
                    >
                        <div className="home-body-containert" /*id={"home-body-container-before"}*/>
                            <Paper
                                onChange={(handleChange)}
                                {...
                                    {
                                        services_info: services_info,
                                        classes: classes,
                                        isMain: services_info.isMain ? true : false,
                                    }
                                }
                            >

                                {retrievedExpansionPanel}


                            </Paper>
                        </div>
                    </Paper>
                </Slide>
            </div>
        )

    }

}

/** 3/25/2021 11;37AM

<div className={classes.ExpansionPanelMainContainer} id={"expansion-panel-container-" + container_id} onClick={handleChange}>
    <Container className={classes.ServicesContainer} id={'services-info-container-unselected-' + container_id} style={{
        borderTopColor: "rgb(219, 219, 219)",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderRightColor: "rgb(219, 219, 219)",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "rgb(219, 219, 219)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderLeftColor: "rgb(219, 219, 219)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderImageSource: "initial",
        borderImageSlice: "initial",
        borderImageWidth: "initial",
        borderImageOutset: "initial",
        borderImageRepeat: "initial",
    }}>
        <React.Fragment>

            <AppToolBarTitle
                {
                    ...
                        {
                            classes: classes,
                            title: services_info.title,
                            emoji: services_info.emoji
                        }
                }
            />

            <Toolbar  />

            <Paper id={'ServicesAppBarPaper'}>
                <Box my={2} style={{marginTop: '0', padding: '10px'}}>
                    {[...new Array(12)]
                        .map(
                            () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et. magna, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.
, vel scelerisque nisl consectetur et.`,
                        )
                        .join('\n')}
                </Box>
            </Paper>
        </React.Fragment>
    </Container>
</div>
**/



/**
 *  3/24/2021 - 2:13PM - Removing Services Expansion Panel, and imported component
 *
 <ServicesExpansionPanel

 onChange={handleChange}

 {...
                                    {
                                        services_info: services_info,
                                        classes: classes,
                                        isMain: services_info.isMain ? true : false,
                                    }
                                }

 />
 *
 *
 *
 */



/**
 * <FormControlLabel
 control={<Routes checked={this.state.checked} onChange={handleChange} />}
 label="Show"
 />
 */

//--------------------------------------------

/**
 * Returns fade popup
 * @param props
 * @returns {*}
 * @constructor
 */
//   props.onChange =


/** ############################################ Card Slider ############################################ **/
/**
 *
 *
 *
 * Function
 * recordChange:
 *
 *
 * return:
 *      class: class.sideColumn
 *
 * @param props
 * @returns {*}
 * @constructor
 */


const CardSlider = (props) => {
    //const classes = useStyles();
    const {classes} = props;
    const {services_info} = props;
    const [firstTime, setFirstTime] = useState(true);
    const [card, setCard] = useState(0);
    const [checked, setChecked] = React.useState(false);
    const [checkedTest, setCheckedTest] = React.useState(false);
    //const cards = new Array(2).fill(0);

    const visLeft = card === 0 ? "hidden" : "visible";
    const visRight = card === /**cards.length-4 ? "hidden" :**/ "visible";



    useEffect({} = () => {
        //console.log('Card Slider - services info: ', services_info );
        //console.log('Services Info Length:', services_info.length);
        {services_info.map((item,index) => {
            //console.log('Services Info: ', item, '  -  index: ', index);
        })}

    });



    const handleChange = () => {
        setChecked((prev) => !prev);
    };



    // Static and non reactive
    const recordChange = (data) => {
        console.log('Recording Change, Services Info: ', services_info);
        console.log('Recording Change Within Parent Function: ', data);

        // Now 'data' is expansionPanel Component
        if (Number.isInteger(data)) {




            if (checkedTest === 'false') {
                let original_data = {};
                original_data[data] = true;
                console.log('a) Setting checkedTest: ', original_data);
                setCheckedTest(original_data);

            } else if (typeof(checkedTest) === 'object') {

                let original_data = checkedTest;

                console.log('RecordChange - Checked Test: ', checkedTest);
                console.log('RecordChange - Checked Test `data`: ', data);

                if (original_data[data]) {
                    original_data[data] = !checkedTest[data];
                } else {
                    original_data[data] = true;
                }

                console.log('b) Setting checkedTest: ', original_data);
                setCheckedTest(original_data)


            }

        } else {
            console.log('BNo')
        }

        //let original_data = checkedTest;
        //console.log('RecordChange - Checked Test: ', checkedTest);


        //props.onChange(checkedTest);
        props.onChange(data); //sending up the expansion panel component


    };


    /**
     * Map services_info, item is each services_info entry
     *     also provide   -->classes<--  so that the styles can be rendered
     */


    if (firstTime) {
        //console.log('FIRST TIME');
        let x = {};
        services_info.map((item, index) => {x[index] = false});
        //console.log('First Time setChecked: ', x);
        setCheckedTest(x);
        setFirstTime((prev) => !prev);
    }

    if (!firstTime) {
        //console.log("Checked Test: ", checkedTest);
    }



    return (



        <div id={'side-column-before'} className={classes.sideColumn}>






            {checkedTest !== 'false' ? (

                <div className={classes.cardWrapper}  id={'on-click-container-before'}
                     style=
                         {
                            {
                                'transform': `translateX(-${card*(250/ services_info.length)}%`

                            }
                         }
                    /**                             onClick={recordChange} **/
                >



                    {services_info.map((item,index) => {
                        //console.log('Fucking Index: ', index);
                        //console.log("Checked Test Within: ", checkedTest);
                        return (
//                                    <div className={classes.container}>
                            <ExampleCardClass onChange={(r) => {
                               // console.log('Hell-fucking-lo');
                                //console.log('Index; ', index);
                                //console.log('Card Slide, onChange data: ', r);
                                recordChange(index)
                            }} {...{classes:classes, services_info: {...item, index: index}}}/>

                            //                                  </div>
                        )
                    })}


                </div>

            ) : null}







        </div>


    )
};

//                        <ExampleCard key={index}/>






/** ############################################ Services ############################################ **/
/** - Each service description and the initial declaration of the object containing said information
 *
 *
 * Renders:
 *      - home-body-container
 *      - classes.ServicesContainer -- id: services-info-container-unselected
 *      - AppToolBarTitle
 *      - ToolBar -- used for separation (iirc)
 *      - Paper -- class: classes.ServicesAppBarPaper
 *          - CardSlider:
 *              props:
 *                  - classes: classes
 *                  - services_info: services_info
 *              onChange:
 *                  - handleChange
 *
 *
 * Function
 * handleChange:
 *      - applied to CardSlider onClick
 *      - takes parameter r, which is the index of the service which was selected
 *
 *      console.log():
 *          - State of shouldFade prior to the selection
 *          - State being assigned to shouldFade
 *          - Expansion Panel index
 *
 *      setState:
 *          - shouldFade
 *          - expansionPanelOpen --> !this.state.expansionPanelOpen
 *          - expansionPanel -- the index (r)
 */


 const bodyText = () => {
    const txt = "Happel Solutions provides businesses, artists, and freelancers with technological                            solutions that fortify existing business practices and customer bases.                            Transitioning and adapting established, proven business practices into their                            respective digital counterparts is a practice that can be trying and difficult given                            a lack of understanding. Equipped with a large degree of varied experience,                            Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with it's clients; of which comes coupled with a respect for time-sensitive milestones.";
    const txtVideText = textVide(txt);
    return (
        <Typography>
            {txtVideText}
        </Typography>
    )
}


const bodyTextChatGPT= () => {
    const txt = "Happel Solutions provides businesses, artists, and freelancers with technological                            solutions that fortify existing business practices and customer bases.                            Transitioning and adapting established, proven business practices into their                            respective digital counterparts is a practice that can be trying and difficult given                            a lack of understanding. Equipped with a large degree of varied experience,                            Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with it's clients; of which comes coupled with a respect for time-sensitive milestones.";
    const txtVideText = textVide(txt);

    // Wrap the innerHTML with a <span> tag to ensure proper rendering and avoid unexpected issues.
    return (
        <Typography>
            <span dangerouslySetInnerHTML={{ __html: txt }} />
        </Typography>
    )
}



const textVideThisText = (txt) => {
    const txtVideText = textVide(txt);
    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: txtVideText }} />
        </>
    )

}


const NewServicesNoTextVide = ({ classes, directory }) => {
    const navigate = useNavigate();
    
  
    const handleMouseEnter = (event) => {
      const elementStyle = window.getComputedStyle(event.target);
      console.log(elementStyle.color); // Logs the current color of the element
      event.target.style.backgroundColor = 'black'; // Changes the color to black
    };
  
    const handleMouseLeave = (event) => {
      event.target.style.color = 'green'; // Reset the color when the cursor leaves the element
    };
  
    const getExpansionPanelContent = (panel_name) => {
      if (panel_name === 'Websites') {
        return <p>Hello</p>;
      } else if (panel_name === 'Software') {
        return <p>Hello Software</p>;
      } else if (panel_name === 'Backup') {
        return <p>Hello Backup</p>;
      } else if (panel_name === 'EPOS') {
        return <p>Hello EPOS</p>;
      } else {
        return <p>404: Expansion panel not found.</p>;
      }
    };
  
    const handleChange = (r) => {
      // Your handleChange logic
    };
  
    const viewportHeight = window.innerHeight;
  
    const getContent = () => {
            // for div --> className="home-body-container" id={"home-body-container-before"}
            return (
      
                <Box style={{paddingTop: "0!important", backgroundColor: "#f2f1f1", padding: "1rem 1rem"}}>
                    <Helmet>
                    <title>Services | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="Services | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="Services | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/services" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                    
                <Box >
                    
                    <Box >
                            <Typography variant={'h1'} sx={{ paddingTop: "1rem", fontStyle: "italic", fontWeight: "bold", fontSize: "2rem", padding: "2rem 1rem"}}>  Services </Typography>
                    </Box>          
                    
                    {viewportHeight <= 900 && ( <Divider style={{marginLeft: '1rem', marginRight: '1rem'}}/> )}

                </Box>



                <Divider style={{marginLeft: '1rem', marginRight: '1rem'}}/>
                
                            
                <Box style={{marginTop: '1rem', marginLeft: '1rem'}} >
                    <Typography className={classes.epH4} style={{fontWeight:'', marginBottom: '0.5rem', fontWeight: 'bold'}}> WEBSITES  </Typography>

                    <Typography style={{lineHeight: "1.3", marginBottom: '2rem'}}> Unleash your digital aspirations with our dynamic website development services. Whether it's creating comprehensive social media platforms, elegant web scheduling tools, captivating portfolios, or any other visionary concept you can imagine, our team is here to turn your dreams into finely-crafted websites, precisely tailored to your goals and aspirations. </Typography>
                 
                        <Link onClick={()=> {navigate("/services/websites ")}} component={Button} endIcon={<ReadMoreIcon/>} to="/services/websites" style={{height: "1.1rem", padding: "8px", marginBottom: "1rem", backgroundColor: 'green', textDecoration: 'none', color: 'white', borderRadius: '0', border: '.08333rem solid #000', boxShadow: '2px 2px #bbb'}}>
                             Learn More
                        </Link> 
                    
                    <Divider style={{ marginRight: '6rem'}}/>

                </Box>

                <Box style={{marginTop: '1rem', marginLeft: '1rem'}} >
                    <Typography className={classes.epH4} style={{fontWeight:'', marginBottom: '0.5rem', fontWeight: 'bold'}}> SOFTWARE  </Typography>

                    <Typography style={{lineHeight: "1.3", marginBottom: '2rem'}}> Experience the art of tailored software development, where we bring your ideas to life. Our versatile team excels at integrating existing systems, designing innovative hardware, and creating web-monitored, interactive IoT devices, all custom-built to meet your unique specifications and desires. </Typography>
                 
                        <Link onClick={()=> {navigate("/services/software ")}} component={Button} endIcon={<ReadMoreIcon/>} to="/services/websites" style={{height: "1.1rem", padding: "8px", marginBottom: "1rem", backgroundColor: '#ffb700', textDecoration: 'none', color: 'white', borderRadius: '0', border: '.08333rem solid #000', boxShadow: '2px 2px #bbb'}}>
                        Learn More
                        </Link> 
                    
                    <Divider style={{ marginRight: '6rem'}}/>

                </Box>

                <Box style={{marginTop: '1rem', marginLeft: '1rem'}} >
                    <Typography className={classes.epH4} style={{fontWeight:'', marginBottom: '0.5rem', fontWeight: 'bold'}}> IT Solutions </Typography>


                    <Typography style={{lineHeight: "1.3", marginBottom: '2rem'}}>  Whether you're an established enterprise pursuing upgrades, a fledgling venture in need of comprehensive IT infrastructure support, or a company seeking to elevate customer experiences through cutting-edge technology, we're here for you.  </Typography>
                 
                        <Link onClick={()=> {navigate("/services/tech")}} component={Button} to="/services/tech" endIcon={<ReadMoreIcon/>} style={{height: "1.1rem", padding: "8px", marginBottom: "1rem", backgroundColor: '#4d2a93', textDecoration: 'none', color: 'white', borderRadius: '0', border: '.08333rem solid #000', boxShadow: '2px 2px #bbb'}}>
                        Learn More
                        </Link> 
                    
                    <Divider style={{ marginRight: '6rem'}}/>

                </Box>

                <Box style={{marginTop: '1rem', marginLeft: '1rem'}} >
                    <Typography className={classes.epH4} style={{fontWeight:'', marginBottom: '0.5rem', fontWeight: 'bold'}}> CONSULTATION  </Typography>

                    <Typography style={{lineHeight: "1.3", marginBottom: '2rem'}}> Empowering your digital journey through expert consultation. We offer strategic guidance for expanding into the digital space, informed cyber security advice, and a host of other tailored solutions. As your trusted software and web development agency, our consultation services pave the way for informed decisions and effective implementations, ensuring your success in today's rapidly evolving technological landscape.  </Typography>
                 
                        <Link onClick={()=> {navigate("/services/consultation")}} component={Button} to="/services/consultation" endIcon={<ReadMoreIcon/>} style={{height: "1.1rem", padding: "8px", marginBottom: "1rem", backgroundColor: '#002c9b', textDecoration: 'none', color: 'white', borderRadius: '0', border: '.08333rem solid #000', boxShadow: '2px 2px #bbb'}}>
                        Learn More
                        </Link> 
                    
                    <Divider style={{ marginRight: '6rem'}}/>

                </Box>

                <Box style={{marginTop: '1rem', marginLeft: '1rem'}} >
                    <Typography className={classes.epH4} style={{fontWeight:'', marginBottom: '0.5rem', fontWeight: 'bold'}}> MARKETING & MEDIA  </Typography>

                    <Typography style={{lineHeight: "1.3", marginBottom: '2rem'}}>  Elevate your marketing efforts with our comprehensive solutions. We offer guidance to enhance visibility through SEO and expertly manage your online presence. Additionally, we provide creative services to craft engaging graphics, compelling content, and captivating promotional material that leaves a lasting impact on your audience. With our result-oriented approach, your brand will thrive and make strides in the ever-changing landscape of marketing.  </Typography>
                 
                        <Link disabled component={Button} to="/services/websites" style={{height: "1.1rem", padding: "8px", marginBottom: "1rem", backgroundColor: '#ff5700', textDecoration: 'none', color: 'white', borderRadius: '0', border: '.08333rem solid #000', boxShadow: '2px 2px #bbb'}}>
                             Info Coming Soon
                        </Link> 
                    
                    <Divider style={{ marginRight: '6rem'}}/>

                </Box>
                          
                        

                    
              


            </Box>

            )
        }

        const content = getContent();


        return (
            <DrawerTitleContainer
              id={'AboutContainer'}
              className={classes.IntroContainer}
              // Pass your props to the DrawerTitleContainer
              {...{
                classes: classes,
                directory: directory,
                details: { title: 'Services', emoji: '💬' },
                content: content,
                onchange: handleChange,
              }}
            />
          );
        };
        

export default withStyles(useStyles, { withTheme: false })(NewServicesNoTextVide);


//                    <Typography style={{lineHeight: "1.3", marginBottom: '2rem'}}> {textVideThisText("Custom IT solutions, tailored to your needs. From computer backups to redundant power supply installations and advanced security cameras, we've got you covered. Share your unique ideas with us, and we'll bring them to life, delivering innovative solutions that propel your business forward in the digital age.")} </Typography>

//                <div ref={this.myRef} id={'internalServicesScrollReferencePoint'}/>

//<Typography style={{fontFamily: "Arial", display: 'inline', color: 'grey'}}>•••••</Typography>

/**
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.24 32.26" style={{height: "0.6rem", paddingTop: "0.25rem", backgroundColor: "green", paddingBottom: "0.25rem", paddingRight: "0.25rem", }}>
<path fill="white" d="M29.1,18.43l-6.19.44H0V13.39H23.91l6.26.44L15.84,0h7.63L39.24,15.84v.58L23.47,32.26H15.84Z"></path>
</svg>

**/


// <div className="home-body-container" id={"home-body-container-before"}>
/**
 <Fade in={this.state.shouldFade}>
 <Container>
 <Fragment>
 {[this.state.expansionPanel].map((item) => (
                                {item}
                                )

                            )

                            }
 </Fragment>
 </Container>
 </Fade>


 **/

/**
 const handleExpansionPanelClick = () => {

            console.log('Expansion Panel Click');
            this.setState({shouldFade: !this.state.shouldFade,
                expansionPanelOpen: !this.state.expansionPanelOpen});

        };


 **/

        /**
        <Typography style={{fontSize:'0.875rem',  display: 'inline-block', color: 'white', padding: "0.05rem 0.5rem"}}>
                            Learn more 
                        </Typography>

                        **/