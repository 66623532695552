import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppToolBarTitle from "./AppToolBarTitle";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";



import useStyles from "./styles";
// Local Storage -- homeHeaderPreviouslyShown
class TitledContainer extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isMobile: (window.navigator.userAgent).includes('Mobile')
        };
    }

    componentDidMount() {}




    render () {

        const { classes } = this.props;
        const { details } = this.props;
        const { content } = this.props;
        const mobileMargin = "10rem";
        const desktopMargin = "20rem";

        //console.log('TitledContainer Props: ', this.props);

        return (
            <div className="home-body-container" 
                id={"home-body-container-before"}
            >

                <Container className={classes.ServicesContainer} id={'services-info-container-unselected'}>

                    <AppToolBarTitle className={classes.title_toolbar} {
                        ...
                            {
                                classes: classes,
                                title: details.title,
                                emoji: details.emoji,
                                isMain: true, // ??
                            }
                    } />

                    <Toolbar id={'separating-gutter'} /** Separating! **/ />

                    <Paper className={classes.ServicesAppBarPaper}>

                        {content}

                    </Paper>

                </Container>

            </div>

        );
    }



}

export default withStyles(useStyles, { withTheme: false })(TitledContainer);
