import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = theme => ({
     DescriptionContainer: {
        paddingLeft: '30px !important',
        paddingRight: '18px',
        marginTop: '3rem',
        background: '#ece2e3',
        background: '-webkit-linear-gradient(to right, #f5f5f5, #ffffff)',
        background: 'linear-gradient(to right, #f5f5f5, #ffffff)',
    },


    initial: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    description: {
        fontWeight: '100',
        fontSize: '2rem',
    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },

    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        wordSpacing: '.3rem'
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },

    SubsectionHeaderFirst: {
        fontWeight: 'bold',
        color: '#1d1d1d',
        paddingTop: '1rem',
    },

    SubsectionHeader: {
        fontWeight: 'bold',
        color: '#1d1d1d',
    },


    PopupGalleryContainer: {
        position: 'relative',
        display: 'flex',
        overflowX: 'scroll',
    },


    homepageBody: {

    },


    TestingImage: {
        height:'17rem',
        width: '21rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center center',
    },

    main_title: {
        backgroundColor: '#da8d8d !important',
    },

    secondary_title: {
        backgroundColor: "#fff !important",
    },

    IntroContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    FirstBodyParagraph: {
        marginBottom: '0 !important',
        padding: '10px',
        marginTop: '0',
    },


    ExpansionPanelMainContainer: {
        // height: '80vh',
        // width: '80vw',
        //top: 0,
        // left: 0,
        //position: 'fixed',
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    boxContent: {
        margin: '5px',
        //marginTop: '12px',
    },


    epDescription: {

    },

    epTitle: {

    },


    epH3: {
        marginTop: '2.9rem',

        marginBottom: '0.2rem',
        textTransform: 'uppercase',
        marginRight: '0.25rem',
        paddingLeft: '1rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '4.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.75rem',
        },
    },

    epH4: {
        marginLeft: '1rem',
        fontSize: '3.75vw',
        marginTop: '0.6rem',
        marginBottom: '0.5rem',
        display: 'inline-block',
    },

    epDescriptionIndent0: {
        marginTop: '3vh',
        //marginLeft: '1rem',
        marginBottom: '2rem',

        paddingRight: '5px',
        textAlign: 'justify',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epDescriptionIndent1: {
        marginTop: '2vh',
        marginLeft: '1rem',
        marginBottom: '2rem',

        paddingRight: '5px',
        textAlign: 'justify',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.75rem',
        },
    },

    epDescriptionIndent2: {
        marginLeft: '2rem',
        fontSize: '3.75vw',
        marginBottom: '1.5rem',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
        },
        [theme.breakpoints.up(901)]: {

        },
    },


    epListLeft: {
        float: 'left',
        marginRight: '20px',
        marginBottom: '2.5rem',
    },

    epListRight: {

    },

    sectionBackground: {
        ///background: '#e2e3ec',
        background: '#d3d3d36e',
        //margin: '-10px',
        //padding: '10px',
        marginTop: '0px',
        paddingBottom: '1px',
        borderRadius: '10px',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        //background: '#353535',
        //background: '-webkit-linear-gradient(top, #353535, #C9C9C9)',
        //background: '-moz-linear-gradient(top, #353535, #C9C9C9)',
        //background: 'linear-gradient(to bottom, #353535, #C9C9C9)',
    },
    sectionBackgroundWhite: {
        background: '#e2e3ec',
        //background: '#d3d3d36e',
        margin: '10px',
        //padding: '10px',
        //marginTop: '0px',
        //paddingBottom: '1px',
        //borderRadius: '10px',
        //borderTopLeftRadius: '0',
        //borderTopRightRadius: '0',
    },

    subsectionTitleAndBody: {
        background: '#f2f1f1',
        borderRadius: '2px',
        //borderRadius: '5px',
        boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
        padding: '3px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
    },


    whatCanWeDoForYouContainer: {

    },


    button: {
        margin: theme.spacing(0.5),
        fontSize: '0.7rem'
    },
});


export default useStyles