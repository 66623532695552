import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { Box, Typography, Divider, Paper, Stack, Button, TextField, Backdrop, CircularProgress, Fade, Select, MenuItem} from '@mui/material';

import { CheckCircleOutline, SmsFailed } from '@mui/icons-material';


import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer'
import useStyles from "./contactstyles";

const styles = theme => ({

    ExpansionPanelMainContainer: {
        // height: '80vh',
        // width: '80vw',
        //top: 0,
        // left: 0,
        //position: 'fixed',
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    boxContent: {
        margin: '10px',
    },


    epDescription: {

    },

    epTitle: {

    },


    epH3: {
        marginTop: '2.9rem',

        marginBottom: '0.2rem',
        textTransform: 'uppercase',
        marginRight: '0.25rem',
        paddingLeft: '1rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '4.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epH4: {
        marginLeft: '1rem',
        
        marginTop: '0.6rem',
        marginBottom: '0.5rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem'
        },
    },

    epDescriptionIndent1: {
        marginTop: '2vh',
        marginLeft: '1rem',
        marginBottom: '2rem',

        paddingRight: '5px',
        textAlign: 'justify',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epDescriptionIndent2: {
        marginLeft: '2rem',
        
        marginBottom: '1.5rem',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },


    epListLeft: {
        float: 'left',
        marginRight: '20px',
        marginBottom: '2.5rem',
        listStyle: 'circle',
    },

    epListRight: {
        listStyle: 'circle',
    },

    sectionBackground: {
        ///background: '#e2e3ec',
        background: '#d3d3d36e',
        //margin: '-10px',
        //padding: '10px',
        marginTop: '0px',
        paddingBottom: '1px',
        borderRadius: '10px',
    },


    subsectionTitleAndBody: {
        background: '#f2f1f1',
        borderRadius: '2px',
        //borderRadius: '5px',
        boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
        padding: '3px',
    },


    whatCanWeDoForYouContainer: {
        display: 'inline-block'
    },

});





const SimpleSimpleContact = ( { directory} ) => {

    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const methods = useForm();



    const [open, setOpen] = React.useState(false);
    const [showNavHomeMessage, setShowNavHomeMessage] = React.useState(false);

    const [requestStatus, setRequestStatus] = React.useState("unsent") // unsent, sent, success, failed


    const handleData = (data) => {
    
        console.log("On submit");
          //if (hasAccount === true) {
            //  if (emailBeenSet === false) {
          //        setEmail(data.email);
            //      setEmailBeenSet(true);
              //}
          //}
      }

      

    async function postData(url, data) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
      }

      
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };


    const onSubmit = async (data) => {
        console.log("On submit");
        console.log(data);
        handleOpen();
        setRequestStatus("sent");
        postData("https://api.web3forms.com/submit", data).then((r) => {
          console.log(r); // JSON data parsed by `data.json()` call
    
          let success_message = r.success;
          if (success_message == true) {
            console.log("Success");
            setTimeout(()=> {
              setRequestStatus("success");
              setTimeout( () => {
                console.log("Navigating home");
                setShowNavHomeMessage(true);
                navigate("/");
              }, 2000)
            }, 1000)
          } else {
            console.log("Failed");
            setTimeout(()=> {
              setRequestStatus("failed");
            }, 1000)
          }
    
        });
        handleData(data);
      }
    
    const container_details = {
        title: 'Contact',
        emoji: '💬',
    }

    const {handleSubmit, onKeyDown, register, watch, formState: { errors, dirtyFields }} = useForm({ mode: "onSubmit"});

    const getContent = () => {
        return (
            <Box id="cont" style={{height: "100vh"}}>
                <Helmet>
                    <title>Contact | Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="Contact | Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="Contact | Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/contact" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                
                    <Box id="inner-cont" style={{ /**height: "60vh", Reason for overflow of button on form via constraining the height**/ marginLeft: "auto", marginRight: "auto", marginTop: "5rem", maxWidth: "940px", backgroundColor: "#f2f1f1", boxShadow: "1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)", borderRadius: "2px"}}> 
                        
                        <Box id="form-cont">
                        
       

            <div>
                
                
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >

                    {requestStatus === "sent" ? <div> <CircularProgress color="inherit" /> </div> : null}
                    {requestStatus === "success" ? 
                    
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Fade in={requestStatus === "success"}  {...(requestStatus === "success" ? { timeout: 600 } : {})}>
                        <CheckCircleOutline color="inherit" style={{margin: "5px auto", height: "4rem", width: "4rem"}}/>
                        
                        </Fade>

                        {showNavHomeMessage ? 
                        <div> 
                            <Fade in={requestStatus === "success"}  {...(requestStatus === "success" ? { timeout: 600 } : {})}>
                        <Typography variant="caption" sx={{ color:  errors.pass ? "red" : "inherit", display: "block", fontSize: "1rem", fontFamily: theme.fontFamily}}> 
                            Successful! Please wait...
                        </Typography>
                        </Fade>
                        </div> 
                        : null}
                        
                    </div>

                    : null}

                    {requestStatus === "failed" ? <div> <SmsFailed color="inherit"/> </div> : null}
                    
                </Backdrop>

            </div>


            <Typography sx={{p:"2rem", mr:"1.1rem", fontSize: '2rem', fontStyle: "italic", textAlign:"center", fontWeight: 500, letterSpacing: "0.00rem",}} variant="h5"> Contact Us </Typography>


            <Box sx={{textAlign: 'center', fontFamily: '"Titillium Web",sans-serif !important'}}>
            
              </Box>
                
                <FormProvider style={{fontFamily: '"Titillium Web",sans-serif'}}>
                <form style={{  padding: "10px", display:"flex", flexDirection: 'column', fontFamily: '"Titillium Web",sans-serif'}}  onSubmit={handleSubmit((data) => onSubmit(data))} >

                    <Stack sx={{ position: 'relative', marginBottom: '0.5rem',  }} spacing={2} >
                    
                      <TextField style={{display: "none"}}{...register('access_key'
                              )}
                              inputProps={{  style: {   display: 'none' }, }}
                              name="access_key"
                              value="6d11c08a-48bc-43a6-a2eb-c381c4e615aa"
                              type="hidden"
                              className="input" 
                          />  
                        

                        <TextField {...register('name', {
                            required: "Your full name is required",   
                            validate: (value) => { 
                              
                                console.log("ARRRRRRRRRRRRRRRR");
                                if (value.length > 6 ) console.log("is 6")
                                return ( value.length > 6 || "Your full name is required")
                              
                            },    
                            })} 
                            inputProps={{    style: { height: '10px', width: "50%" }, }}
                            label="Name"
                            placeholder="Enter full name here"
                            name="name"
                            type="text"
                            autoComplete="off"
                            required
                            className="input" /> 

                        <Typography variant="caption" sx={{paddingLeft: '5px',  textAlign: "center", color: "red",  display:  errors.name ? "block" : "none"}}> Enter your full name. </Typography>

                        <TextField {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                            },
                            })}
                            inputProps={{  style: {   height: '10px' }, }}
                            label="Email"
                            name="email"
                            type="email"                        
                            required
                            defaultValue={""}
                            className="input" 
                        />  

                        <Typography variant="caption" sx={{paddingLeft: '5px',  textAlign: "center", color: "red",  display:  errors.email ? "block" : "none"}}> This email appears to be invalid. </Typography>
                        


                        <TextField {...register('subject', {
                            required: "Subject is required",   
                            validate: (value) => { 
                              
                                console.log("ARRRRRRRRRRRRRRRR");
                                if (value.length > 6 ) console.log("is 6")
                                return ( value.length > 6 || "Subject needs to be longer.")
                              
                            },    
                            })} 
                            inputProps={{    style: { height: '10px' }, }}
                            label="Subject"  
                            name="subject"
                            type="text"
                            autoComplete="off"
                            required
                            className="subject" /> 
                        
                        <Typography variant="caption" sx={{paddingLeft: '5px',  textAlign: "center", color: "red",  display:  errors.subject ? "block" : "none"}}> Subject needs to be longer. </Typography>

                        <Select {...register('service', {
                            required: "Service is required",   
                            validate: (value) => { 
                                if (value !== "Select A Service" ) console.log("Service Selected")
                                return ( value !== "Select A Service"|| "Subject needs to be longer.")
                              
                            },    
                            })} 
                            inputProps={{    style: { height: '10px' }, }}
                            label="Service"  
                            name="service"
                            autoComplete="off"
                           
                            required
                            className="service">
                                <MenuItem value={"Website and Web App Development"}>Website and Web App Development</MenuItem>
                                <MenuItem value={"Software Development"}>Software Development</MenuItem>
                                <MenuItem value={"IT and Tech Solutions"}>IT and Tech Solutions</MenuItem>
                                <MenuItem value={"Consultation"}>Consultation</MenuItem>
                        
                        </Select> 
                        
                        <Typography variant="caption" sx={{paddingLeft: '5px',  textAlign: "center", color: "red",  display:  errors.service ? "block" : "none"}}> You must select a service option. </Typography>
                    
                    

                        <TextField {...register('details', {
                          required: "Details are required", 
                          validate: (value) => { 
                              
                            console.log("More details needed");
                            if (value.length > 40 ) console.log("is 6")
                            return ( value.length > 40 || "Please enter a more details.")
                          
                        },
                            //validate: (value) => {},
                          })} 
                            inputProps={{    style: { /*height: '10px'*/ }, }}
                            label="Details"
                            name="details"
                            placeholder="Enter details here"
                            type="textarea"
                            multiline
                            rows={4}
                            autoComplete="off"
                            required
                            className="input12"
                            
                             /> 
                    

                
                        <Typography variant="caption" sx={{ textAlign: "center", color: "red",  display:  errors.details ? "block" : "none"}}> Please enter a more details.  </Typography>
                   

                        
                    </Stack>

                    <Button style={{height: '3vh', paddingLeft:'17px', width: "8vh", boxShadow: "rgb(187, 187, 187) 2px 2px", border: "0.08333rem solid rgb(0, 0, 0)"}}type="submit" variant="contained" color="primary">Submit</Button>

                </form>

                </FormProvider> 
          

                        </Box>

                    </Box>  
               

            </Box> 
        )


    }

    const handleChange = () => {

    }

    const content = getContent();
  
    return (
        <> 
        <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}
    
            style={{ background: '#353535', }}
            {... {
                        classes: classes,
                        directory: directory,
                        details: container_details,
                        content: content,
                        onchange: {handleChange}
            }}
            /> 
        </>
      )
}

export default withStyles(styles, { withTheme: false }) (SimpleSimpleContact);