import React, {Fragment, useEffect, useState} from "react";
import ReactDOM from "react-dom";

import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { red, blue, green } from "@material-ui/core/colors";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import BackdropImageButton from "./BackdropImageButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ImagesFromFlickr from "./ImagesFromFlickr";
import Backdrop from "@material-ui/core/Backdrop";
//import SlidesForCarousel from "./SlidesForCarousel";

import SlidesAndImagesForCarousel from "./SlidesForCarousel";

const styles = {

    MainZone: {
        //backgroundColor: '#F9F9F9',
        //backgroundImage: 'linear-gradient(to top, #e6b980 0%, #eacda3 50%)',
        //backgroundColor: 'rgba(255,255,255,.8)',
        //backgroundColor: '#f8f8ff',
        backgroundColor: '#fff',
        borderRadius: '4px',
        //flexGrow: 2,  // Causing over-extension of background color on mobile version
        width: '90%',

    },
    refinedGallery: {
        padding: '20px',
        //display: 'grid',
        //display: 'none',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '15px',
        boxShadow: '1px 2px 0px #aaaaaa',
        //rowGap: '30px',
        //columnGap: '30px',
    },
    ImageComponent: {
        cursor:'pointer',
        opacity: '1',
        boxShadow: '2px 2px 3px #aaaaaa;',
    },
    GalleryImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    ModalGateway: {
    },

};






const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, imagesForCarousel, props}) => {

    //console.log('Auto Rotating, inheriting props: ', props);

    //console.log('Images Inherit: ', imagesForCarousel);

    const retrieved_images = imagesForCarousel; //ImagesForCarousel(props.flickr_album_name);
    //console.log('Retrieved Images: ', retrieved_images);

    return (
        <Fragment>
            {retrieved_images ? (
                <div>
                    {/* <Button onClick={() => setHandleOpen({ open: true })}>Open carousel</Button> */}


                </div>



            ): <p> BH </p>}
        </Fragment>

    );
};

// Local Storage -- homeHeaderPreviouslyShown
function AutoRotatingCarouselPopup(props) {



    useEffect({} = () => {
        //console.log('Auto Rotating Props: ', props)
    });

    const showcase_details = props.showcase_details;


    return (

        <Fragment>

            {props.showcase_details ? (

                <Fragment>

                    {showcase_details.map((item, index) => {

                        return (

                            <Fragment>

                                <SlidesAndImagesForCarousel
                                    {...
                                        {album_name: item.flickr_album_name,
                                            cover_image_url: item.cover_image_url,
                                            index: index}
                                    }
                                />

                            </Fragment>

                        )
                    })}

                </Fragment>

            ): null}

        </Fragment>

    );

}



export default withStyles(styles, { withTheme: false })(AutoRotatingCarouselPopup);


/**

 <AutoRotatingCarouselModal
 //isMobile={matches}
 handleOpen={carouselOpen}
 setHandleOpen={setHandleOpen}
 props={item}
 />


function AutoRotatingCarouselPopup(props) {

    const [carouselOpen, setHandleOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false );

    const handleClick = (s) => {
        setHandleOpen({ open: true });
    };

    const matches = useMediaQuery("(max-width:600px)");

    useEffect({} = () => {
        console.log('Auto Rotating Props: ', props)
    });

    console.log('Auto Rotating Props: ', props);
    const showcase_details = props.showcase_details;




    return (

        <Fragment>

            {props.showcase_details ? (

                <Fragment>

                    {showcase_details.map((item, index) => {

                        return (

                            <Fragment>
                                <BackdropImageButton
                                    {...
                                        {
                                            image_file: item.cover_image_url,
                                            index: 0
                                        }
                                    }
                                    onClick={handleClick}  // onClick ={ () => {} }
                                />

                                <AutoRotatingCarouselModal
                                    //isMobile={matches}
                                    handleOpen={carouselOpen}
                                    setHandleOpen={setHandleOpen}
                                    props={item}
                                />
                            </Fragment>

                        )
                    })}

                </Fragment>

            ): null}

        </Fragment>

    );

}

**/


/**
 <Slide
 media={
                        <img src="https://i.pinimg.com/736x/06/d1/5f/06d15fc8a69e2ab67143e5357a4184d7.jpg" />
                    }
 mediaBackgroundStyle={{ backgroundColor: red[400] }}
 style={{ backgroundColor: red[600] }}
 title="Max McGill Productions"
 subtitle="Just using this will blow your mind."
 />
 <Slide
 media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png" />
                    }
 mediaBackgroundStyle={{ backgroundColor: blue[400] }}
 style={{ backgroundColor: blue[600] }}
 title="Ever wanted to be popular?"
 subtitle="Well just mix two colors and your are good to go!"
 />
 <Slide
 media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png" />
                    }
 mediaBackgroundStyle={{ backgroundColor: green[400] }}
 style={{ backgroundColor: green[600] }}
 title="May the force be with you"
 subtitle="The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe."
 />
 **/

