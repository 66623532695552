import React, {Fragment, useEffect, useState} from "react";
import ReactDOM from "react-dom";

import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { red, blue, green } from "@material-ui/core/colors";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import BackdropImageButton from "./BackdropImageButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {Alert} from "@material-ui/lab";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Snackbar from "@material-ui/core/Snackbar";
const styles = {

    WebsiteTitleDIV: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        lineHeight: '.8rem',
        paddingRight: '2rem',
    },

    WebsiteTitleText: {
        fontSize: '.9rem',
    },

    WebsiteTitleURL: {
        fontSize: '.7rem',
        paddingLeft: '0.25rem',
    },

    MobileShowcasePaperMaster: {
        marginBottom: '-0.4rem',
        //paddingBottom: '1rem',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',

        overflowX: 'hidden',
        width: '100%',
        borderLeft: 'double',
        border: 'outset',
        borderLeftWidth: 'thick',
        borderLeftColor: '#feffff',
        borderTopWidth: 'initial',
        borderBottomWidth: 'medium',
    },


    ToggleBackdropButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderLeftWidth: 'medium',
        borderTop: 'inset',
        borderTopWidth: 'thin',
        width: '100%',
        color: '#2b2b2b',
        backgroundImage: "url('../../blue-button.jpg')",
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 800,
        marginBottom: '2rem',
        marginTop: '0.05rem',
        border: 'outset',
        fontSize: '.7rem',
        //borderLeftWidth: 'thick',
    },


};


//{/* <Button onClick={() => setHandleOpen({ open: true })}>Open carousel</Button> */}

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile, props }) => {

    console.log('AutoRotatingCarouselModal, item: ', props);
    const slides = props.info_slides;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const hanc = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({...{open:false}});
    };

    return (


        <Paper id={'KScopePattern'}>

            <AutoRotatingCarousel
                label={'Visit Site'}
                ButtonProps={{onClick: () => {
                    //alert('clicked');
                    setHandleOpen({ open: false });
                        setSnackbarOpen(true);
                }} }
                open={handleOpen.open}
                onClose={() => setHandleOpen({ open: false })}
                onStart={() => setHandleOpen({ open: false })}
                autoplay={true}
                interval={50000}
                mobile={isMobile}
                style={{ position: "absolute", height: '350', width: '450px' }}
                className={'AutoRotating'}
            >


                {slides.map((slide, index) => (

                    <React.Fragment>

                        <div style={{
                            borderRadius: '10px',
                        }}>

                            <CloseOutlinedIcon style={{
                                position: 'absolute',
                                color: 'white',
                                backgroundColor: '#bdbdbd78',
                                fontSize: 'medium',
                                borderRadius: '10px',
                                margin: '10px 0px 0px 10px',
                                padding: '10px',
                                boxShadow: 'rgb(0 0 0 / 20%) 1px -1px 20px -2px, rgb(0 0 0 / 19%) 0px 2px 8px 0px',

                            }}
                            onClick={
                                () => {
                                    setHandleOpen({ open: false })
                                }
                            }
                            />

                        </div>

                        <Slide
                            media={<img src={slide.slide_image_url}/>}
                            mediaBackgroundStyle={
                                {
                                    //backgroundColor: '#FFF9F8',
                                    background: 'rgb(238,238,238)',
                                    background: 'linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,249,248,1) 41%, rgba(238,238,238,1) 68%)',


                                    borderBottom: 'inset',
                                }
                            }
                            style={
                                {
                                    backgroundColor: 'rgb(238,238,238)',
                                    background: 'linear-gradient(186deg, rgba(238,238,238,1) 0%, rgba(210,218,228,1) 59%, rgba(167,185,208,1) 100%)',
                                }
                            }
                            title={slide.title}
                            subtitle={slide.subtitle}
                            index={index}
                        />

                    </React.Fragment>


                ))}

            </AutoRotatingCarousel>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={()=> {setSnackbarOpen(false)}}>
                        <Alert onClose={()=> {setSnackbarOpen(false)}} severity="success">
                            Navigate to <ArrowRightIcon style={{margin: '-7px'}}/>
                                    <Link href="/maxmcgill">
                                        Max Mcgill Production
                                    </Link>
                        </Alert>
            </Snackbar>
        </Paper>


    );
};

// Local Storage -- homeHeaderPreviouslyShown
function MobileAutoCarousel(props) {

    const [carouselOpen, setHandleOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false );
    const handleClick = (s) => {
        setHandleOpen({ open: true });
        console.log("\n\n\n\n\n\n\n\n\n\n Handle CLICKKKKKKKKKKKKKKKKKKKK, props: ", props);
    };
    const matches = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        // Update the document title using the browser API
        //console.log('Mobile Auto Carousel Index: ', props);
    });
    const {classes} = props;
    return (


        <Fragment>

            <div className={classes.WebsiteTitleDIV}>
                < p style={{ marginBottom: '.9rem',  /*textAlign: 'center'*/} }>
                    <b className={classes.WebsiteTitleText}>⭐ Max McGill Production Co.</b>
                    <br/>
                    <Link href="https://www.maxmcgillproduction.com" className={classes.WebsiteTitleURL}>https://www.maxmcgillproduction.com</Link>
                </p>
            </div>

            {props.showcase_details.map((item, index) => (

                <Paper elevation={6} className={classes.MobileShowcasePaperMaster} id={'LightGreenBackgroundTexture'} >

                    <BackdropImageButton {...{image_file: item.cover_image_url, index: index}}
                                         onClick={handleClick}  // onClick ={ () => {} }
                        index={index}
                    />
                    <AutoRotatingCarouselModal
                        isMobile={matches}
                        handleOpen={carouselOpen}
                        setHandleOpen={setHandleOpen}
                        props={item}
                    />
                </Paper>
            ))}

            <Button
                className={classes.ToggleBackdropButton}
                //id="ToggleBackdropButton"  
                variant="contained"
                color="primary"
                onClick={ () => {
                    setHandleOpen({ open: true });
                }}
            >
                View Highlights
            </Button>

        </Fragment>
    );
}




export default withStyles(styles, { withTheme: false })(MobileAutoCarousel);

//            <Button onClick={handleClick}>Open carousel</Button>
/**

 <Slide
 media={
                        <img src="https://i.pinimg.com/736x/06/d1/5f/06d15fc8a69e2ab67143e5357a4184d7.jpg" />
                    }
 mediaBackgroundStyle={{ backgroundColor: red[400] }}
 style={{ backgroundColor: red[600] }}
 title="Max McGill Productions"
 subtitle="Just using this will blow your mind."
 />
 <Slide
 media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png" />
                    }
 mediaBackgroundStyle={{ backgroundColor: blue[400] }}
 style={{ backgroundColor: blue[600] }}
 title="Ever wanted to be popular?"
 subtitle="Well just mix two colors and your are good to go!"
 />
 <Slide
 media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png" />
                    }
 mediaBackgroundStyle={{ backgroundColor: green[400] }}
 style={{ backgroundColor: green[600] }}
 title="May the force be with you"
 subtitle="The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe."
 />

 //className={classes.WebsiteTitleDIV}

 **/