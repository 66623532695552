import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import React from "react";


const styles = {
    BoldASF: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    LightASF: {
        fontWeight: '100',
        fontSize: '2rem',
    },
};

const TestButton = ({ classes, specifiedClassName }) => {
    console.log('Test Button - classes: ', classes);
    console.log('Test Button - specified class name: ', specifiedClassName);

    return(
        <Button className={classes[specifiedClassName]} variant={"contained"}> Ballsack MacGee</Button>
    )
};


export default withStyles(styles)(TestButton)