import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = theme => ({
    backdrop: {


        color: '#fff',
        height: '100%',
        width: '100%',
        paddingTop: '40%',
        paddingBottom: '40%',
        overflowY: 'scroll',

        [theme.breakpoints.down(900)]: {
            maxHeight: '600px',
        },
        [theme.breakpoints.up(901)]: {
            maxHeight: '1200px',
        },


    },
    ImageGallery: {

        // overflowX: 'scroll'
    },
    BackdropContainer: {

        [theme.breakpoints.down(900)]: {
            marginTop: '40%',
            paddingRight: '16px',
            paddingTop: '45%',
            paddingBottom: '30%',
            marginBottom: '30%',
        },
        [theme.breakpoints.up(901)]: {
            marginTop: '40%',
            paddingRight: '16px',
            paddingTop: '45%',
            paddingBottom: '30%',
            marginBottom: '30%',
        },
    },

    ButtonAndBackdropContainer: {

    },


    IntroContainer: {
        paddingLeft: '30px',
        paddingRight: '18px',
        //background: '#e2e8ec', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #ece6e2, #e2e8ec )',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #ece6e2, #e2e8ec )' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        background: '#ece2e3', //fallback for old browsers
        background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    DescriptionContainer: {
        paddingLeft: '30px !important',
        paddingRight: '18px',
        marginTop: '3rem',
        background: '#ece2e3',
        background: '-webkit-linear-gradient(to right, #f5f5f5, #ffffff)',
        background: 'linear-gradient(to right, #f5f5f5, #ffffff)',
    },


    ServicesContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        borderTopColor: "rgb(219, 219, 219)",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderRightColor: "rgb(219, 219, 219)",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "rgb(219, 219, 219)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderLeftColor: "rgb(219, 219, 219)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderImageSource: "initial",
        borderImageSlice: "initial",
        borderImageWidth: "initial",
        borderImageOutset: "initial",
        borderImageRepeat: "initial",

    },
    initial: {
        fontWeight: '600',
        fontSize: '10rem',

    },

    description: {
        fontWeight: '100',
        fontSize: '2rem',
    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },

    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },

    SubsectionHeaderFirst: {
        fontWeight: 'bold',
        color: '#1d1d1d',
        paddingTop: '1rem',
    },

    SubsectionHeader: {
        fontWeight: 'bold',
        color: '#1d1d1d',
    },


    PopupGalleryContainer: {
        position: 'relative',
        display: 'flex',
        overflowX: 'scroll',
    },




    homepageBody: {
        minHeight: '22.5rem',
        //display: 'inline-block',

    },

    ServicesAppBarPaper: {
        padding: '5px',
    },

    TitleSpacer: {

    },







    sideColumn: {
        height: '264px',
        width: '100%', // we want it to fill the entire container
        position: 'relative',
        //display: 'flex',
        //overflow: 'hidden',
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderTop: 'outset',

        display: 'inline-block', // for testing

        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            width: '8.8vw',
            height: '120%',
            //background: 'white',
            top: '-10%',
            left: 0,
            zIndex: 3,
        },
        '&:after': {
            left: 'auto',
            width: '9.2vw',
            right: 0,
        }
    },

    cardSlider: {
        position: 'relative',
        height: '264px',
        //maxWidth: '82vw',
        // margin: '0 auto',
    },
    cardWrapper: {

        //height: '0vh !important',

        display: 'inline-flex',
        flexFlow: 'row noWrap',
        justifyContent: 'space-evenly',
        left: '0',
        //fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',

        transition: 'transform 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955)',
    },
    button: {
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        display: 'block',
        position: 'absolute',
        border: 'none',
        borderRadius: '50%',
        height: '4vw',
        width: '4vw',
        zIndex: '4',
        top: '25%',
        backgroundColor: '#dbdee0',
        color: 'rgb(121, 111, 111)',
        '&:focus': {
            outline: 'none'
        },
        '&:active': {
            transform: 'translateY(1px)',
            filter: 'saturate(150%)'
        },
        '&:hover': {
            borderColor: '#f3f3f3',
            backgroundColor: '#f3f3f3',
            cursor: 'pointer',
        }
    },
    buttonRight: {
        // desktop: right 0 , margin right 2 rem

        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        display: 'block',
        position: 'absolute',
        border: 'none',
        borderRadius: '50%',
        height: '4vw',
        width: '4vw',
        zIndex: '4',

        backgroundColor: '#dbdee0',
        color: 'rgb(121, 111, 111)',
        '&:focus': {
            outline: 'none'
        },
        '&:active': {
            transform: 'translateY(1px)',
            filter: 'saturate(150%)'
        },
        '&:hover': {
            borderColor: '#f3f3f3',
            backgroundColor: '#f3f3f3',
            cursor: 'pointer',
        },
        boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0,     0, 0.19)',

        [theme.breakpoints.down(900)]: {
            right: '0.5rem',
            bottom: '0.5rem',
        },

        [theme.breakpoints.up(901)]: {
            top: '25%',
            right: '0',
            marginRight: '2rem'
        },
    },
    buttonLeft: {
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        display: 'block',
        position: 'absolute',
        border: 'none',
        borderRadius: '50%',
        height: '4vw',
        width: '4vw',
        zIndex: '4',
        top: '25%',
        backgroundColor: '#dbdee0',
        color: 'rgb(121, 111, 111)',
        '&:focus': {
            outline: 'none'
        },
        '&:active': {
            transform: 'translateY(1px)',
            filter: 'saturate(150%)'
        },
        '&:hover': {
            borderColor: '#f3f3f3',
            backgroundColor: '#f3f3f3',
            cursor: 'pointer',
        },
        boxShadow: '-1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0,       0, 0.19)',
        left: '-3%',
        [theme.breakpoints.down(900)]: {
            left: '0',
            marginLeft: '2rem',
        },

        [theme.breakpoints.up(901)]: {
            left: '0',
            marginLeft: '2rem',
        },


    },
    noTextOverflow: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    // Card

    servicesCardActionArea: {
        height: '100%',
    },

    ExampleCardClassDiv: {
        position: 'relative',
        // display: 'flex',
    },

    servicesTypographyComponent: {

    },

    servicesCardContentDiv: {
        padding: '0px !important',
        position: 'absolute',
        top: '0',
        width: '100%',
    },

    servicesCardTitle: {
        background: '#ebebeb',
        margin: 0,
        padding: '11px 10px 5px',
        //width: '100%',
        height: '100%',
        textAlign: 'center',
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        borderBottomStyle: 'groove',
        borderBottomWidth: '1px',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
    },

    servicesCardSummary: {
        textAlign: "left",
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    },

    servicesLearnMoreButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        position: 'inherit',
        //marginTop: '33%', // could use some adjustment
    },

    main_title: {
        backgroundColor: '#da8d8d !important',
    },

    secondary_title: {
        backgroundColor: "#fff !important",
    },


    title_toolbar: {

    },

});


export default useStyles