import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ImagesFromFlickr from "./ImagesFromFlickr";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function BackdropExample() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        let refinedGalleryDIV = 'refined-gallery'+3;
        document.getElementById(refinedGalleryDIV).style.display = 'none';
        setOpen(false);
    };
    const handleToggle = () => {
        let refinedGalleryDIV = 'refined-gallery'+ 3;
        document.getElementById(refinedGalleryDIV).style.display = 'grid';
        setOpen(!open);

    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleToggle}>
                Show backdrop
            </Button>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <ImagesFromFlickr {...{album_name: 'Instruments_Prod', index: 3}}
                />
            </Backdrop>
        </div>
    );
}
