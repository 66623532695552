import React, {Component, Fragment, useEffect, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Routes from "react-router-dom";
import Fade from "@material-ui/core/Fade";



//import AppToolBarTitle from "../features/AppToolBarTitle";
import Backdrop from '@material-ui/core/Backdrop';
//import ServicesExpansionPanel from "../features/ServicesExpansionPanel";
import Grow from "@material-ui/core/Grow";
import CustomCard from "./CustomCard";
import TitledContainer from '../../../BuildingComponents/TitledContainer/TitledContainer';
//import TitledContainer from '../../BuildingComponents/TitledContainer/TitledContainer';
import useStyles from './styles';



// ----------- Hide App Bar ----------- //
function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


function HideAppBar(props) {
    return (
        <React.Fragment>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6">Scroll to Hide App Bar</Typography>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
            <Container id={'HideAppBar'}>
                <Box my={2}>
                    {[...new Array(12)]
                        .map(
                            () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                        )
                        .join('\n')}
                </Box>
            </Container>
        </React.Fragment>
    );
}



/** ############################################ Card Slider ############################################ **/
const CardSlider = (props) => {
    //const classes = useStyles();
    const {classes} = props;
    const {services_info} = props;
    const [firstTime, setFirstTime] = useState(true);
    const [card, setCard] = useState(0);
    const [checked, setChecked] = React.useState(false);
    const [checkedTest, setCheckedTest] = React.useState(false);
    //const cards = new Array(2).fill(0);

    const visLeft = card === 0 ? "hidden" : "visible";
    const visRight = card === /**cards.length-4 ? "hidden" :**/ "visible";



    useEffect({} = () => {
        //console.log('Card Slider - services info: ', services_info );
        //console.log('Services Info Length:', services_info.length);
        {services_info.map((item,index) => {
            //console.log('Services Info: ', item, '  -  index: ', index);
        })}

    });



    const handleChange = () => {
        setChecked((prev) => !prev);
    };



    // Static and non reactive
    const recordChange = (data) => {
        console.log('Recording Change, Services Info: ', services_info);
        console.log('Recording Change Within Parent Function: ', data);

        // Now 'data' is expansionPanel Component
        if (Number.isInteger(data)) {

            if (checkedTest === 'false') {
                let original_data = {};
                original_data[data] = true;
                console.log('a) Setting checkedTest: ', original_data);
                setCheckedTest(original_data);

            }

            else if (typeof(checkedTest) === 'object') {

                let original_data = checkedTest;

                console.log('RecordChange - Checked Test: ', checkedTest);
                console.log('RecordChange - Checked Test `data`: ', data);

                if (original_data[data]) {
                    original_data[data] = !checkedTest[data];
                } else {
                    original_data[data] = true;
                }

                console.log('b) Setting checkedTest: ', original_data);
                setCheckedTest(original_data)


            }

        } else {
            console.log('BNo')
        }

        props.onChange(data); //sending up the expansion panel component


    };


    /**
     * Map services_info, item is each services_info entry
     *     also provide   -->classes<--  so that the styles can be rendered
     */


    if (firstTime) {
        console.log('FIRST TIME');
        let x = {};
        services_info.map((item, index) => {x[index] = false});
        console.log('First Time setChecked: ', x);
        setCheckedTest(x);
        setFirstTime((prev) => !prev);
    }

    if (!firstTime) {
        //console.log("Checked Test: ", checkedTest);
    }

    return (



        <div id={'side-column-before'} className={classes.sideColumn}>



            <div className={classes.cardSlider}>

                <button
                    onClick={() => setCard(card+1)}
                    className={classes.buttonRight}
                    style={{visibility: visRight}}
                >
                    {'>'}
                </button>

                <button
                    onClick={() => setCard(card-1)}
                    className={classes.buttonLeft}
                    style={{visibility: visLeft}}
                >
                    {'<'}
                </button>



                {checkedTest !== 'false' ? (

                    <div className={classes.cardWrapper}  id={'on-click-container-before'}
                         style={{'transform': `translateX(-${card*(150/ services_info.length)}%`}}
                        /**                             onClick={recordChange} **/
                    >



                        {services_info.map((item,index) => {
                            //console.log('Fucking Index: ', index);
                            //console.log("Checked Test Within: ", checkedTest);
                            return (
//                                    <div className={classes.container}>
                                <CustomCard onChange={(r) => {
                                   // console.log('Hell-fucking-lo');
                                    //console.log('Index; ', index);
                                    console.log('Card Slide, onChange data: ', r);
                                    recordChange(index)
                                }} {...{classes:classes, services_info: {...item, index: index}}}/>

                                //                                  </div>
                            )
                        })}


                    </div>

                ) : null}
                <p>;</p>



            </div>

        </div>


    )
};






/** ############################################ Services ############################################ **/
class ServicesOffered extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expansionPanelOpen: false,
            numberOfTimesHandleChangeTriggered: 0,
            shouldFade: false,
            expansionPanel: (<p>Hello</p>),
        };
        this.myRef = React.createRef()
    }

    componentWillUnmount() {

    };

    componentDidMount() {
        console.log('Services Mounted');
        [this.state.expansionPanel].map((item) => (
                console.log(item)
            )
        )
    }

    render () {

        const { classes } = this.props;

        const services_info = [
            {
                title: 'Website Design',
                summary: 'Design and build your website',
                expandedView: '',
                bg_color: 'da8d8d',
                isMain: false,
            },

            {
                title: 'Software',
                summary: 'Design and build software solutions',
                expandedView: '',
                bg_color: 'da8d8d',
                isMain: false,
            },
        ];

        const container_details = {
            title: 'Services',
            emoji: '🛠️',
        };

        const handleChange = (r) => {
            // Set expansionPanel state, so if fading out main component, replace with expansionPanel
            //alert('Handle Change: ' + r);
            this.setState({
                shouldFade: !this.state.shouldFade,
                expansionPanelOpen: !this.state.expansionPanelOpen,
                expansionPanel: r
            });

            /**
             let updatedNumberOfTimes = 1 + this.state.numberOfTimesHandleChangeTriggered;
             if (updatedNumberOfTimes === 2) {
                console.log('Updated Number of Times: ', updatedNumberOfTimes);
                console.log('Should fade !');
                this.setState({numberOfTimesHandleChangeTriggered: 0})
            } else {
                console.log('Updated Number of Times: ', updatedNumberOfTimes);
                console.log('Number of Times: ', this.state.numberOfTimesHandleChangeTriggered);
                console.log('Should not fade');
                this.setState({numberOfTimesHandleChangeTriggered: updatedNumberOfTimes});

            }
             //this.setChecked();
             //this.props.onChange(services_info.index);
             **/
        };

        const getCardSlider = () => {
            return (
                <CardSlider
                    {...{classes: classes, services_info: services_info}}
                    onChange={handleChange}/>
            )
        };

        const content = getCardSlider();

        return (

            <TitledContainer
                {
                    ...
                        {
                            classes: classes,
                            details: container_details,
                            content: content,
                            onchange: {handleChange}
                        }
                }
            />

        );
    }




}
export default withStyles(useStyles, { withTheme: false })(ServicesOffered);
