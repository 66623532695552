import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = theme => ({
    ServicesContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        borderTopColor: "rgb(219, 219, 219)",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderRightColor: "rgb(219, 219, 219)",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "rgb(219, 219, 219)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderLeftColor: "rgb(219, 219, 219)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderImageSource: "initial",
        borderImageSlice: "initial",
        borderImageWidth: "initial",
        borderImageOutset: "initial",
        borderImageRepeat: "initial",
    },

    title_toolbar: {

    },

    ServicesAppBarPaper: {
        backgroundColor: 'transparent',
    },

    

});


export default useStyles