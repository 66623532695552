import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'

import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';  
import { Typography, Box, Divider} from '@mui/material';
import { OfficeRoomMin } from '../../Assets/photos';



const Consultation = ( {classes, directory}) => {

    const container_details = {
        title: 'Consultation',
        emoji: '💬',
    };
    
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    useEffect(() => {
        // Update the document title using the browser API
        console.log("Classes: " + JSON.stringify(classes));

      });
    
    
    const getContent = () => {
        
        return (
            <Box my={2} style={{ 
                marginTop: '0', padding: '10px',
                ...(viewportWidth < 600 && { padding: '0px', }), // Apply styles for mobile
            }}>
                <Helmet>
                    <title>Consultation | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="Consultation | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="Consultation | Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/services/consultation" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <Box style={{ background: '#d3d3d36e', marginTop: '0px', paddingBottom: '1px', borderRadius: '10px' }}>
                    <Box style={{ margin: '10px' }}>
                        <Box style={{ background: '#f2f1f1', borderRadius: '2px', boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', padding: '3px' }}>
                            
                           
                            
                            

                            <div style={{ padding: '20px' }}>
                                
                                <div style={{ overflow: 'hidden' }}>
                                    
                                    
                                    
                                    <Box id='intro-text'
                                        style={{
                                        ...(viewportWidth >= 600 && { fontSize: '1.4rem', paddingLeft: "15%", paddingRight: "9%"}), // Apply styles for non-mobile
                                        ...(viewportWidth < 600 && { maxWidth: "900px", fontSize: '1.05rem', }), // Apply styles for mobile
                                        }}>

                                         <Typography variant="h3" 
                                            style={{ marginTop: '2.9rem', marginBottom: '0.2rem', textTransform: 'uppercase', fontWeight: '700', fontStyle: 'italic',
                                                     ...(viewportWidth >= 600 && { margin: '4rem', /*textAlign: "center",*/ fontSize: '1.8rem', marginLeft: '11vw'}), // Apply styles for Desktop
                                                     ...(viewportWidth < 600 && { fontSize: '1.35rem', textAlign: 'center', marginBottom: '2rem' }), // Apply styles for mobile
                                            }}>

                                                                        Consultation
                                        </Typography>

                                        <Box id='pic'
                                        style= {{
                                                ...(viewportWidth >= 600 && {width: '400px', float: 'right', paddingRight: "2%", paddingLeft: "10%" }), // Apply styles for Desktop
                                                ...(viewportWidth < 600 && { width: '331', float: 'none', marginLeft: 'auto', textAlign: 'center'}), // Apply styles for mobile
                                        }}>
                                    
                                        <img src={OfficeRoomMin} alt="blueprint" style={{ 
                                            maxWidth: '100%', height: 'auto', borderRadius: '4pc',
                                            ...(viewportWidth < 600 && { maxWidth: '63%', borderStyle: 'solid', borderColor: 'lightslategrey' }),
                                            }} />
                                    </Box>
                                        

                                        <Typography style={{
                                             ...(viewportWidth < 600 && { marginTop: '2rem', marginLeft:'15px' }),
                                        }}>        
                                          In today's complex digital landscape, making the right choices can be the difference between stagnation and growth, vulnerability and security. While technology offers unprecedented opportunities, it also comes with its own set of challenges. This is where strategic consultation can make all the difference. At its core, consultation is about illuminating the path ahead, enabling you to navigate the intricacies of technology with a sense of direction and purpose.
                                          <br/> <br/>
                                            It's not just about solving immediate problems, although that's certainly part of it. Effective consultation provides you with the tools and insights to anticipate challenges before they become roadblocks, to grasp opportunities before they become fleeting moments, and to understand the full potential of your digital assets. The result? A more secure, efficient, and agile operation that's poised for success, whatever your scale or sector.
                                            <br/> <br/>
                                            From pinpointing vulnerabilities in your cybersecurity posture to crafting a digital strategy that aligns with your broader business objectives, consultation is a holistic exercise that touches every facet of your operation. It's about asking the right questions, testing assumptions, and providing tailored solutions that are not just technically sound but also strategically aligned with your goals.
                                            <br/> <br/>
                                            This is what consultation aims to offer: an opportunity for clarity, for strategic vision, and for informed decision-making that will serve as a cornerstone for all your future digital endeavors. If you're serious about leveraging technology to its fullest extent, consultation isn't just an option—it's a necessity.
                                        </Typography>
                                        
                                        {/**Added this for testing */}
                                        
                                        <Divider style={{marginTop: "2rem"}}/>
                                        
                                        <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '700' }}>
                                        Our Holistic Consultation Approach
                                        </Typography>
                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>

                                            {/* Strategic Roadmapping */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Strategic Roadmapping<br/></Typography>
                                                Strategic roadmapping isn't just plotting points on a timeline; it's a dynamic plan that guides your vision from inception to realization. Whether you're entering the digital realm for the first time or revamping an existing platform, strategic roadmapping offers a step-by-step guide to help you navigate through budgeting, scope definition, and technology selection. It's the bedrock on which resilient digital solutions are built.
                                            </Typography>

                                            {/* Needs Analysis */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Needs Analysis: Your Blueprint for Success<br/></Typography>
                                                Understanding your needs is the first step towards a successful project. Through in-depth discussions, we identify your business objectives, constraints, and functional requirements. Our needs analysis process is not a mere checklist but a comprehensive evaluation model that can include SWOT analysis, stakeholder interviews, and even market research, all geared towards developing a tailored action plan.
                                            </Typography>

                                            {/* Making the Right Tech Choices */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Making the Right Tech Choices<br/></Typography>
                                                Technology is an enabler, but choosing the wrong tools can become an obstacle. Our technology selection service provides an impartial assessment of the software, frameworks, and platforms best suited for your project. Whether you're concerned about scalability, security, or both, we help you make informed decisions that align with your long-term objectives.
                                            </Typography>

                                            {/* UX/UI Design */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>UX/UI Design: More Than Just Aesthetics<br/></Typography>
                                                Good design is good business. But it goes beyond just aesthetics. Effective UX/UI design can increase user engagement, lower bounce rates, and even boost conversions. Depending on the project's needs, we offer guidance on user interface best practices, user experience strategies, and the science of creating an intuitive digital environment.
                                            </Typography>

                                            {/* Secure Your Digital Frontier */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Secure Your Digital Frontier<br/></Typography>
                                                In an era where data breaches make headlines, cybersecurity can't be an afterthought. Our security consultation services include an audit of your existing protocols, identification of potential vulnerabilities, and recommendations for enhancing your digital asset's defense mechanisms. Your peace of mind is a priority, and we're here to fortify your digital walls.
                                            </Typography>

                                            {/* Data-Driven Decisions */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Data-Driven Decisions<br/></Typography>
                                                Big data isn't just a buzzword; it's a compass that directs your business towards informed decisions. We offer consultation on data management strategies, analytics tools, and how to transform raw data into actionable insights. Whether it's customer analytics or internal performance metrics, we guide you on how to leverage data for your benefit.
                                            </Typography>

                                            {/* Managing the Process, Maximizing the Output */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Managing the Process, Maximizing the Output<br/></Typography>
                                                Effective project management is the cornerstone of any successful digital initiative. We provide insights into methodologies such as Agile, Scrum, or Waterfall and help you choose what's best for you. Our consultation also includes establishing realistic timelines, defining deliverables, and monitoring progress.
                                            </Typography>

                                            {/* Optimize for Excellence */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Optimize for Excellence<br/></Typography>
                                                An efficient system is more than just fast; it's economical, robust, and scalable. Our performance optimization consultation covers everything from code quality to database design, aiming to lower operational costs while improving user satisfaction.
                                            </Typography>

                                            {/* Navigate the Legal Maze */}
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '600'}}>Navigate the Legal Maze<br/></Typography>
                                                Compliance and regulations are ever-evolving challenges in the digital sphere. Our consultation services help you decode the legal jargon, providing a straightforward path to comply with data protection laws, intellectual property rights, and other regulations pertinent to your project.
                                            </Typography>
                                                <Divider style={{marginTop: "2rem"}}/>
                                        </Box>
                                        
                                        
                                        

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{ marginRight: '20px', marginBottom: '2.5rem'  }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginTop: '2rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> The Importance of Expert Guidance <br/> <br/> </Typography>
                                                    Navigating today's complex technological landscape is akin to charting unknown territories. Herein lies the value of expert consultation—a knowledgeable guide who can pave your path to success. This service transcends mere technical know-how; it offers a nuanced understanding of market trends, consumer behavior, and cutting-edge technologies. With expert consultation, businesses can anticipate challenges, adapt to changes, and make informed decisions, thereby gaining a competitive edge in a saturated market.
                                                </Typography>


                                            </Box>
                                        </Box>

                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{   marginRight: '20px', marginBottom: '2.5rem', }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Strategic Digital Expansion <br/> <br/> </Typography>
                                                    In an era where the digital realm is increasingly integral to business success, simply having an online presence is no longer sufficient. This is where our consultation services come into play. We help businesses not just to exist but to excel online by crafting a digital expansion strategy that aligns with their core objectives. From identifying the right platforms to allocating resources efficiently, our strategic plans ensure that your digital presence serves as an extension of your brand’s core values, driving sustainable growth.

                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                        <Box style={{ marginLeft: '0.2rem', marginBottom: '1.5rem', fontSize: '1.05rem' }}>
                                            <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                                <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                    <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Cybersecurity: More Than Just a Buzzword <br/> <br/> </Typography>
                                                    As data breaches and cyberattacks become ever more sophisticated, cybersecurity has transitioned from being an IT concern to a business imperative. Through our consultation services, we focus on not just fixing vulnerabilities but building a resilient digital infrastructure that anticipates and mitigates risks. We guide you in understanding the latest security protocols, legislation, and best practices, ensuring that your business is fortified against the ever-evolving cyber threats.
                                                </Typography>
                                                
                                            </Box>
                                        </Box>
                                        
                                      

                                        <Box style={{  marginRight: '20px', marginBottom: '2.5rem',   }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Customized Solutions, Personalized Approach<br/><br/> </Typography>
                                                At the heart of our consultation services is a commitment to customization. We understand that no two businesses are alike, which is why our recommendations are tailored to meet your specific needs and circumstances. Our focus on a personalized approach ensures that the solutions we propose aren't just effective but also aligned with your company's culture, objectives, and constraints.
                                            </Typography>
                                            
                                        </Box>

                                        <Box style={{ marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Decision-Making with Confidence<br/><br/> </Typography>
                                                Data and insights are only as useful as the decisions they enable. Our consultation services go beyond providing raw data to offer actionable insights that empower you to make decisions with confidence. Whether it's market entry strategies, technology adoption, or risk management, the information we provide is designed to facilitate clear, informed choices that drive your business forward.
                                            </Typography>
                                            
                                        </Box>
                                        
                                        <Box style={{ marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Long-Term Planning for Sustained Success<br/><br/> </Typography>
                                                Our consultation services aren't just about solving immediate problems; they're about setting you up for sustained success. We engage in long-term planning that takes into account future challenges and opportunities, ensuring that your business remains adaptable and resilient in a rapidly evolving market landscape.
                                            </Typography>
                                            
                                        </Box>
                                        
                                        <Box style={{ marginRight: '20px', marginBottom: '2.5rem',  }}>
                                            <Typography variant="h4" style={{ marginLeft: '0rem', marginBottom: '0.5rem', fontSize: '1.02rem', fontWeight: '400' }}>
                                                <Typography style={{fontWeight: '700', fontSize: '1.02rem', lineHeight: "1"}}> Getting Started with Our Consultation Services<br/><br/> </Typography>
                                                Ready to transform uncertainty into opportunity? Our consultation services offer more than just solutions; they provide a roadmap to long-term success. Reach out for an initial consultation to explore how we can bring unprecedented value to your business, shaping your future in the digital world.
                                                <br/> <br/>
                                                I hope this expansion fits well with your requirements. Feel free to ask for any adjustments or further elaborations.
                                            </Typography>
                                            
                                        </Box>

                                       


                                    </Box>

                                </div>

                            </div>
                            

                            
                        </Box>
                    </Box>
                </Box>

                

                
            </Box>

        );
    };
    
    



    const content = getContent();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };


  return (
    <> 
    <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

        style={{ background: '#353535', }}
        {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
        }}
        /> 
    </>
  )
}

export default withStyles({}, { withTheme: false }) (Consultation);