import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import React from "react";


const styles = {
    BoldASF: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    LightASF: {
        fontWeight: '100',
        fontSize: '2rem',
    },
};

const SecondCompForTesting = () => {

    return(

        <p styles={{fontWeight: '600',
            fontSize: '10rem'}}>  U  NOT!!  COOL</p>

    )

};


export default withStyles(styles)(SecondCompForTesting)