import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@mui/material/Typography';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from '@material-ui/core';

import { textVide } from 'text-vide';

import TitledContainer from '../../BuildingComponents/TitledContainer/TitledContainer';
import DrawerTitleContainer from '../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';
import BannerImage from "../../features/BannerImage";
import BannerVideo from "../../features/BannerVideo";

import MatrixBanner from '../../headers/MatrixBanner';

import useStyles from "./styles";

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize ={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}

const bodyText = () => {
    const txt = "Happel Solutions provides businesses, artists, and freelancers with technological                            solutions that fortify existing business practices and customer bases.                            Transitioning and adapting established, proven business practices into their                            respective digital counterparts is a practice that can be trying and difficult given                            a lack of understanding. Equipped with a large degree of varied experience,                            Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with it's clients; of which comes coupled with a respect for time-sensitive milestones.";
    const txtVideText = textVide(txt);
    return (
        <Typography>
            {txtVideText}
        </Typography>
    )
}


const bodyTextChatGPT= () => {
    const txt = "Happel Solutions provides businesses, artists, and freelancers with technological                            solutions that fortify existing business practices and customer bases.                            Transitioning and adapting established, proven business practices into their                            respective digital counterparts is a practice that can be trying and difficult given                            a lack of understanding. Equipped with a large degree of varied experience,                            Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with it's clients; of which comes coupled with a respect for time-sensitive milestones.";
    const txtVideText = textVide(txt);

    // Wrap the innerHTML with a <span> tag to ensure proper rendering and avoid unexpected issues.
    return (
        
            <span style={{fontFamily: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif!important"}} dangerouslySetInnerHTML={{ __html: txt }} />
        
    )
}



const textVideThisText = (txt) => {
    const txtVideText = textVide(txt);
    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: txtVideText }} />
        </>
    )

}

class ReRevampedIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {renderMsg: false};
        this.myRef = React.createRef();                            
        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }


    render () {
        const { classes } = this.props;
        const { directory } = this.props;
        const viewportHeight = window.innerHeight;

        const bodyTxt = bodyText();
        const handleChange = () => {
            // Set expansionPanel state, so if fading out main component, replace with expansionPanel
            //alert('Handle Change');
        };


        /**
         <BannerVideo style={{
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                    }}/>
         **/


        /**
         * <li
         style={{
                                                textAlignLast: 'end',
                                                marginRight: '2rem',
                                            }}>Restaurant Online Ordering Platform</li>
         * @returns {*}
         */
         const getContent = () => {
            return (

                <Box style={{paddingTop: "0!important", backgroundColor: "#f2f1f1", /*padding: "1rem 1rem"*/}}>
                    
                    
                    <Box style={{ padding: "1rem"}}>
                        
                        <Box style={{marginTop: "10rem", marginBottom: "2rem", marginLeft: '2rem'}}>
                                <Typography className="Berk" variant={'h1'} sx={{ paddingTop: "1rem", fontFamily: "BerkeleyMonoTrial-Regular", fontStyle: "italic", fontSize: "2rem", padding: "2rem 1rem"}}> {textVideThisText("HAPPEL SOLUTIONS:<br/><br/>  <n/> A technology solutions company specializing in various forms of development, tech services, and consultation.")} </Typography>
                        </Box>          
                        
                        
                        

                    </Box>

                    <Divider style={{   }}/>

                    {viewportHeight <= 900 ? (

                        <Box style={{ marginTop: "4rem", marginBottom: "4rem"}}>
                            <Typography style={{fontStyle:'italic', marginLeft: 'auto', marginRight: 'auto', display: 'table'}}> {textVideThisText("WHAT WE CAN DO FOR YOU")}  </Typography>
                            <Box style={{ listStyle: 'decimal-leading-zero', marginLeft: 'auto', marginRight: 'auto', display: 'table' }}>
                                <Typography>
                                    <li>{textVideThisText("Website Development")}</li>
                                    <li>{textVideThisText("Software Development")}</li>
                                    <li>{textVideThisText("Data Backup Solutions")}</li>
                                    <li>{textVideThisText("Consulation Services")}</li>
                                    <li>{textVideThisText("Media Management")}</li>
                                    <li>{textVideThisText("and more")}</li>
                                </Typography>
                            </Box>
                        </Box>

                    ) : (

                        <Box style={{ marginTop: "4rem", marginBottom: "4rem"}}>
                            <Typography style={{fontStyle:'italic', marginLeft: '4rem'}}> {textVideThisText("WHAT WE CAN DO FOR YOU")}  </Typography>
                            <Box style={{ listStyle: 'decimal-leading-zero', marginLeft: '4rem' }}>
                                <Typography>
                                    <li>{textVideThisText("Website Development")}</li>
                                    <li>{textVideThisText("Software Development")}</li>
                                    <li>{textVideThisText("Data Backup Solutions")}</li>
                                    <li>{textVideThisText("Consulation Services")}</li>
                                    <li>{textVideThisText("Media Management")}</li>
                                    <li>{textVideThisText("and more")}</li>
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    


   
                    <Divider style={{marginLeft: '1rem', marginRight: '1rem'}}/>
                    
                                
                    <Box style={{}} >
                        
                        <Typography className={classes.epH4} style={{padding: '4rem', paddingBottom: '0rem'}}> {textVideThisText("OUR MISSION")} </Typography>
                        
                        <Typography style={{padding: '4rem', paddingTop: '2rem', fontFamily: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif!important"}}> {bodyTextChatGPT()}  </Typography>
                        
                        <Divider style={{ marginRight: '6rem'}}/>
                        
                        <Typography style={{maxWidth: '514px', marginTop: '4rem', marginLeft: '4rem', marginBottom: "-1rem", marginRight: '4rem'}}> <i style={{ }}> Perfection is our goal—excellence will be tolerated. </i>  </Typography>
                            

                    </Box>

                              
                            

                        
                  


                </Box>

            )
        };

        //<span style={{padding: "2rem"}} dangerouslySetInnerHTML={{ __html: textVide("Happel Solutions provides businesses, artists, and freelancers with technological                            solutions that fortify existing business practices and customer bases.                            Transitioning and adapting established, proven business practices into their                            respective digital counterparts is a practice that can be trying and difficult given                            a lack of understanding. Equipped with a large degree of varied experience,                            Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with it's clients; of which comes coupled with a respect for time-sensitive milestones.")}} />
        const content = getContent();

        const container_details = {
            title: 'About Us',
            emoji: '💬',
        };

        return (
            <> 
            {window.location.pathname === '/old' ? (
                <TitledContainer id={'AboutContainer'} className={classes.IntroContainer}
                    style={{ background: '#353535'}}
                    { ...
                        
                        {
                            classes: classes,
                            details: container_details,
                            content: content,
                            onchange: {handleChange}
                        }
                    }
                />
            ) : (
                <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

                    style={{
                        background: '#353535',
                    }}
                    {... {
                                classes: classes,
                                directory: directory,
                                details: container_details,
                                content: content,
                                onchange: {handleChange}
                    }}
                /> 
            )}
                </>
        );
    }


    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}
export default withStyles(useStyles, { withTheme: false })(ReRevampedIntro);

/** 
Happel Solutions provides businesses, artists, and freelancers with technological
solutions that fortify existing business practices and customer bases.
Transitioning and adapting established, proven business practices into their
respective digital analogs is a practice that can be trying and difficult given
a lack of understanding. Equipped with a large degree of varied experience,
Happel Solutions' approach to development permits an unmitigated sense of security
and satisfaction via the means of both clear and concise communication with it's
clients; of which comes coupled with a respect for time-sensitive milestones.
**/

/**
 Wasting time:
  <Box style={{display: "flex"}}>
                        
                        <Box style={{maxWidth: "20vw", background: "#fafbfb"}}>
                                <Typography variant={'h1'} style={{fontSize: "2rem", padding: "0rem 0.1rem"}}> WHAT WE DO </Typography>
                             
                        </Box>          
 
                        <Box style={{width: "80vw"}}>
                            <Typography> What can we do for you? </Typography>
                            <Box style={{  marginLeft: '1rem', listStyle: 'circle' }}>
                                <Typography>
                                    <li>Website Development</li>
                                    <li>Software Development</li>
                                    <li>Data Backup Solutions</li>
                                    <li>Consulation Services</li>
                                    <li>Social Media Management</li>
                                    <li>and more</li>
                                </Typography>
                            </Box>
                        </Box>

                    </Box>

 */
/**
<Box>
<Typography> 
    Happel Solutions is a technology solutions company specializing in various forms
    of development, tech services, and consultation options.
</Typography>

</Box>
**/


/**
 * <BannerImage style={{
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                    }}/>
                    
 */
/**
 *  Removing when implementing styling from websites.js
 *
 <p className={classes.FirstBodyParagraph} id={'infoSectionBodyStart'}
 style={{
                           fontSize: '1.0rem',
                           wordSpacing: '0.19rem',
                       }}
 >


 Happel Solutions is a software and web development solutions company purposed on
 facilitating affordable access to a host of modern, advanced technologies ranging
 from websites to complete software packages.
 <br/>

 </p>

 **/

/**  Paper and Image Component*/
/*
      <Paper elevation={2}>
                    <img className={classes.TestingImage} src={'../../laptop.jpg'}/>
                </Paper>
 */

//<p className={classes.SubsectionHeaderFirst}> 💬 About Us </p>

/**
 <Divider className={classes.infoSectionEndDivider}/>
 <p className={classes.SubsectionHeader}> Our Location </p>
 <p className={classes.infoSectionBody} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 </p>
 <Divider className={classes.infoSectionEndDivider}/>

 <p className={classes.SubsectionHeader}> Learn More </p>
 <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tempor incididunt ut labore et dolore magna
 aliqua <Link href="/#">Lorem Ipsum</Link> page.
 </p>
**/