import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
import headerImage from '../../Assets/photos/studio.jpg'
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Typist from "react-typist";

const styles = {
    LogoImage: {
        height: '5rem',
        width: '5rem',

    },
    None: {
        
    },
};


// Local Storage -- homeHeaderPreviouslyShown
class HappelSolutionsLogo extends Component{


    constructor(props) {
        super(props);
        this.state = {};
    }



    componentDidMount() {}


    render () {
        const { classes } = this.props;

        return (
            <div>
            </div>
        );
    }



}

export default withStyles(styles, { withTheme: false })(HappelSolutionsLogo);
