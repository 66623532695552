import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ImagesFromFlickr from "./ImagesFromFlickr";
import BackdropImageButton from "./BackdropImageButton";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function BD(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        let refinedGalleryDIV = 'refined-gallery'+3;
        document.getElementById(refinedGalleryDIV).style.display = 'none';
        setOpen(false);
    };
    const handleToggle = () => {
        let refinedGalleryDIV = 'refined-gallery'+ 3;
        document.getElementById(refinedGalleryDIV).style.display = 'grid';
        setOpen(!open);

    };

    return (
        <div>
            <BackdropImageButton {...{image_file: props.cover_image_url, index: props.index}}
                                 onClick={handleToggle}  // onClick ={ () => {} }
            />
            ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <ImagesFromFlickr {...{album_name: 'Instruments_Prod'}}
                />
            </Backdrop>
        </div>
    );
}
