import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";




import useStyles from './styles'

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize ={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}


class Quotes extends Component {
    constructor(props) {
        super(props);
        this.state = {renderMsg: false};
        this.myRef = React.createRef();
        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }


    render () {

        const { classes } = this.props;

        return (


            <body className={classes.homepageBody}>



            <ListItem></ListItem>

            <div className="home-body-container" id={"home-body-container-before"}>



                <Container className={classes.IntroContainer} id={'AboutContainer'}
                           style={{
                               borderTopColor: "rgb(219, 219, 219)",
                               borderTopStyle: "solid",
                               borderTopWidth: "1px",
                               borderRightColor: "rgb(219, 219, 219)",
                               borderRightStyle: "solid",
                               borderRightWidth: "1px",
                               borderBottomColor: "rgb(219, 219, 219)",
                               borderBottomStyle: "solid",
                               borderBottomWidth: "1px",
                               borderLeftColor: "rgb(219, 219, 219)",
                               borderLeftStyle: "solid",
                               borderLeftWidth: "1px",
                               borderImageSource: "initial",
                               borderImageSlice: "initial",
                               borderImageWidth: "initial",
                               borderImageOutset: "initial",
                               borderImageRepeat: "initial",
                           }}>


                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBodyStart'}
                       style={{
                           fontSize: '0.9rem',
                           wordSpacing: '0.19rem',
                           marginBlockEnd: '0',
                       }}
                    >
                        <i>
                            "If releasing your work doesn't, at times, make you physically ill...
                            If you can’t find things you wish you’d done better...
                            If you don’t wrestle with your work the way Jacob wrestled with God...
                            you may not be an artist."
                        </i>

                    </p>

                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBodyStart'}
                       style={{
                           fontSize: '0.8rem',
                           wordSpacing: '0.19rem',
                           textAlign: 'right',
                           fontWeight: '500',
                           marginBlockStart: '0.5em',
                       }}
                    >
                        <i>~ Jon du Toit (Paraphrased)</i>
                    </p>


                </Container>
            </div>
            </body>



        );
    }


    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}
export default withStyles(useStyles, { withTheme: false })(Quotes);


/**
<div className="home-body-container" id={"home-body-container-before"}>



    <Container className={classes.IntroContainer} id={'AboutContainer'}
               style={{
                   borderTopColor: "rgb(219, 219, 219)",
                   borderTopStyle: "solid",
                   borderTopWidth: "1px",
                   borderRightColor: "rgb(219, 219, 219)",
                   borderRightStyle: "solid",
                   borderRightWidth: "1px",
                   borderBottomColor: "rgb(219, 219, 219)",
                   borderBottomStyle: "solid",
                   borderBottomWidth: "1px",
                   borderLeftColor: "rgb(219, 219, 219)",
                   borderLeftStyle: "solid",
                   borderLeftWidth: "1px",
                   borderImageSource: "initial",
                   borderImageSlice: "initial",
                   borderImageWidth: "initial",
                   borderImageOutset: "initial",
                   borderImageRepeat: "initial",
               }}>


        <p className={classes.infoSectionBodyFinal} id={'infoSectionBodyStart'}
           style={{
               fontSize: '0.9rem',
               wordSpacing: '0.19rem',
               marginBlockEnd: '0',
           }}
        >
            <i>
                "Perfection is our goal, excellence will be tolerated."
            </i>

        </p>

        <p className={classes.infoSectionBodyFinal} id={'infoSectionBodyStart'}
           style={{
               fontSize: '0.8rem',
               wordSpacing: '0.19rem',
               textAlign: 'right',
               fontWeight: '500',
               marginBlockStart: '0.5em',
           }}
        >
            - J. Yahl
        </p>


    </Container>
**/


/**
<p className={classes.SubsectionHeaderFirst}
   style={{}}>

    Mantras Ascribable To Happel Solutions </p>
**/
/**  Paper and Image Component*/
/*
      <Paper elevation={2}>
                    <img className={classes.TestingImage} src={'../../laptop.jpg'}/>
                </Paper>
 */



/**
 <Divider className={classes.infoSectionEndDivider}/>
 <p className={classes.SubsectionHeader}> Our Location </p>
 <p className={classes.infoSectionBody} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 </p>
 <Divider className={classes.infoSectionEndDivider}/>

 <p className={classes.SubsectionHeader}> Learn More </p>
 <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tempor incididunt ut labore et dolore magna
 aliqua <Link href="/#">Lorem Ipsum</Link> page.
 </p>
 **/