import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
import headerImage from '../../Assets/photos/studio.jpg'
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Typist from "react-typist";
import HappelSolutionsLogo from "../features/HappelSolutionsLogo";

const styles = theme => ({
    pageHeaderTextContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1480px',
        flexGrow: 2,
        width: '100%',
        padding: 0,
        textAlign: 'center',
        //marginTop: '5rem',
        //marginTop:'4%',
        //marginBottom: '0.5rem',
    },
    CenteredContainer: {
        //display: 'flex',
        //maxWidth: '300px',
        position: 'relative',
        zIndex: 4,
       backgroundImage: "url('../../GreenCloudBgErased.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
       backgroundPosition: 'center center',

        //marginLeft: '5rem',

        //filter: 'drop-shadow(0px 2px 9px #666)',
        //webkitFilter: 'drop-shadow(0px 2px 9px #666)',

        [theme.breakpoints.down(900)]: {
            height: '25rem',//'237.5px',
            padding: '0',
            marginTop: '-1rem',
            //marginBottom: '5rem',
            // backgroundColor: theme.palette.secondary.main,
        },

        [theme.breakpoints.up(901)]: {
            height: '675px',
            marginLeft: '9rem',
            marginTop: '6rem',
        },

    },


    BackgroundImageContainer: {
        display: 'flex',
        //maxWidth: '300px',
        backgroundImage: "url('../../GreenCloudBgErased.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center center',

        top: '-13rem',
        height: '675px',
        position: 'absolute',

    },

    MarqueeImage: {
        position: 'relative',
    },


    ContentContainer: {

        position: 'relative',
        //padding: '10px',
        //width: '50%',
       // margin: '0 auto',
        display: 'flex',
        flexFlow: 'row',
        zIndex: 11,
        //top: '50%',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',



        [theme.breakpoints.down(900)]: {
            top: '46%',
            left: '5%',

            // height: '33%',
           // marginTop: '7rem',
            //paddingLeft: '3rem'
            // backgroundColor: theme.palette.secondary.main,
            //top: '52rem',
            //left: '35rem',
            //height: '4rem',
        },

        [theme.breakpoints.up(901)]: {
            top: '50%',
            left: '20%', /** TEMPORARY!!!!! **/
            //left: '36%', /** TEMPORARY!!!!! **/
           // height: '675px',
           // marginTop: '22rem',
//            marginLeft: '22rem',
        },
    },

    LogoImage: {
        order: 1,
        [theme.breakpoints.down(900)]: {
            paddingTop: '4rem !important',
            paddingLeft: '3rem !important',
            height: '3rem',
            width: '3rem',
            marginRight: '5px',
            opacity: 0,

            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up(901)]: {
            paddingTop: '4rem !important',
            marginRight: '5px',
            opacity: 0,
            height: '6rem',
            width: '6rem',

        },

//
        /**
        height: '5rem',
        width: '5rem',
        marginLeft: '-35px',
        marginRight: '25px',
        opacity: '0'
         **/
    },
    HSTitleComponent: {
        order: 2
    },
    InfoPageTitleRowOne: {
        paddingTop: '4.8%',
        letterSpacing: '.45rem',
        wordSpacing: '0.5rem',
        transformText: 'uppercase',
        //fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        fontFamily: "Titillium Web, sans-serif",
        position: 'relative',
        color: 'black',
        textAlign: 'center',
        filter: 'drop-shadow(0px 2px 9px #666)',
        webkitFilter: 'drop-shadow(0px 2px 9px #666)',
        [theme.breakpoints.down(900)]: {
            fontSize: '1.85rem',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '2.65rem',
            lineHeight: '1em',
        },

    },


    InfoPageTitleRowTwo: {
        paddingTop: '4.8%',
        color: 'rgb(40 40 40 / 90%)',
        wordSpacing: '0.5rem',
        transformText: 'uppercase',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        position: 'relative',
        textAlign: 'center',
        [theme.breakpoints.down(900)]: {
            fontSize: '1.05rem',
            letterSpacing: '.29rem',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.35rem',
            letterSpacing: '.45rem',
        },
    },

    WaveGridImage: {

        position: 'relative',
        zIndex: 5,
        //left: 0,


        filter: 'drop-shadow(0px 2px 9px #666)',
        webkitFilter: 'drop-shadow(0px 2px 9px #666)',
        [theme.breakpoints.down(900)]: {
            height: '51vw',
            top: '-1rem',
            right: '-4rem',
        },

        [theme.breakpoints.up(901)]: {
            height: '23vw',
            float: 'right',
            top: '-10rem',
            right: '-1rem',
        },
        [theme.breakpoints.up(1650)]: {
            height: '17vw',
            float: 'right',
            top: '-9rem',
            right: '-6rem',
        },

    },





});


// Local Storage -- homeHeaderPreviouslyShown
class HomepageTitleText extends Component {


    constructor(props) {
        super(props);
        this.state = {};
    }

    onHeaderTyped = () => {
        let displayingInitialStyling = document.getElementById('VerticalStepperDiv') !== null;
        let displayingTransitionedStyling = document.getElementById('VerticalStepperDiv-AfterTitleTyped') !== null;
        let displayingAboveStyling = document.getElementById('VerticalStepperDiv-AfterTitleTyped-AboveContent') !== null;

        document.getElementsByClassName('LogoImage')[0].setAttribute('id', 'LogoImage');
        document.getElementsByClassName('MuiStepper-vertical')[0].setAttribute('ready', true);


        /**
        Removed when removing Holiday Sign

         document.getElementById('holiday-sign-banner').classList.add('HolidaySignBannerAfter');



        if (window.screen.availHeight <= 901) {
            document.getElementsByClassName('layer1')[0].style.width = '0.8rem';
            document.getElementsByClassName('layer1')[0].style.height = '0.8rem';
            document.getElementsByClassName('layer2')[0].style.width = '0.6rem';
            document.getElementsByClassName('layer2')[0].style.height = '0.6rem';
            document.getElementsByClassName('layer3')[0].style.width = '0.5rem';
            document.getElementsByClassName('layer3')[0].style.height = '0.5rem';
        } else {
            document.getElementsByClassName('layer1')[0].style.width = '1.2rem';
            document.getElementsByClassName('layer1')[0].style.height = '1.2rem';
            document.getElementsByClassName('layer2')[0].style.width = '0.9rem';
            document.getElementsByClassName('layer2')[0].style.height = '0.9rem';
            document.getElementsByClassName('layer3')[0].style.width = '0.7rem';
            document.getElementsByClassName('layer3')[0].style.height = '0.7rem';
        }
         **/

        if (displayingInitialStyling) {

            this.props.onChange({typistDone: true});
            this.setState({ renderMsg: true });
            const cursor_dom_elements = document.getElementsByClassName('Cursor');
            const cursor_dom = cursor_dom_elements[0];
            cursor_dom.style.visibility = 'hidden';
            console.log('THis is cursor_dom_el: ', cursor_dom);
            let r = document.getElementById('VerticalStepperDiv');
            console.log('Vertical Stepper DIV: ', r);

            document.getElementById('VerticalStepperDiv').setAttribute('ready', "true");
            document.getElementById('VerticalStepperDiv').setAttribute('id', 'VerticalStepperDiv-AfterTitleTyped');
            //document.getElementById('snow-wrapper-initial').setAttribute('id', 'snow-wrapper-slower-storm');


            //document.getElementById('LogoImage').style.opacity = "1";
        }


    };

    componentDidMount() {}


    //<img src={'../../WaveGrid.png'} alt={'i'} className={classes.WaveGridImage} />

    render () {
        const { classes } = this.props;
        console.log('HomePageTitleText: ', this.props);

        return (

            <Container className={classes.pageHeaderTextContainer}>




                <Container className={classes.CenteredContainer} id={'HomePageTitleTextCenteredContainer'}>

                            <Container className={classes.ContentContainer}>

                                <img className={'LogoImage'} src={"./HappelSolutionsTransparent.png"}
                                     alt={'sdsdsdsdss.jpg'} id={'LogoImage'}/>

                                <img className={'LogoImage'} src={"./GreenCloudBgErased.png"}
                                     alt={'sdsdsdsdss.jpg'}/>

                                <Typist
                                    className={classes.HSTitleComponent}
                                    avgTypingDelay={90}
                                    startDelay={1000}
                                    hideWhenDone={'true'}
                                    onTypingDone={this.onHeaderTyped}
                                >

                                    <span className={classes.InfoPageTitleRowOne}>
                                        HappelSol
                                    </span>

                                    <Typist.Backspace count={3} delay={200} />
                                    <Typist.Delay ms={500} />

                                    <span className={classes.InfoPageTitleRowTwo}>
                                        <br/>&nbsp;Solutions&nbsp;
                                    </span>
                                    <Typist.Delay ms={200} />


                                </Typist>

                            </Container>




                    </Container>




            </Container>
        );
    }



}

export default withStyles(styles, { withTheme: false })(HomepageTitleText);

/**
<img className={'HolidaySignBannerBefore'} id={'holiday-sign-banner'} src={"./HangingSign.png"}
     alt={'sdsdsdsdss.jpg'}/>
**/


//<div className={'text'}>HAPPEL SOLUTIONS</div>
//                        <span className={classes.BackgroundImageContainer}> </span>
/**
 *
 * <Container className={classes.BackgroundImageContainer}>

 </Container>
 */
/**
 * [theme.breakpoints.down('sm')]: {
            fontSize: '1.75rem',
            // backgroundColor: theme.palette.secondary.main,
        },
 [theme.breakpoints.up('lg')]: {
            fontSize: '2.65rem',
        },

 [theme.breakpoints.down('sm')]: {
            height: '237.5px',
            marginLeft: '1.75rem',
            // backgroundColor: theme.palette.secondary.main,
        },

 [theme.breakpoints.up('lg')]: {
            height: '675px',
            marginLeft: '12rem',
        },


 *
 **/

/**
 <div className={'marquee'}>
 <div className="marquee__inner" aria-hidden="true">
 <span><img className={classes.MarqueeImage} src={'../../GreenCloudBgErased.png'} alt={'h'}/></span>

 </div>
 </div>

 **/

/**
 *     <div className={'marquee'}>
 <div className="marquee__inner" aria-hidden="true">
 <span><img className={classes.MarqueeImage} src={'../../GreenCloudBgErased.png'} alt={'h'}/></span>
 </div>
 </div>
 */
//                <img src={'../../WaveGrid.png'} alt={'i'} id={'wave-grid-image'} className={classes.WaveGridImage} />