import React, {Component, Fragment, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ImagesFromFlickr from "./ImagesFromFlickr";
import Grow from "@material-ui/core/Grow";
import Carousel, {ModalGateway} from "react-images";
import Modal from "@material-ui/core/Modal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import BackdropImageButton from "./BackdropImageButton";
import { red, blue, green } from "@material-ui/core/colors";

const Flickr = require('flickr-sdk');


const styles = {

};





const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile }) => {
    return (
        <div>
            {/* <Button onClick={() => setHandleOpen({ open: true })}>Open carousel</Button> */}
            <AutoRotatingCarousel
                label="Get started"
                open={handleOpen}
                onClose={() => setHandleOpen({ open: false })}
                onStart={() => setHandleOpen({ open: false })}
                autoplay={false}
                mobile={isMobile}
                style={{ position: "absolute" }}
            >
                <Slide
                    media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_79394/youtube.png" />
                    }
                    mediaBackgroundStyle={{ backgroundColor: red[400] }}
                    style={{ backgroundColor: red[600] }}
                    title="This is a very cool feature"
                    subtitle="Just using this will blow your mind."
                />
                <Slide
                    media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png" />
                    }
                    mediaBackgroundStyle={{ backgroundColor: blue[400] }}
                    style={{ backgroundColor: blue[600] }}
                    title="Ever wanted to be popular?"
                    subtitle="Well just mix two colors and your are good to go!"
                />
                <Slide
                    media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png" />
                    }
                    mediaBackgroundStyle={{ backgroundColor: green[400] }}
                    style={{ backgroundColor: green[600] }}
                    title="May the force be with you"
                    subtitle="The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe."
                />
            </AutoRotatingCarousel>
        </div>
    );
};


// Local Storage -- homeHeaderPreviouslyShown
function ButtonImageAndSlides(props){

    const [carouselOpen, setHandleOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(true );

    const handleClick = () => {
        setHandleOpen(true);
    };

    const matches = useMediaQuery("(max-width:600px)");

    useEffect({} = () => {
       // console.log('Auto Rotating Props: ', props)
    });

    //console.log('Auto Rotating Props: ', props);
    const showcase_details = props.showcase_details;



    return (


            <Fragment>

                <BackdropImageButton
                    {...
                        {
                            image_file: props.cover_image_url,
                            index: 0
                        }
                    }
                    onClick={handleClick}  // onClick ={ () => {} }
                />


                < AutoRotatingCarouselModal
                    isMobile={matches}
                    handleOpen={carouselOpen}
                    setHandleOpen={setHandleOpen}
                >

                    {this.state.sortedImages.map((image, x) => (

                            <Slide
                                media={
                                    <img src="https://i.pinimg.com/736x/06/d1/5f/06d15fc8a69e2ab67143e5357a4184d7.jpg" />
                                }
                                mediaBackgroundStyle={{ backgroundColor: '#DB7093' }}
                                style={{ backgroundColor: '#FF0000'  }}
                                title="Max McGill Productions"
                                subtitle="Just using this will blow your mind."
                            />
                        ))
                    }


                </AutoRotatingCarouselModal>

            </Fragment>

    );
}

//                        <SlidesAndImagesForCarousel {...{album_name: 'Instruments_Prod', index: 0}}/>



class SlidesAndImagesForCarousel extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            flickr_key: '7c9b3630d78042478d9946955360dd6f',
            user_id: "190684174@N02",
            albumDataRetrieved: false,
            photoDataRetrieved: false,
            date: new Date(),
            isLoading: true,
            images: []
        };



        this.getAlbumData = this.getAlbumData.bind(this);
        this.locateAlbumWithinResponseData = this.locateAlbumWithinResponseData.bind(this);
        //this.photoRetrievalProcess = this.photoRetrievalProcess.bind(this);
        //this.setAlbumFoundConfirmationState = this.setAlbumFoundConfirmationState.bind(this);
    }


    getAlbumData = async (FlickrAPI, user_id, cb) => {
        try {
            FlickrAPI.photosets.getList({user_id: user_id})
                .then(function (res) {
                    cb( {albums: res.body.photosets, total: res.body.photosets.total} )
                });
        } catch (err) { console.log("Error occurred when fetching albums") }
    };


    locateAlbumWithinResponseData = async (data, album_title, cb) => {
        for (let i = 0; i <= data.total - 1; i++) {
            let album_title_i = data.albums.photoset[i].title._content;
            if (album_title_i === album_title) {
                //console.log("Success - located album: ", data.albums.photoset[i]);
                this.setAlbumStates(data.albums.photoset[i], true, async function () {
                    cb(data.albums.photoset[i], true)
                });
            }
        }
    };


    setAlbumStates = async (album_data, wasFound, cb) => {
        this.setState({
            albumData: album_data,
            correctAlbumFound: wasFound,
            albumDataRetrieved: true,
        });  cb();
    };


    componentDidMount() {
        //console.log('Componenet Mnted!!');
    }


    tick() {
        //console.log('Current state of flick_album, on tick: ', this.state.images);
        this.setState({
            date: new Date()
        });
    }




    render() {
        //console.log("Props: ", this.props);
        //console.log('Image Gallery State: ', this.state);

        //console.log('Image Gallery Props: ', this.props);

        const {classes} = this.props;
        const {album_name} = this.props;
        const {cover_image_url} = this.props;
        const {index} = this.props;
        const FlickrAPI = new Flickr(this.state.flickr_key);

        const checkForAndGetAlbumData = async (cb) => {
            let images_ = new Array();
            const user_id = this.state.user_id;

            await this.getAlbumData(FlickrAPI, this.state.user_id,  async (album_data) =>  {
                //console.log('Album Data & Title: ', album_data, album_name);
                await this.locateAlbumWithinResponseData(album_data, album_name,
                    async function (specific_album_data, wasFound) {

                        if (wasFound) {
                            FlickrAPI.photosets.getPhotos({
                                photoset_id: specific_album_data.id,
                                user_id: user_id,
                            })
                                .then( function (res) {
                                    let album_photos_object = (JSON.parse(res.text)).photoset.photo; // JSON

                                    for (let r = 0; r <= album_photos_object.length - 1; r++) {
                                        FlickrAPI.photos.getSizes({photo_id: album_photos_object[r].id}).then(function(sizes) {
                                            let sizes_json = (JSON.parse(sizes.text)).sizes.size;
                                            let image = new Array();

                                            if (sizes_json[(sizes_json).length - 1].width > sizes_json[(sizes_json).length - 1].height)
                                            {
                                                image.cols = 2;
                                            } else {
                                                image.cols = 1
                                            };

                                            image.caption = ""; image.author = {};

                                            image.source = {
                                                thumbnail: sizes_json[5],
                                                medium_size: sizes_json[6],
                                                large_size: sizes_json[9],
                                                original_size: sizes_json[(sizes_json).length - 1],
                                            };

                                            images_.push(image);

                                            if (images_.length === album_photos_object.length) {
                                                console.log("Done gathering images");
                                                cb(album_photos_object, true, images_);
                                            }
                                        });
                                    }
                                })
                        }
                    })
            });
        };



        if (!this.state.albumDataRetrieved) {
            checkForAndGetAlbumData(  (photos_object, wasFound, images) =>  {
                this.setState({
                    photo_data: photos_object,
                    photoDataRetrieved: wasFound,
                    images: images
                });
                //console.log('BOOM Setting State: ', wasFound)
            });
        }



        const CreateImageComponentsFromImageData = () => {
            return (
                <Fragment>
                    {
                        this.state.images.map( (image, i) => <img key={i} src={image.src.medium_size.source} /> )

                    }
                </Fragment>
            )
        };


        return (

            <Fragment>

                {this.state.photoDataRetrieved ? (

                    <Fragment>

                        <ButtonImageAndSlides props={this.state}
                            { ... {
                                index: index,
                                cover_image_url: cover_image_url,
                                album_name: album_name,

                            }} classes={classes}/>

                    </Fragment>


                ) : <p> Gallery dawg </p>}

            </Fragment>

        )
    }
}


export default withStyles(styles, { withTheme: false })(SlidesAndImagesForCarousel);







// <ImagesFromFlickr /*className={classes.ImageGallery}*/ {...{album_name: 'Instruments_Prod', index: props.index}} />;

/**

 {

                            {imagesForCarousel}.map((item, index) =>

                                <Slide
                                    media={
                                        <img src="https://i.pinimg.com/736x/06/d1/5f/06d15fc8a69e2ab67143e5357a4184d7.jpg" />
                                    }
                                    mediaBackgroundStyle={{ backgroundColor: red[400] }}
                                    style={{ backgroundColor: red[600] }}
                                    title={item.source.medium_size.source}
                                    subtitle="Just using this will blow your mind."
                                />

                            )}

 **/

/**
 < AutoRotatingCarousel
 label="Get started"
 open={handleOpen.open}
 onClose={() => setHandleOpen({ open: false })}
 onStart={() => setHandleOpen({ open: false })}
 autoplay={true}
 mobile
 style={{ position: "absolute", height: '350', width: '450px' }}
 className={'AutoRotating'}
 >

 <SlidesAndImagesForCarousel {...{album_name: 'Instruments_Prod', index: 0}}/>

 </AutoRotatingCarousel>
**/