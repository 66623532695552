import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import withStyles from "@material-ui/core/styles/withStyles";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

import ParticleImage, { ParticleOptions } from "react-particle-image";
import './Assets/css/default.min.css';
// import components
import ClickToScrollHeader from "./components/headers/ClickToScrollHeader";
//import Intro from '../src/components/pages/Intro'

import Main from "./components/pages/Main";
import OldMain from "./components/pages/OldMain"

import TestButton from "./components/features/TestButton";
import Button from "@material-ui/core/Button";
import PageComponentRouter from "./components/pages/PageComponentRouter";
import HomepageTitleText from "./components/text/HomepageTitleText";
import Template from "./components/Template";
import VerticalStepper from "./components/menu/VerticalStepper";

import ScrollMonitor from "./components/features/ScrollMonitor";
import SvgIcon from "@material-ui/core/SvgIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createTheme, colors, ThemeProvider } from '@mui/material';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';

import { CssBaseline } from '@material-ui/core';
//import { ECommerceApp } from './components';
import { SchedulerApp } from './components';
//import Calendar from "@ericz1803/react-google-calendar";
import WebFont from "webfontloader";
import { Intro,  IMG, ReRevampedIntro, WebsitesPage, WebsitesBasic, WebsitesPageAttemptThree, Websites, SoftwarePage,SoftwarePageBasic, NewSoftwarePage, SoftwarePageAttemptThree, NewWebsitesPage, ITPage, RevampedIntro, ReRevampedIntroFunctional, Services, ServicesOffered, SimpleContact, Pricing, Quotes, Contact, Showcase, QR, ECommerceApp, ITSolutions, Consultation} from './components/pages'
import { Scheduler, Calendar } from './components/scheduler';


import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import BuildIcon from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
//import Berk from '../src/Assets/fonts/BerkeleyMonoTrial-Regular.otf'
import BerkeleyMonoTrial from '../src/Assets/fonts/BerkeleyMonoTrial-Regular.otf';
import NotoSansRegular from '../src/Assets/fonts/NotoSans-Regular.ttf';
import NewServices from './components/pages/ServicesFolder/NewServices';
import NewServicesNoTextVide from './components/pages/ServicesFolder/NewServicesNoTextVide';
import SimpleSimpleContact from './components/pages/Contact/SimpleSimpleContact';


WebFont.load({google: {families: ["Roboto:300,400,500"]}});

const gapi = window.gapi;
const CALENDAR_ID = process.env.REACT_APP_USERS_CALENDAR_ID;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    AppClass: {
        backgroundImage: "url('../../DrawingPaperBg.png')",
        overflowX: 'hidden',
        height: "100vh",
        position: 'relative'
    }

};

//const https = require('https');
const ip_api_url = "https://extreme-ip-lookup.com/json/";
const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
const URL = 'https://api/entries';

var ClickToScrollHandlerFirstTime = true;


class ComponentRenderer extends Component {

    constructor() {
        super();
        this.state = {
            Clicked: false,
        };
    }


    testingFunction(event) {
        console.log('Down Arrow Clicked State: ', event);
        console.log('!!!!!!!!!!!!!!!!!!!Down Arrow Clicked');
    }

    render () {
        console.log("Component Rendered Props: ", this.props);
        const comp = this.props.component;
        return (
            <div id={this.props.name} onClick={this.testingFunction}>
                {comp}
            </div>)
    }
}



const theme = createTheme ({
    palette: {
      primary: {
        main: '#ffffff',
        //main: colors.indigo[50],//'#e8eaf6',//colors.red[500],
        mainLight: '#e8eaf670',
        mainWhite: '#ffffff4a',
        paperColor: "rgb(252, 252, 252)",
        redd: "rgb(252 36 36)",

      },

      secondary: {
            main: colors.orange[500],
            contrastText: colors.orange[500], //'#000',
      },
      tertiary: {
        contrastText: 'rgba(0, 0, 0, 0.87)',
      }
    },
    fontFamilyRegular: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
    typography: {
        //fontFamily: '"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        fontFamily: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
        //fontFamily: 'BerkeleyMonoTrial-Regular',
        //fontFamily: 'BerkeleyMonoTrial-Regular',
        lineHeight: '1.5625',
        color: 'rgba(0, 0, 0, 0.87)', 
        fontWeight: '400',
        
        //fontFamily: [
        //  'BerkeleyMonoTrial-Regular, Arial, NotoSansRegular, '
        //]
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'BerkeleyMonoTrial';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('BerkeleyMonoTrial'), local('BerkeleyMonoTrial-Regular'), url(${BerkeleyMonoTrial}) format('opentype');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }, 
            @font-face {
              font-family: 'NotoSansRegular';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('NotoSansRegular'), local('NotoSans-Regular'), url(${NotoSansRegular}) format('truetype');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            },
          `,
      },
    },
})



class App extends Component {


    constructor() {
        super();
        this.state = {
            firstRendered: true,
            currentStage: 'initial',
            buttonPressed: false,
            ClickToScrollClicked: false
        };

        this.stages = {
            initial: 'initial',
            secondary: 'secondary',
            examples: '',
            contact: '',
            ig_story: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateStageSelection = this.updateStageSelection.bind(this);
    }



    async asyncSetStage(new_stage, callback) {
        await this.componentWillUnmount();
        await this.setState({
            currentStage: new_stage,
            buttonPressed: true
        });
        callback()
    }


    async updateStage(new_stage, callback) {
        console.log('> [App.js] - updateStage:  Setting State');
        await this.asyncSetStage(new_stage, async function() {
            await callback()
        })
    }

    
    componentWillUnmount() {
        console.log('Un-mounting!');
    }

    componentDidMount() {
        console.log('> [App.js] - Current Stage: ', this.state.currentStage);
        this.setState({firstTimeRendering: false})
        console.log("these are props: " + this.props)
    }

    render() {
        document.title= "Happel Solutions";
        console.log('> [App.js] - Rendering...');
        console.log('> [App.js] - Current Stage: ', this.state.currentStage);
        const {classes} = this.props;
        ReactGA.initialize('UA-101024354-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        const tagManagerArgs = {
            gtmId: 'GTM-WW5XMHJ'
        };
        TagManager.initialize(tagManagerArgs);
        const components_array = [
            {name: 'ClickToScrollHeader', component: <ClickToScrollHeader/>},
            {name: 'TestButton', component: <TestButton/>},
        ];


        const s_currentStage = this.state.currentStage;
        const s_currentStageComponent = this.stages[s_currentStage];

        const eventhandler =  data => {
            if (data.displayHeadImage === false && ClickToScrollHandlerFirstTime === true) {
                ClickToScrollHandlerFirstTime = false;
                //console.log('> [App.js] - ClickToScroll eventhandler, displayHeadImage: ', data.displayHeadImage);
                this.setState({ClickToScrollClicked: true});
            } else {
                //console.log('> [App.js] - eventhandler: ', data );
            }
        };


        const typistEventHandler = data => {
            if (data.typistDone === 'true') {
                console.log('> App.js - Typist Done!');
                this.setState({typistDone: true});
            }
            //console.log('Typist Event Handler: ', data);
        };

        const directory = [
            { title: 'About Us', componentName: 'Intro', path: '/', icon: <AccessibilityNewIcon/> },
            { title: 'Services', componentName: 'Services', path: '/services', icon: <BuildIcon/> },
            //{ title: 'Website Showcase', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/showcase', icon: <ComputerIcon/> },
            { title: 'Contact', componentName: 'Contact', path: '/contact', icon: <PermContactCalendarIcon/> },
            
         ]
    

        return (
            <ThemeProvider theme={theme}>
            <Router id="fluidbox-overlay">
               
                <div id={"App"} className={classes.AppClass}>
                
                    <Routes>

                        {/**
                         * <Route  path='/oldhome' element={<ReRevampedIntro directory={directory}/> }/>
                         * <Route exact path='/services/oldssoftware' element={<SoftwarePageBasic directory={directory} thisName={'Services'}/>}/> 
                         * <Route exact path='/services/oldwebsites' element={<WebsitesPage directory={directory} thisName={'Services'}/>}/> 
                         * <Route exact path='/services/newsoftware' element={<NewSoftwarePage directory={directory} thisName={'Services'}/>}/> 
                         * <Route exact path='/services/oldsoftware' element={<SoftwarePage directory={directory} thisName={'Services'}/>}/> 
                         * <Route exact path='/oldservices' element={<Services directory={directory} thisName={'Services'}/>}/> 
                         * <Route exact path='/oldcontact' element={<Contact directory={directory} thisName={'Contact'}/>}/> 
                         * <Route exact path='/scheduler/' element={<Scheduler/>}/> 
                         * <Route exact path='/scheduler/calendar' element={<Calendar/>}/> 
                         * <Route exact path='/old/'{...classes} element={<OldMain {...classes}/> }/> 
                         * <Route exact path='/ecommerce' element={<ECommerceApp />}/>
                         * <Route path='/maxmcgill' component={() => { window.location.href = 'https://www.maxmcgillproduction.com/'; return null; }}/>
                         * <Route exact path='/showcase' element={<Showcase classes={classes} directory={directory} thisName={'Showcase'}/>} /> 

                         */}

                        <Route  path='/' element={<ReRevampedIntroFunctional directory={directory}/> }/>
                        <Route  path='/about-us' element={<ReRevampedIntroFunctional directory={directory}/> }/>
                        <Route  path='/IMG' element={<IMG directory={directory}/> }/>
                        <Route exact path='/services' element={<NewServicesNoTextVide directory={directory} thisName={'Services'}/>}/> 
                        <Route exact path='/services/websites' element={<WebsitesBasic directory={directory} thisName={'Services'}/>}/> 
                        <Route exact path='/services/consultation' element={<Consultation directory={directory} thisName={'Services'}/>}/> 
                        <Route exact path='/services/software' element={<SoftwarePageAttemptThree directory={directory} thisName={'Services'}/>}/> 
                        <Route exact path='/services/tech' element={<ITSolutions directory={directory} thisName={'Services'}/>}/> 
                        <Route exact path='/contact' element={<SimpleSimpleContact directory={directory} thisName={'Contact'}/>}/> 
                        <Route exact path='/qr' element={<QR />}/> 
                            
                        <Route path='/instagram' component={() => { window.location.href = 'https://www.instagram.com/happelsolutions/'; return null; }}/>
                        <Route path='/facebook' component={() => { window.location.href = 'https://www.facebook.com/HappelSolutions/'; return null; }}/>
                        
                        
                    </Routes>

                </div>

                {
                    /**
                     <div id={"AppAdditionalComponents"} 
                     style={{ marginTop: '-100vh', position: 'relative' }}>
                         <Routes>
                            <Route path='/ecommerce' element={<ECommerceApp/>}/>
                            <Route path='/scheduler/' element={<SchedulerApp/>} />
                        </Routes>
                    </div>
                     */
                }

            </Router>
            </ThemeProvider>
        );

    }

    handleChange(event) {
        this.setState({currentStage: event.target.value});
    }

    updateStageSelection =  async (event) => {
        //console.log("This is event target value: ", event.target);
        await this.updateStage(event.target.value, async function () {
          console.log('DONE');
        })
    };
}



export default withStyles(styles, { withTheme: false })(App);
//                    //{window.location.pathname === '/' && (localStorage.getItem('headImageShown') !== 'true') ? (



//                    <HomepageTitleText onChange={typistEventHandler}/>

/**
 <Route path='/contact-us' component={() => {
                        window.location.href = 'https://www.instagram.com/thedenproductions/';
                        return null;
                    }}/>

 **/

//            //currentStageComponent: this.stages[this.currentStage],

/**
 {window.location.pathname === '/' ? (
                        components_array.map((item, x) => {
                            //return <ComponentRenderer onClick={this.testingFunction} name={item.name} component={item.component}/>
                            return <ComponentRenderer onClick={this.testingFunction} name={item.name} component={item.component}/>

                        })
                    ): null}
**/

//                <param name={'isClicked'} value={this.state.Clicked}/>


/** removed click to scroll for now:



 {!this.state.ClickToScrollClicked ? (
                        <ClickToScrollHeader onChange={eventhandler}/>
                    ): null}


 {this.state.ClickToScrollClicked ? (
                            <HomepageTitleText onChange={typistEventHandler}/>
                    ) : null}


 **/
