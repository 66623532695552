import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';

import { SvgIcon } from "@material-ui/core";
import StorefrontIcon from '@material-ui/icons/Storefront';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';

import { Zoom } from '@material-ui/core';


import { Link as RouterLink } from 'react-router-dom';
import { Toolbar, AppBar, Typography } from "@material-ui/core";

//import { ReactComponent as HappelSolutionsTransparent}  from '../../../Assets/photos/HappelSolutionsTransparent.svg'
import HappelSolutionsDrawerLogo from './HappelSolutionsDrawerLogo';


import useStyles from './styles'

function ListItemLink(props) {
  const { icon, primary, to, secondary, secondaryStyles } = props;
  
  const renderLink = React.useMemo(
    
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

 
  if (secondary &&  secondaryStyles) {
    console.log('Secondary: ', secondary);
    console.log('Secondary Styles: ', secondaryStyles);
    //const secondary_sx = secondary.props.children.props.sx;
    //console.log('SX Props: ', secondary_sx);
    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} 
            secondary={
                <Fragment>
                    <Typography style={{...secondaryStyles}}>
                      {secondary}
                    </Typography>
                </Fragment>
            }
         />
        </ListItem>
      </li>
    );
  }
//          { ((icon) && icon === 'contact') ? <ListItemIcon> <PermContactCalendarIcon/> </ListItemIcon> : null }

  else {
    return (
      <li style={{fontFamily: "BerkeleyMonoTrial-Regular"}}>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} style={{}}/>
      </ListItem>
    </li>
    )
  }
  
}


//          <ListItemText secondary={secondary} style={secondaryStyles} /**sx={secondary_sx}*/ /> : null }



/**
<ListItem button key={directoryObject.title}>
  <ListItemIcon> <MailIcon /></ListItemIcon>
  <ListItemText primary={directoryObject.title} />
</ListItem>
*/

/**
 * @menuInteractionRecorded = when touch/click registered on menu bar, turns true
 *    Used for closing the tooltip once the menu bar is touched, because it is now clear that the user knows what to do
 * @param {*} param0 
 * @returns 
 */
export default function FixedDrawerMenu( { containerDetailsAndDirectory }) {

    const classes = useStyles();
    const container_title = containerDetailsAndDirectory["title"];
    const container_emoji = containerDetailsAndDirectory["emoji"];
    const full_container_title = container_emoji + " " + container_title;
    //const full_container_title = {happelSolutionsLogo} + " " + container_title;
    
    const logoBurgerDom = document.getElementsByClassName("DrawerMenuLogo")[0];
    const [state, setState] = React.useState({ top: false });
    const [parsedDirectory, setParsedDirectory] = React.useState(null)
    const [logoRotated, setLogoRotated] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false)
    const [logoDomElement, setLogoDomElement] = React.useState(null)
    //const [showToolTip, setShowToolTip] = React.useState(false)
    const [showToolTip, setShowToolTip] = React.useState(localStorage.getItem("toolTipShown") == null)  // if null, it hasnt been shown
    const [menuInteractionRecorded, setMenuInteractionRecorded] = React.useState(false)
    const isMobile = (window.navigator.userAgent).includes('Mobile')



    const toggleDrawer = (anchor, open) => (event) => {

      setMenuInteractionRecorded(true) // Register interaction with menu bar
      
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }

        setMenuOpen(!menuOpen);
        setState({ ...state, [anchor]: open });
    };


    // Remove the current page's directory object from the list containing the directory objects
    const parseDirectoryList = ( directory, currentTitle ) => {
      var parsed_directory = [];
      for ( var i = 0; i <= directory.length - 1; i++ ) {
         if (directory[i].title !== currentTitle)  {
           parsed_directory.push(directory[i])  
      }}
      setParsedDirectory(parsed_directory)
    }

    
    const getLogoDomElement = () => {
      setLogoDomElement(document.getElementsByClassName("DrawerMenuLogo")[0])
    }


    const updateLogoPosition = ( menuOpen ) => {
        if ( menuOpen === true ) {
          if (logoBurgerDom !== null) {
            logoBurgerDom.id = "logoBurgerRotated";
            //alert(logoBurgerDom.id)
          }
        }

        if ( menuOpen === false ) {
          if ( logoBurgerDom !== null ) {
            try {
              if (logoBurgerDom.id === "logoBurgerRotated") {
                //alert('Yes')
                logoBurgerDom.id = "logoBurgerUndoRotate";
            
              }
            } catch {
              
            }
          }
        }
      }
      
    const presentToolTip = () => {

    

      if (showToolTip) {
        
        setTimeout(()=> {
          localStorage.setItem('toolTipShown', true)
          //setShowToolTip(false)
          setTimeout( () => {
            setShowToolTip(false)   
          }, 6000)
  
        }, 3000)
      }
      
    }

    useEffect(() => {
     
      if(window.location.pathname !== '/old') {
        if (parsedDirectory === null) parseDirectoryList( containerDetailsAndDirectory["directory"], containerDetailsAndDirectory["title"] )
      }

     // if ( localStorage.getItem('toolTipShown') === null) {
        // show tooltip function
        presentToolTip();
     // }

      updateLogoPosition(menuOpen);


      // if menuOpen == add rotate logo id
      // if menuOpen is false == remove rotate logo id and replace it with something else

      // set classname within svg component .jsx file, getel by class and use [0]
      
    })

    const list = (anchor) => (
        <div
          className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
          onClick={ () => { if (parsedDirectory !== null) toggleDrawer(anchor, false)}}
          onKeyDown={ () => { if (parsedDirectory !== null) toggleDrawer(anchor, false)}}
        >
          
            {
              (parsedDirectory !== null ? (
                <List style={{paddingTop: '50px', paddingBottom: '0'}}>
                  {parsedDirectory.map((directoryObject, index) => (
                    <>
                    <Divider/>
                      <ListItemLink 
                          to={directoryObject.path}
                          primary={directoryObject.title}
                          icon={directoryObject.icon}
                        />

                        </>
                  ))}

                </List>
              ) : null)
            }
            
         
          
          <Divider />
          
          
        </div>
    );

    /**
     * 
     * This was right below the divider vvvv 
    <List>
            
              <ListItemLink 
                 primary={'E Commerce'}
                 to={'/ecommerce/'} 
                 icon={<StorefrontIcon/>}
                 secondary={'A fully functional e-commerce example'}
                 secondaryStyles={
                   {fontFamily: "AvenirLTStd-Book",
                    color: "blue", 
                    lineHeight: "1.14",
                    fontSize: ".8rem",
                  }}
              />
                
             
             
          </List>
**/
// /className = { isMain ? classes.main_title : classes.secondary_title }
// {double_space_emoji.replace(/ /g, "\u00a0")}
//  {title}
    return (
        
        <div>
        
            {['top'].map((anchor) => (
            
            <React.Fragment key={anchor}>

                <AppBar
                    onClick={toggleDrawer(anchor, !state['top'])}
                    style = {{
                                borderTopLeftRadius: '0.5rem',
                                borderTopRightRadius: '0.5rem',
                                color: 'black',
                                height: '2.9rem',
                                zIndex: '5',
                    }} id={'fixedDrawerMenu'}>

                    <Tooltip id='menuTooltip' 
                      className={classes.NavigationTooltip}
                      styles={{
                        marginTop: '10px',
                
                        //boxShadow: theme.shadows[1],
                        fontSize: '11',
                      }}
                      title={(isMobile) ? "Touch here to open menu" : "Click here to open menu"}
                      placement="bottom-start"
                      TransitionComponent={Zoom}
                      open={showToolTip && !menuInteractionRecorded} 
                      arrow>
                      
                      <Toolbar  style={{ position: 'relative',  /**paddingBottom: '1vh',**/ }}>

                          <Container id={'sfixedDrawerMenu1'} style={{
                            marginLeft: 'unset',
                            //marginTop: '1vh',
                            display: 'flex',
                            maxWidth: 'unset',
                            height: '100%',
                            paddingLeft: '0px',
                            paddingRight: '0px'
                          }} >
                              
                              <HappelSolutionsDrawerLogo/>

                              <Typography variant="h6" className={classes.TitleText} style={{
                                display: 'flex',
                                fontWeight: 400,
                                letterSpacing: '-0.1px',
                                lineHeight: '2',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '1vh',
                                flexGrow: '2',
                                justifyContent: 'center',
                                fontFamily: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
                                userSelect: 'none',
                              }}>
                                {container_title}
                              </Typography>

                          </Container>    
                        
                      </Toolbar>

                  </Tooltip>

                </AppBar>
            
             
          
                <Drawer 
                    anchor={anchor} 
                    open={state[anchor]} 
                    onClose={toggleDrawer(anchor, false)}
                    style = {{ zIndex: '2'}}
                    >
                    {list(anchor)}
                </Drawer>

            </React.Fragment>
            ))}
        </div>
    )
}

//                       {full_container_title}



//<Container className={classes.HappelSolutionsLogoContainer}>
//                                <ListItemLink primary={'E Commerce'} to={'/ecommerce/'} icon={<InboxIcon/>}/>
