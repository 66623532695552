import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Button, Grid, Typography, Divider, TextField } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { commerce } from '../../../lib/commerce';
import useStyles from "./contactstyles";
import { TimeSlot } from '..';

//import FormInput from '../../scheduler/SchedulerForm/CustomTextInput';

import FormInput from './SimpleContactFormInput';

import { Box, Fade} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import DrawerTitleContainer from '../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer'



const styles = theme => ({

    ExpansionPanelMainContainer: {
        // height: '80vh',
        // width: '80vw',
        //top: 0,
        // left: 0,
        //position: 'fixed',
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    boxContent: {
        margin: '10px',
    },


    epDescription: {

    },

    epTitle: {

    },


    epH3: {
        marginTop: '2.9rem',

        marginBottom: '0.2rem',
        textTransform: 'uppercase',
        marginRight: '0.25rem',
        paddingLeft: '1rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '4.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epH4: {
        marginLeft: '1rem',
        
        marginTop: '0.6rem',
        marginBottom: '0.5rem',
        [theme.breakpoints.down(900)]: {
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem'
        },
    },

    epDescriptionIndent1: {
        marginTop: '2vh',
        marginLeft: '1rem',
        marginBottom: '2rem',

        paddingRight: '5px',
        textAlign: 'justify',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

    epDescriptionIndent2: {
        marginLeft: '2rem',
        
        marginBottom: '1.5rem',
        [theme.breakpoints.down(900)]: {
            marginRight: '.25rem',
            fontSize: '3.75vw',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },


    epListLeft: {
        float: 'left',
        marginRight: '20px',
        marginBottom: '2.5rem',
        listStyle: 'circle',
    },

    epListRight: {
        listStyle: 'circle',
    },

    sectionBackground: {
        ///background: '#e2e3ec',
        background: '#d3d3d36e',
        //margin: '-10px',
        //padding: '10px',
        marginTop: '0px',
        paddingBottom: '1px',
        borderRadius: '10px',
    },


    subsectionTitleAndBody: {
        background: '#f2f1f1',
        borderRadius: '2px',
        //borderRadius: '5px',
        boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
        padding: '3px',
    },


    whatCanWeDoForYouContainer: {
        display: 'inline-block'
    },

});





const SimpleContact = ( { directory} ) => {

    const [checkoutToken, setCheckoutToken] = useState(null);
    const [cart, setCart] = useState(null);
    const [products, setProducts] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [userFormData, setUserFormData] = useState({});
    const [selectionData, setSelectionData] = useState(null);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [clientCountry, setClientCountry] = useState('');
    const [clientRegion, setClientRegion] = useState('');
    const [checkoutTokenId, setCheckoutTokenId] = useState(null);
    const [selectedService, setSelectedService] = useState('');
    //const [checkoutToken, setCheckoutToken] = useState('')
    
    const [shippingCountries, setShippingCountries] = useState([]);
    const [shippingCountry, setShippingCountry] = useState('');
    const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
    const [shippingSubdivision, setShippingSubdivision] = useState('');
    const [shippingOptions, setShippingOptions] = useState([]);
    const [shippingOption, setShippingOption] = useState('');



    const classes = useStyles();
    const navigate = useNavigate();
    const methods = useForm();


    const fetchShippingCountries = async (checkoutToken) => {
        const { countries } = await commerce.services.localeListShippingCountries(checkoutToken.id);
        console.log('Countries: ', countries);
        setShippingCountries(countries);
        setShippingCountry(Object.keys(countries)[0]);
      };

    const fetchSubdivisions = async (countryCode) => {
        const { subdivisions } = await commerce.services.localeListSubdivisions(countryCode);
        setShippingSubdivisions(subdivisions);
        setShippingSubdivision(Object.keys(subdivisions)[0]);
    };


   // useEffect (() => {
     //   fetchShippingCountries(checkoutToken);
    //}, []); // with input here, the function continues to repeat itself

    useEffect(() => {
        if (shippingCountry) fetchSubdivisions(shippingCountry);
        console.log('Shipping country: ', shippingCountry);
    }, [shippingCountry]);

    useEffect(() => {
        //refreshCart()
        async function fetchCart (retrieved_products, cb)  {
          console.log('Fetch cart')
          const cart = await commerce.cart.retrieve();
          cb(cart)
        }
        async function fetchProducts (cb)  {
          console.log('Fetch prod')
          const prod = await commerce.products.list();
          cb(prod)
        }
    
        async function addRandomProductToCart (cb) {
          console.log('Adding prod to cart')
          const item = await commerce.cart.add("prod_p6dP5g3k85n7kA", 1);
          cb(item);
        }
    
        async function generateToken (retrieved_products, retrieved_cart, cb) {
          try {
            console.log('Generate token')
            console.log(retrieved_cart)
            console.log(retrieved_products)
            const token = await commerce.checkout.generateToken(retrieved_cart.id, { type: 'cart' });
            console.log('Token: ', token);
            cb(token);
          } catch (err) {
            cb(err) //console.log(err);
          }
        };
        
    
        async function performFuncts () {
          await fetchProducts( async (retrieved_products) => {
            await fetchCart ( retrieved_products, async (retrieved_cart) => {
              await addRandomProductToCart ( async (retrieved_item) => {
                await generateToken (retrieved_products, retrieved_cart, async (token) => {
                  setCart(retrieved_cart)
                  setProducts(retrieved_products)
                  setCheckoutToken(token)
                  await fetchShippingCountries(token);
                  //setShippingSubdivisions(await fetchSubdivisions("US"));
                })
              })
            })
          });
        }
        
        performFuncts();
    
      }, []);


      const onSubmit = () => {

      }


    
    const container_details = {
        title: 'Contact',
        emoji: '💬',
    }

    const {handleSubmit, onKeyDown, register, watch, formState: { errors, dirtyFields }} = useForm({ mode: "onSubmit"});

    const getContent = () => {
        return (
            <Box id="cont" style={{height: "100vh"}}>

                
                    <Box id="inner-cont" style={{ height: "50vh", marginLeft: "auto", marginRight: "auto", marginTop: "5rem", maxWidth: "940px", backgroundColor: "#f2f1f1", boxShadow: "1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)", borderRadius: "2px"}}> 
                        
                        <Box id="form-cont">
                        
                        <Typography variant="h6" gutterBottom> General Info </Typography>
                        
                        <FormProvider {...methods}>
                            
                            <form onSubmit={methods.handleSubmit((data) => onSubmit({ ...data, selectedService, shippingCountry, clientRegion}))}>
                            
                            <Grid container xs={12} spacing={3}>
                            
                                <TextField style={{display: "none"}}{...register('access_key'
                                )}
                                inputProps={{  style: {   display: 'none' }, }}
                                name="access_key"
                                value="6d11c08a-48bc-43a6-a2eb-c381c4e615aa"
                                type="hidden"
                                className="input" 
                            />  
                                    
                                <FormInput {...register('fullname')} required name="fullName" label="Full name" />
                                
                                <FormInput {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Please enter a valid email',
                                            },
                                })} required name="email" label="Email" /> 
                                
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Shipping Country</InputLabel>
                                    <Select value={shippingCountry} fullWidth onChange={(e) => setClientCountry(e.target.value)}>
                                        {Object.entries(shippingCountries).map(([code, name]) => ({ id: code, label: name })).map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel> {shippingCountry === 'US' ? 'State:' : 'Region:'  } </InputLabel>
                                    <Select value={clientRegion} fullWidth onChange={(e) => setClientRegion(e.target.value)}>
                                        {Object.entries(shippingSubdivisions).map(([code, name]) => ({ id: code, label: name })).map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                
                                <FormInput register={register} required name="city" label="City" />

                            </Grid>

                            <Divider style= {{ marginTop: '7%', marginBottom: '5%', marginLeft: '-2%', marginRight: '-2%', borderTopStyle: 'outset'}} />

                            <Typography variant="h6" gutterBottom>Info About Inquiry</Typography>
                            <Grid container xs={12} spacing={3}>
                                <FormInput register={register} required name="bizName" label="Business name" />
                                <Grid item xs={12} sm={6}>
                                <InputLabel> Product / Service we offer </InputLabel>
                                <Select value={selectedService} className={classes.selectEmpty} fullWidth required onChange={(e) => setSelectedService(e.target.value)}>
                                <MenuItem value="" disabled> Product / Service we offer </MenuItem>
                                    <MenuItem key={0} value={'Website'}> Websites </MenuItem>
                                    <MenuItem key={1} value={'Software'}> Software </MenuItem>
                                    <MenuItem key={2} value={'Consultation'}> Consultation Services </MenuItem>
                                    <MenuItem key={2} value={'TechSupport'}> On-call Tech Support </MenuItem>
                                </Select>
                                </Grid>
                                <TextField {...register('details', {
                                    required: "Details are required", 
                                    validate: (value) => { 
                                        
                                        console.log("More details needed");
                                        if (value.length > 40 ) console.log("is 6")
                                        return ( value.length > 40 || "Please enter a more details.")
                                    
                                    },
                                        //validate: (value) => {},
                                    })} 
                                        inputProps={{    style: { /*height: '10px'*/ }, }}
                                        label="Details"
                                        name="details"
                                        placeholder="Enter details here"
                                        type="textarea"
                                        multiline
                                        rows={4}
                                        autoComplete="off"
                                        required
                                        className="input12"
                                        
                                        /> 
                            </Grid>

                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button component={Link} variant="outlined" to="/">Cancel</Button>
                                <Button type="submit" variant="contained" color="primary">Next</Button>
                            </div>
                            </form>
                        </FormProvider>

                        </Box>

                    </Box>  
               

            </Box> 
        )


    }

    const handleChange = () => {

    }

    const content = getContent();
  
    return (
        <> 
        <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}
    
            style={{ background: '#353535', }}
            {... {
                        classes: classes,
                        directory: directory,
                        details: container_details,
                        content: content,
                        onchange: {handleChange}
            }}
            /> 
        </>
      )
}

export default withStyles(styles, { withTheme: false }) (SimpleContact);