import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export default makeStyles((theme) => ({

    QRContainer: {
        //height: '25vh',
        //width: '50vw',
        //marginLeft: 'auto',
        //marginRight: 'auto',
        //width: '100%',
        //height: '100%'
        height: '100vh',
        display: 'table',
    },

    ContentContainer: {

    },

    WelcomeText: {
        textAlign: 'center',
        fontFamily: 'AvenirLTStd-Book',
        paddingTop: '25vh'
    }, 

    EyesEmojiText: {
        textAlign: 'center',
        paddingTop: '30vh'
    },
    popoutOne:{
        fontSize: '2.5rem',
        //position: 'absolute',
        //display: 'none',
        display: 'table-cell',
        verticalAlign: 'middle',
        minWidth: '13rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%', 
        textAlign: 'center',
    },
    popoutTwo: {
        fontSize: '2.0rem',
        //position: 'absolute',
        //display: 'block',
        //display: 'table-cell',
        display: 'none',
        //visibility: 'hidden',
        verticalAlign: 'middle',
        minWidth: '13rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%', 
        textAlign: 'center',
    },
    popoutThree: {
        fontSize: '2.0rem',
        //position: 'absolute',
        //display: 'block',
        //display: 'table-cell',
        display: 'none',
        //visibility: 'hidden',
        verticalAlign: 'middle',
        minWidth: '13rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%', 
        textAlign: 'center',
    },

    contactInformingTitle: {
        fontSize: '1.5em',
        //fontFamily: 'Raleway, sans-serif',
        fontFamily: 'AvenirLTStd-Book',
        fontWeight: '900',
        padding: '70px',
        fontSize: ' xx-large',
        //position: 'absolute',
        //display: 'block',
        //display: 'table-cell',
        display: 'none',
        //visibility: 'hidden',
        verticalAlign: 'middle',
        minWidth: '13rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%', 
        textAlign: 'center',
        fontVariantCaps: 'petite-caps',
    }, 

    contactInformingParagraph: {
        //fontSize: '1rem',
        //fontFamily: 'Raleway, sans-serif',
        //position: 'absolute',
        //display: 'block',
        //display: 'table-cell',
        display: 'none',
        //visibility: 'hidden',
        verticalAlign: 'middle',
        minWidth: '13rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%', 
        textAlign: 'center',
        wordSpacing: '2px',
        //fontWeight: 'bold',
        font: 'small-caption',
        
    },
    eyes: {
        position: 'absolute',
        fontSize: '3rem',
        padding: '0',
        margin: '0',
        left: '20%',
        top: '30%',
    }

}));