import React, {Component} from "react";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import ServicesExpansionPanel from "./ServicesExpansionPanel";
import withStyles from "@material-ui/core/styles/withStyles";



const styles = theme => ({

    OnClickContainer: {
        //width: '80vw',
        // height: '80vh',
    },



    CardComponentClassForStyling: {

        overflow: 'initial !important',
        fontFamily: "'Titillium Web',sans-serif'",
        marginTop: '2rem',
        //width: '12'
        //minWidth: '12rem',
        background: 'linear-gradient(to left, rgb(255, 255, 255), rgb(240, 232, 236)) !important',
        // width 6 rem for mobile

        // For testing *******
        top: 0,
        position: 'relative',
        // *******************


        [theme.breakpoints.down(900)]: {
            //maxWidth: '10rem',
            //width: '9rem',

            minWidth: '9rem',
            maxWidth: '77%',
            height: '200px',
            //marginRight: '-0.5vw',
            //marginLeft: '6vw',
            margin: '6% 4% 6% 11%',
        },

        [theme.breakpoints.up(901)]: {
            margin: '6%',
            width: '10rem',
            minWidth: '30%',
            height: '200px',
            // marginRight: '-9.5vw',
            //marginLeft: '15vw',
            // Removed b/c being pushed off screen
        },

    },





    servicesCardActionArea: {
        height: '100%',
    },

    ExampleCardClassDiv: {
        position: 'relative',
        // display: 'flex',
    },

    servicesTypographyComponent: {

    },

    servicesCardContentDiv: {
        padding: '0px !important',
        position: 'absolute',
        top: '0',
        width: '100%',
    },

    servicesCardTitle: {
        background: '#ebebeb',
        margin: 0,
        padding: '11px 10px 5px',
        //width: '100%',
        height: '100%',
        textAlign: 'center',
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        borderBottomStyle: 'groove',
        borderBottomWidth: '1px',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
    },

    servicesCardSummary: {
        textAlign: "left",
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    },

    servicesLearnMoreButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        position: 'inherit',
        //marginTop: '33%', // could use some adjustment
    },

    main_title: {
        backgroundColor: '#da8d8d !important',
    },

    secondary_title: {
        backgroundColor: "#fff !important",
    },


    title_toolbar: {

    },
});




class CustomCard extends Component {

    constructor(props) {
        super(props);
        this.state = {checked: false};
        this.setChecked = () => {
            console.log('Setting Checked: ', !this.state.checked);
            this.setState({checked:!this.state.checked})
        };
    }


    componentWillUnmount() { console.log('ExampleCardClass Unmounting!'); }

    render () {

        const {classes} = this.props;
        const {services_info} = this.props;
        const {bg_color} = this.props;





        const changeSideColumnStyle = (checked) => {
            if (checked === true) {
                console.log('Changing sideColumn style to true');
                document.querySelectorAll('div[class^="ServicesOffered-sideColumn"]')
                    [0].setAttribute('id', 'side-column-after');

                document.getElementById('on-click-container-before').style.height = 'oops';
                //document.querySelectorAll('div[class^="Services-OnClickContainer"]')
                //    [0].setAttribute('id', 'on-click-container-after');



            } else if (checked === false) {
                console.log('Changing sideColumn style to false');
                document.querySelectorAll('div[class^="ServicesOffered-sideColumn"]')
                    [0].setAttribute('id', 'side-column-before');
                // document.querySelectorAll('div[class^="Services-OnClickContainer"]')
                //     [0].setAttribute('id', 'on-click-container-before');
            } else {
                console.log('Error')
            }
        };



        /*
          if (card_el.hasChildNodes()) {
                            let children = card_el.childNodes;
                            for (let i = 0; i < children.length; i++) {
                                //alert(children[i].innerHTML);
                                let child = children[i];
                                alert('Child Length: '+ child.childNodes.length);
                                for (let r = 0; r < child.childNodes.length; r++) {
                                    let y = child.childNodes[r];
                                    alert(y.innerHTML);
                                }

                            }
                        }

         */
        const changeCardClassStyle = (index, checked) => {
            //alert('index: ' + index + ' checked: ' + checked);

            let card_elements = document.querySelectorAll('div[class^="ServicesOffered-ExampleCardClassDiv"]');

            for (let i = 0; i <= card_elements.length - 1; i++) {
                let card_el = card_elements[i];
                //alert('Card El ID: ' + card_el.id + ' Index: ' + index);

                /** Identifies the card that corresponds with the index of the selected card **/
                /** triple equal sign does not work **/

                if (card_el.id == index) {

                    if (checked) {
                        card_el.style.position = 'fixed';
                        card_el.style.zIndex = '1';

                        let services_body_container = document.getElementById('services-info-container-unselected-' + index);
                        services_body_container.style.marginLeft =  '0';

                    } else {
                        card_el.style.position = 'revert';
                        card_el.style.zIndex = '0';
                    }

                } else { /* alert('False'); */ }
            }
        };

        const handleChange = (r) => {
            //alert('Custom Card, Handle Change: ' + r);
            console.log('Card - Handle Change:', services_info.index);
            //let expPanel = GetExpansionPanel(services_info, classes);
            console.log('Setting Checked - !this.state.checked:', !this.state.checked);
            this.setChecked();
            console.log('Setting Checked After - !this.state.checked:', !this.state.checked);

            changeSideColumnStyle(!this.state.checked);
            changeCardClassStyle(services_info.index, !this.state.checked);


            //this.props.onChange(services_info.index); old
            //this.props.onChange(expPanel); old
            this.props.onChange(services_info.index);
            // this.componentWillUnmount()
        };



        //console.log('ExampleCarClass - services info: ', services_info);
       //console.log('ExampleCarClass - Props: ', this.props);
        //console.log('ExampleCarClass - Index: ', services_info.index);

        //  STYLE INLINE IF STATEMENT WHATTT SO COOL!!
        return  (

            <div className={classes.ExampleCardClassDiv} id={services_info.index}>
                <Fade in={!this.state.checked}>

                    <Card
                        onClick = { () => {
                                handleChange(services_info.index)
                            }
                        }

                        className={classes.CardComponentClassForStyling}

                        style =
                            {
                                {
                                    position: !this.state.checked ? 'relative': 'absolute'
                                    //width: '25rem',
                                    //minWidth: '45%',
                                    //background: 'rgba(230, 229, 230, 1) !important'
                                    //background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236)) !important',
                                }
                            }
                    >


                        <CardActionArea className={classes.servicesCardActionArea}>
                            <CardContent className={classes.servicesCardContentDiv}>
                                <Typography className={classes.servicesTypographyComponent} component="div">

                                    <Box className={classes.servicesCardTitle}  m={1}>
                                        {services_info.title}
                                    </Box>

                                    <Box className={classes.servicesCardSummary} m={1}>
                                        {services_info.summary}
                                    </Box>

                                    <Button className={classes.servicesLearnMoreButton} size="small" color="primary">
                                        Learn More
                                    </Button>

                                </Typography>
                            </CardContent>
                        </CardActionArea>


                    </Card>
                </Fade>

                <Slide direction={'up'} in={this.state.checked}>
                    <Paper elevation={4} className={classes.OnClickContainer}>
                        <div className="home-body-containert" /*id={"home-body-container-before"}*/>

                            <ServicesExpansionPanel

                                onChange={handleChange}

                                { ...
                                    {
                                        services_info: services_info,
                                        classes: classes,
                                        isMain: services_info.isMain ? true : false,
                                    }
                                }

                            />
                        </div>
                    </Paper>
                </Slide>

            </div>

        )

    }

}


export default withStyles(styles, { withTheme: false })(CustomCard);
