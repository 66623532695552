import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = theme => ({
    /**
     MainContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 'none',
        padding: 0,
        margin: 0,
        align: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {

            //backgroundColor: theme.palette.primary.main,
        },

        [theme.breakpoints.up('md')]: {
            //  backgroundColor: theme.palette.secondary.main,
        },

        [theme.breakpoints.up('lg')]: {
            //  backgroundColor: green[500],
        },
    },
     InfoPageTitle: {
        marginTop: '5rem',
        position: 'relative',
        textAlign: 'center',
        marginBottom: '3rem',
    },

     InfoPageTitleRowOne: {
        fontSize: '2.65rem',
        paddingTop: '4.8%',
        letterSpacing: '.45rem',
        wordSpacing: '0.5rem',
        transformText: 'uppercase',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        position: 'relative',
        textAlign: 'center',
    },
     InfoPageTitleRowTwo: {
        fontSize: '1.35rem',
        paddingTop: '4.8%',
        letterSpacing: '.45rem',
        wordSpacing: '0.5rem',
        transformText: 'uppercase',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        position: 'relative',
        textAlign: 'center',
    },
     root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
     header: {

        position: 'relative',
        backgroundColor: 'black',
        width: '1920px',
        height: '1080px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

     img: {
        width: '100%',
        height: '100%',
        margin:'auto',
        justifyContent: 'left',

    },
     headerImageContainer: {
        height: '1',
        width: '1'
    },
     homeH1: {
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        textAlign: 'center',
    },
     opaqueListHeader: {
        backgroundColor: '#22292554',
    },
     homepageBody: {
        marginTop: '0px',
        marginBottom: '5rem',
    },
     infoContainer: {
        //backgroundColor: 'rgba(255,255,255,.8)',
        borderRadius: '4px',
        flexGrow: 2,
        width: '100%',
        fontSize: '16px',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        boxShadow: '2px 2px 3px #aaaaaa',
        maxWidth: '1480px',
        background: '#e2e8ec ', //fallback for old browsers
        background: '-webkit-linear-gradient(to right, #F2E8E9, #e2e8ec )',  //Chrome 10-25, Safari 5.1-6
        background: 'linear-gradient(to right,  #F2E8E9, #e2e8ec )' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

//marginBottom: '2%',
    },
     pageHeaderTextContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1480px',
        flexGrow: 2,
        width: '100%',
        padding: 0,
        //marginTop:'4%',
        //marginBottom: '0.5rem',
    },
     pageHeaderText: {
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textAlign: 'center',
        // textShadow: '1px 1px 0px #aaaaaa',
    },
     infoSectionHeader: {
        paddingTop: '1%',
        paddingBottom: '0%',
        marginBottom: '0',
        fontWeight: '500',

    },

     infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
     infoHeaderDivider: {
        marginBottom: '0.5rem',
    },
     infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },
     infoSectionBodyFinal: {
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
     insetInfoDivider: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
     SubsectionHeaderFirst: {
        fontWeight: 'bold',
        color: '#1d1d1d',
        paddingTop: '1rem',
    },
     SubsectionHeader: {
        fontWeight: 'bold',
        color: '#1d1d1d',
    },

     IntroContainer: {
        paddingLeft: '30px',
        paddingRight: '18px',
        //background: '#e2e8ec', //fallback for old browsers
       // background: '-webkit-linear-gradient(to right, #ece6e2, #e2e8ec )',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #ece6e2, #e2e8ec )' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        background: '#ece2e3', //fallback for old browsers
        background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },
     **/

    DescriptionContainer: {
        paddingLeft: '30px !important',
        paddingRight: '18px',
        marginTop: '3rem',
        background: '#ece2e3',
        background: '-webkit-linear-gradient(to right, #f5f5f5, #ffffff)',
        background: 'linear-gradient(to right, #f5f5f5, #ffffff)',
    },


    initial: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    description: {
        fontWeight: '100',
        fontSize: '2rem',
    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },

    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        wordSpacing: '.3rem'
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },

    SubsectionHeaderFirst: {
        fontWeight: 'bold',
        color: '#1d1d1d',
        paddingTop: '1rem',
    },

    SubsectionHeader: {
        fontWeight: 'bold',
        color: '#1d1d1d',
    },


    PopupGalleryContainer: {
        position: 'relative',
        display: 'flex',
        overflowX: 'scroll',
    },


    homepageBody: {

    },


    TestingImage: {
        height:'17rem',
        width: '21rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center center',
    },

    main_title: {
        backgroundColor: '#da8d8d !important',
    },

    secondary_title: {
        backgroundColor: "#fff !important",
    },

    IntroContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    FirstBodyParagraph: {
        marginBottom: '0 !important',
        padding: '10px',
        marginTop: '0',
    },


    PricingTitle1: {
        color: '#1d1d1d',
        fontWeight: 'bold',
        paddingTop: '1rem',
        paddingLeft: '10px',
        paddingRight: '10px',
    },

    GreenItalics: {
        color: '#498629',
        fontStyle: 'italic',
      display: 'inline',
    },

    Bold: {
        fontWeight: 'bold',
        display: 'inline',
    },


});


export default useStyles
    