import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = theme => ({
    IntroContainer: {
        paddingLeft: '30px',
        paddingRight: '18px',
        //background: '#e2e8ec', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #ece6e2, #e2e8ec )',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #ece6e2, #e2e8ec )' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        background: '#f4f4f4', //fallback for old browsers
        //background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
      //  background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+

    },


    DescriptionContainer: {
        paddingLeft: '30px !important',
        paddingRight: '18px',
        marginTop: '3rem',
        background: '#f4f4f4',
       // background: '-webkit-linear-gradient(to right, #f5f5f5, #ffffff)',
     //   background: 'linear-gradient(to right, #f5f5f5, #ffffff)',
    },


    initial: {
        fontWeight: '600',
        fontSize: '10rem',
    },

    description: {
        fontWeight: '100',
        fontSize: '2rem',
    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },

    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        wordSpacing: '.3rem'
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },

    SubsectionHeaderFirst: {
        fontWeight: 'bold',
        color: '#1d1d1d',
        paddingTop: '1rem',
    },

    SubsectionHeader: {
        fontWeight: 'bold',
        color: '#1d1d1d',
    },


    PopupGalleryContainer: {
        position: 'relative',
        display: 'flex',
        overflowX: 'scroll',
    },


    homepageBody: {

    },


    TestingImage: {
        height:'17rem',
        width: '21rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center center',
    },
});


export default useStyles