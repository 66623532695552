import React from 'react'
import { Box } from '@mui/system';
import { Paper, Avatar, Typography, Divider } from '@mui/material';

const Footer = () => {
  return (
    <Box style={{backgroundColor: "rgb(242, 241, 241)", margin: '0 auto', padding: '5rem 1rem 0.5rem 1rem ', textAlign: 'center'}}>
        <Divider style={{marginBottom:'1rem', backgroundColor: "rgb(0 0 0 / 2%)"}}/>
        <Typography style={{fontSize: '1.3vh'}}> © 2023 by Happel Solutions </Typography>
        <Typography style={{fontSize: '1.3vh'}}> Huntington, NY 11743 </Typography>
        <Typography style={{fontSize: '1.3vh'}}> contact@happel.solutions</Typography>
        
    </Box>
  )
}


export default Footer;