import React, {Component, Fragment} from 'react';
import { SvgIcon, Button, Snackbar, Link, Typography } from '@material-ui/core';
//import { TodayIcon, CameraAltIcon, PersonIcon, MailOutlineIcon} from '@material-ui/icons';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TodayIcon from '@material-ui/icons/Today';

//import { HappelSolutionsTransparent } from '../../../Assets/photos/'
import { Alert } from '@material-ui/lab';
import withStyles from "@material-ui/core/styles/withStyles";

import TitledContainer from '../../BuildingComponents/TitledContainer/TitledContainer';
import DrawerTitleContainer from '../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';
import copy from 'copy-to-clipboard';

import useStyles from './styles';

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize ={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}


class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            renderMsg: false,
            alertOpen: false,
            snackbarText: 'nothing',
        };
        this.myRef = React.createRef();
        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }


    render () {

        const { classes } = this.props;
        const { directory } = this.props;





        const handleChange = () => {
            // Set expansionPanel state, so if fading out main component, replace with expansionPanel
            //alert('Handle Change');
        };

        const handleClick = (reason) => {


            if (reason === 'instagram') {
                this.setState({...{
                        open:true,
                        snackbarText: 'Instagram'
                    }});
            } else if (reason === 'facebook') {
                this.setState({...{
                        open:true,
                        snackbarText: 'Facebook'
                    }});
            } else  if (reason === 'email') {
                this.setState({
                    ...{
                        open: true,
                        snackbarText: 'Email copied to clipboard!'
                    }
                });
            }
        };

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            this.setState({...{open:false}});
        };


            const getContent = () => {
            return (

                <Fragment>

                    <div className={classes.sectionBackground}>

                        <div className={classes.boxContent}>

                            <div className={classes.subsectionTitleAndBody}>

                                <div className={classes.sectionBackgroundWhite}>

                                    <div className={classes.boxContent}
                                         style={{
                                             boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
                                         }}>

                                        <div className={classes.epDescriptionIndent0}
                                             style={{
                                                 textAlign: 'center',
                                                 marginBottom: '0.5rem',
                                                 textTransform: 'capitalize',
                                                 fontWeight: '700'
                                            }}
                                        >
                                            Online and in-person consultations are free!

                                        </div>
                                    </div>

                                </div>



                                <div className={classes.whatCanWeDoForYouContainer} style={
                                    {
                                        display: 'inline-block',
                                        marginBottom: '0.5rem',
                                        marginTop: '1rem',
                                        width: '100%',
                                        backgroundColor: '#e2e3ec',
                                    }
                                }>

                                    

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                            margin: 'auto'
                                        }}>
                                        <Button
                                            style={{
                                                flexGrow: '1'
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<CameraAltIcon style={{height: '0.8em', width: '0.8em'}}/>}
                                            onClick= { () => {
                                                handleClick('instagram');
                                            }}
                                        >
                                            Instagram
                                        </Button>

                                        <Button
                                            style={{
                                                flexGrow: '1'
                                            }}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<PersonIcon style={{height: '0.8em', width: '0.8em'}}/>}
                                            onClick= { () => {
                                                handleClick('facebook');
                                            }}
                                        >
                                            Facebook
                                        </Button>
                                        <Button
                                            style={{
                                                flexGrow: '1'
                                            }}
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<MailOutlineIcon style={{height: '0.8em', width: '0.8em'}}/>}
                                            onClick= { () => {
                                                copy('admin@happelsolutions.com');
                                                //navigator.clipboard.writeText('happelsolutions@gmail.com');
                                                handleClick('email');
                                            }}
                                        >
                                            Email
                                        </Button>

                                    </div>


                                    {window.innerWidth >= 901 ? (
                                        <Typography style={{width:'100%', left: '50%', position: 'relative', fontWeight: 500}}> or </Typography>
                                    ):null}
                                    
                                    
                                    
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        margin: 'auto'
                                    }}>
                                        <Button
                                            style={{
                                                flexGrow: '1',
                                                //backgroundColor:"#00e676",
                                                backgroundColor:"rgb(46 169 109)",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<TodayIcon style={{height: '0.8em', width: '0.8em'}}/>}
                                            onClick={() => window.location ="/scheduler/"}
                                        >
                                            Schedule a Meeting
                                        </Button>
                                    </div>


                                    <Snackbar open={this.state.open} autoHideDuration={6000} onClose={handleClose}>
                                            { this.state.snackbarText === 'Facebook' || this.state.snackbarText === 'Instagram' ? (
                                                <Alert onClose={handleClose} severity="success">
                                                    Navigate to <ArrowRightIcon style={{margin: '-7px'}}/>

                                                    {
                                                        this.state.snackbarText === 'Facebook' ? (
                                                            <Link href="https://www.facebook.com/HappelSolutions/">
                                                                Facebook
                                                            </Link>

                                                        ): null

                                                    }

                                                    {
                                                        this.state.snackbarText === 'Instagram' ? (
                                                            <Link href="https://www.instagram.com/happelsolutions/">
                                                                Instagram
                                                            </Link>

                                                        ): null

                                                    }

                                                </Alert>
                                            ):
                                                <Alert onClose={handleClose} severity="success">
                                                    Email copied to clipboard!
                                                </Alert>

                                            }




                                    </Snackbar>


                                </div>
                            </div>

                        </div>
                    </div>

                   

                </Fragment>




            )
        };

        const content = getContent();

        const container_details = {
            title: 'Contact',
            emoji: '💬',
        };


        return (







            <DrawerTitleContainer id={'ContactContainer'} className={classes.IntroContainer}
                             {
                                 ...
                                     {
                                         classes: classes,
                                         directory: directory,
                                         details: container_details,
                                         content: content,
                                         onchange: {handleChange}
                                     }
                             }
            />








        );
    }


    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}
export default withStyles(useStyles, { withTheme: false })(Contact);

/**
<Button
    variant="contained"
    color="secondary"
    size="small"
    className={classes.button}
    startIcon={<CameraAltIcon />}
    onClick= { () => {
        handleClick('instagram');
    }}
>
    Instagram
</Button>
**/

/**  Paper and Image Component*/
/*
      <Paper elevation={2}>
                    <img className={classes.TestingImage} src={'../../laptop.jpg'}/>
                </Paper>
 */

//<p className={classes.SubsectionHeaderFirst}> 💬 About Us </p>



/**
 <div className={classes.epDescriptionIndent1}>
 <h4 className={classes.epH4}
 style={{marginLeft:'0rem'}}>Contact Options </h4>
 <div className={classes.epListLeft}
 style={{
                                             marginLeft: '1rem',
                                             listStyle: 'circle'

                                         }}>
 <li>Instagram: @HappelSolutions</li>
 <li>Facebook Page: Happel Solutions</li>
 <li onClick={() => {navigator.clipboard.writeText('Happel')}}
 >Email</li>
 </div>
 </div>

/**
 <Divider className={classes.infoSectionEndDivider}/>
 <p className={classes.SubsectionHeader}> Our Location </p>
 <p className={classes.infoSectionBody} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 </p>
 <Divider className={classes.infoSectionEndDivider}/>

 <p className={classes.SubsectionHeader}> Learn More </p>
 <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tempor incididunt ut labore et dolore magna
 aliqua <Link href="/#">Lorem Ipsum</Link> page.
 </p>
 **/