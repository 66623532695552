import React, {Component, Fragment} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TitledContainer from '../../BuildingComponents/TitledContainer/TitledContainer';
import useStyles from './styles';



function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize ={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}


class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {renderMsg: false};
        this.myRef = React.createRef();
        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }


    render () {

        const { classes } = this.props;



        const handleChange = () => {
            // Set expansionPanel state, so if fading out main component, replace with expansionPanel
            alert('Handle Change');
        };

        const getContent = () => {
            return (

                <Fragment>

                    <p className={classes.PricingTitle1}> Industry Quality At Half The Price </p>

                    <p className={classes.FirstBodyParagraph} id={'infoSectionBodyStart'}
                       style={{
                           fontSize: '1.0rem',
                           wordSpacing: '0.19rem',
                       }}
                    >
                        Getting bespoke websites can often run consumers upwards of <p className={classes.GreenItalics}> thousands </p> of dollars.
                        <br/>

                    </p>

                    <p className={classes.FirstBodyParagraph} id={'infoSectionBodyStart'}
                       style={{
                           fontSize: '1.0rem',
                           wordSpacing: '0.19rem',
                       }}
                    >
                        <p className={classes.Bold}> Happel Solutions </p> provides industry-grade quality at an unparalleled price point.

                        <br/>

                    </p>

                </Fragment>




            )
        };

        const content = getContent();

        const container_details = {
            title: 'Pricing',
            emoji: '💬',
        };


        return (







            <TitledContainer id={'AboutContainer'} className={classes.IntroContainer}
                             {
                                 ...
                                     {
                                         classes: classes,
                                         details: container_details,
                                         content: content,
                                         onchange: {handleChange}
                                     }
                             }
            />








        );
    }


    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}
export default withStyles(useStyles, { withTheme: false })(Pricing);

/**  Paper and Image Component*/
/*
      <Paper elevation={2}>
                    <img className={classes.TestingImage} src={'../../laptop.jpg'}/>
                </Paper>
 */

//<p className={classes.SubsectionHeaderFirst}> 💬 About Us </p>

/**
 <Divider className={classes.infoSectionEndDivider}/>
 <p className={classes.SubsectionHeader}> Our Location </p>
 <p className={classes.infoSectionBody} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 </p>
 <Divider className={classes.infoSectionEndDivider}/>

 <p className={classes.SubsectionHeader}> Learn More </p>
 <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tempor incididunt ut labore et dolore magna
 aliqua <Link href="/#">Lorem Ipsum</Link> page.
 </p>
 **/