import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '450px',
      paddingBottom: '0%',
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: '950px',
    },

  },
  media: {
    height: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0%',
      paddingLeft: '8px',
      paddingTop: '88.25%', // 16:9
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '76.25%', // 16:9
    },
  },
  

  cardContentRoot: {

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0%',
      paddingLeft: '8px'
    },
    [theme.breakpoints.up('lg')]: {

    },
  },
  
  cardActions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0%',
    },
    [theme.breakpoints.up('lg')]: {

    },
  },

  productPrice: {
    flexGrow: 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1em'
    }, 
  },

  addToCartButton: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0, 
      padding: 0
    },
    [theme.breakpoints.up('lg')]: {

    },
  },
  
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  
  
  
  
  productName: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      fontWeight: '400',
      marginBottom: '0.2em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2em',
      fontWeight: '400'
      
    },
    fontFamily: 'AvenirLTStd-Book',
  },
  productDescription: {

    fontFamily: 'AvenirLTStd-Book',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whitespace: 'nowrap'
  },
  
  

}));
