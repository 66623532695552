import React, {Fragment, useState} from "react";
import ReactDOM from "react-dom";

import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { red, blue, green } from "@material-ui/core/colors";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";

import withStyles from "@material-ui/core/styles/withStyles";


const styles = {

};



const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile }) => {
    return (
        <div>
            {/* <Button onClick={() => setHandleOpen({ open: true })}>Open carousel</Button> */}
            <AutoRotatingCarousel
                label="Get started"
                open={handleOpen.open}
                onClose={() => setHandleOpen({ open: false })}
                onStart={() => setHandleOpen({ open: false })}
                autoplay={false}
                mobile={isMobile}
                style={{ position: "absolute", height: '350', width: '450px' }}
                className={'AutoRotating'}
            >
                <Slide
                    media={
                        <img src="https://i.pinimg.com/736x/06/d1/5f/06d15fc8a69e2ab67143e5357a4184d7.jpg" />
                    }
                    mediaBackgroundStyle={{ backgroundColor: red[400] }}
                    style={{ backgroundColor: red[600] }}
                    title="Max McGill Productions"
                    subtitle="Just using this will blow your mind."
                />
                <Slide
                    media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png" />
                    }
                    mediaBackgroundStyle={{ backgroundColor: blue[400] }}
                    style={{ backgroundColor: blue[600] }}
                    title="Ever wanted to be popular?"
                    subtitle="Well just mix two colors and your are good to go!"
                />
                <Slide
                    media={
                        <img src="http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png" />
                    }
                    mediaBackgroundStyle={{ backgroundColor: green[400] }}
                    style={{ backgroundColor: green[600] }}
                    title="May the force be with you"
                    subtitle="The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe."
                />
            </AutoRotatingCarousel>
        </div>
    );
};

// Local Storage -- homeHeaderPreviouslyShown
function AutoRotatingExample() {

    const [carouselOpen, setHandleOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false );
    const handleClick = (s) => {
        setHandleOpen({ open: true });
    };
    const matches = useMediaQuery("(max-width:600px)");


    return (
        <Fragment>
            <Button onClick={handleClick}>Open carousel</Button>
            <AutoRotatingCarouselModal
                isMobile={matches}
                handleOpen={carouselOpen}
                setHandleOpen={setHandleOpen}
            />
        </Fragment>
        );
    }




export default withStyles(styles, { withTheme: false })(AutoRotatingExample);


