import React, { useState } from 'react';
import { Helmet } from 'react-helmet'


import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box } from '@material-ui/core';
import Divider from "@material-ui/core/Divider";

import { textVide } from 'text-vide';
import TitledContainer from '../BuildingComponents/TitledContainer/TitledContainer';
import DrawerTitleContainer from '../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';
import { useTheme } from '@material-ui/core/styles';
import useStyles from "./IntroStyles";



function DownArrow(props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize="large"
                 component={ExpandMoreIcon}
                 id="HomepageHeaderDownArrow"
        />
    )
}

function ReRevampedIntroFunctional(props) {
    const [renderMsg, setRenderMsg] = useState(false);
    

    const setHeaderState = async () => {
        setRenderMsg(false);
        document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
        localStorage.setItem('headImageShown', 'true');
    };

    const waitAgain = async () => {
        setTimeout(setHeaderState, 500);
    };

    const waitSetState = async () => {
        await setTimeout(waitAgain, 500);
    };

    const bodyText = () => {
        const txt = "Happel Solutions provides businesses, artists, and freelancers with technological solutions that fortify existing business practices and customer bases. Transitioning and adapting established, proven business practices into their respective digital counterparts is a practice that can be trying and difficult given a lack of understanding. Equipped with a large degree of varied experience, Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with its clients; of which comes coupled with a respect for time-sensitive milestones.";
        const txtVideText = textVide(txt);
        return (
            <Typography>
                {txtVideText}
            </Typography>
        )
    }

    const bodyTextChatGPT = () => {
        const txt = "Happel Solutions provides businesses, artists, and freelancers with technological solutions that fortify existing business practices and customer bases. Transitioning and adapting established, proven business practices into their respective digital counterparts is a practice that can be trying and difficult given a lack of understanding. Equipped with a large degree of varied experience, Happel Solutions' approach to development permits an unmitigated sense of security and satisfaction via the means of both clear and concise communication with its clients; of which comes coupled with a respect for time-sensitive milestones.";
        const txtVideText = textVide(txt);

        // Wrap the innerHTML with a <span> tag to ensure proper rendering and avoid unexpected issues.
        return (
            <span style={{fontFamily: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif!important"}} dangerouslySetInnerHTML={{ __html: txt }} />
        )
    }

    const textVideThisText = (txt) => {
        const txtVideText = textVide(txt);
        return (
            <>
                <span dangerouslySetInnerHTML={{ __html: txtVideText }} />
            </>
        )
    }

    const { classes } = props;
    const { directory } = props;
    const theme = useTheme();
    const viewportHeight = window.innerHeight;

    const bodyTxt = bodyText();
    const handleChange = () => {
        // Set expansionPanel state, so if fading out main component, replace with expansionPanel
        //alert('Handle Change');
    };

    const getContent = () => {
        return (

            
            <Box style={{paddingTop: "0!important", backgroundColor: "#f2f1f1", /*padding: "1rem 1rem"*/}}>
                
                <Helmet>
                    <title>Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)</title>
                    <meta property="og:title" content="Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="twitter:title" content="Best In Class: Tri-State Area's Choice for Software, Web, and IT | Happel Solutions (Long Island, New York, Connecticut, New Jersey)" />
                    <meta name="description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta name="twitter:description" content="Located on Long Island, Happel Solutions Is the Tri-State Area's Foremost Choice in Software Development, Web Design and Development, IT Services, and Consultation." />
                    <meta property="og:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta name="twitter:image" content="https://happel.solutions/static/media/SocialMediaBannerOverlayLogoEightBit.0ce9e058ae98c5fdfbd3.png"/> 
                    <meta property="og:url" content="https://www.happel.solutions/" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                
                <Box style={{ padding: "1rem"}}>
                    
                    <Box style={{marginTop: "14rem", marginBottom: "14rem", marginLeft: '2rem'}}>
                            <Typography className="Berk" variant={'h1'} sx={{  fontFamily: "BerkeleyMonoTrial-Regular", fontStyle: "italic", fontSize: "2rem",}}> {textVideThisText("HAPPEL SOLUTIONS:<br/><br/>  <n/> A technology solutions company specializing in various forms of development, tech services, and consultation.")} </Typography>
                    </Box>          
                    
                    
                    

                </Box>

                <Divider style={{   }}/>

                {viewportHeight <= 900 ? (

                    <Box style={{ marginTop: "14rem", marginBottom: "14rem"}}>
                        <Typography id="mobile" style={{fontSize: "1.05rem", fontFamily: "BerkeleyMonoTrial-Regular", paddingRight: "2rem", marginBottom: "3rem", marginLeft: '4rem', marginRight: 'auto', display: 'table'}}> OUR SERVICES </Typography>
                        <Box style={{  listStyle: 'decimal-leading-zero', marginLeft: '4rem', marginRight: 'auto', display: 'table', paddingRight: '3.5rem' }}>
                            <Typography style={{ }}>
                                <li>&nbsp;Website Development</li>
                                <li>&nbsp;Software Development</li>
                                <li>&nbsp;Data Backup Solutions</li>
                                <li>&nbsp;Consultation Services</li>
                                <li>&nbsp;Media Management</li>
                                <li>&nbsp;and more</li>
                            </Typography>
                        </Box>
                    </Box>

                ) : (

                    <Box style={{ marginTop: "4rem", marginBottom: "4rem"}}>
                    <Typography id="mobile" style={{fontWeight:'bold', paddingRight: "2rem", marginBottom: "1rem", marginLeft: '4rem', marginRight: 'auto', display: 'table'}}> OUR SERVICES </Typography>
                    <Box style={{  listStyle: 'decimal-leading-zero', marginLeft: '4rem', marginRight: 'auto', display: 'table', paddingRight: '3.5rem' }}>
                        <Typography style={{ }}>
                            <li>&nbsp;Website Development</li>
                            <li>&nbsp;Software Development</li>
                            <li>&nbsp;Data Backup Solutions</li>
                            <li>&nbsp;Consulation Services</li>
                            <li>&nbsp;Media Management</li>
                            <li>&nbsp;and more</li>
                        </Typography>
                    </Box>
                </Box>

                )}

                



                <Divider style={{marginLeft: '1rem', marginRight: '1rem'}}/>
                
                            
                <Box style={{}} >
                    
                    <Typography className={classes.epH4} style={{ fontSize: "1.05rem", fontFamily: "BerkeleyMonoTrial-Regular", marginLeft: "3.5rem", marginTop: "13rem", paddingBottom: '0rem'}}>  OUR MISSION</Typography>
                    
                    <Typography style={{textAlign: "justify", /*wordSpacing: '-2px',*/ fontSize: "14px", margin: "3rem 3.5rem 14rem",  fontFamily: "'Univers LT Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif!important"}}> {bodyTextChatGPT()}  </Typography>
                    
                    <Divider style={{ marginRight: '6rem'}}/>
                    
                    <Typography style={{maxWidth: '514px', marginTop: '4rem', marginLeft: '4rem', marginBottom: "-1rem", marginRight: '4rem'}}> <i style={{ }}> Perfection is our goal—excellence will be tolerated. </i>  </Typography>
                        

                </Box>

                          
                        

                    
              


            </Box>

        )
    };

    const content = getContent();

    const container_details = {
        title: 'About Us',
        emoji: '💬',
    };

    return (
        <> 
        {window.location.pathname === '/old' ? (
            <TitledContainer id={'AboutContainer'} className={props.classes.IntroContainer}
                style={{ background: '#353535'}}
                { ...
                    
                    {
                        classes: classes,
                        details: container_details,
                        content: content,
                        onchange: {handleChange}
                    }
                }
            />
        ) : (
            <DrawerTitleContainer id={'AboutContainer'} className={props.classes.IntroContainer}

                style={{
                    background: '#353535',
                }}
                {... {
                            classes: classes,
                            directory: directory,
                            details: container_details,
                            content: content,
                            onchange: {handleChange}
                }}
            /> 
        )}
            </>
    );
}

export default withStyles(useStyles, { withTheme: false })(ReRevampedIntroFunctional);

/**
<Box style={{ marginTop: "4rem", marginBottom: "4rem"}}>
                        <Typography id="mobile" style={{fontStyle:'bold', fontFamily: "BerkeleyMonoTrial-Regular", marginLeft: 'auto', marginRight: 'auto', display: 'table'}}> WHAT WE CAN DO FOR YOU </Typography>
                        <Box style={{ listStyle: 'decimal-leading-zero', marginLeft: 'auto', marginRight: 'auto', display: 'table' }}>
                            <Typography  style={{fontFamily: "BerkeleyMonoTrial-Regular",}}>
                                <li>{textVideThisText("Website Development")}</li>
                                <li>{textVideThisText("Software Development")}</li>
                                <li>{textVideThisText("Data Backup Solutions")}</li>
                                <li>{textVideThisText("Consulation Services")}</li>
                                <li>{textVideThisText("Media Management")}</li>
                                <li>{textVideThisText("and more")}</li>
                            </Typography>
                        </Box>
                    </Box>
 */