import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  h3: {
    fontSize: '1rem',
  },

  toolbar: theme.mixins.toolbar,

  gridCartContainer: {
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
  gridCartItem: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '75%',
      //textAlign: '-webkit-center'
    },
  },
  title: {
    marginTop: '2rem',
    marginBottom: '0.7rem',
    fontSize: '1.3rem',
    fontFamily: 'AvenirLTStd-Book',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('xs')]: {
      
    },
  },
  emptyButton: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '20px',
    },
  },
  checkoutButton: {
    minWidth: '150px',
  },
  link: {
    textDecoration: 'none',
  },
  cardDetails: {
    display: 'flex',
    //marginTop: '10%',
    marginTop: '1.5rem',
    width: '100%',
    justifyContent: 'space-between',

    flexDirection: 'column',
    marginLeft: '3rem',
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      margin: '15px',
    }
  },
  checkoutButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '2rem',
    marginTop: '1rem',
  },
  subtotal: {
    fontSize: '1rem',
    fontWeight: '500',
    paddingBottom: '0.5rem',
    fontFamily: 'AvenirLTStd-Book',
  },

}));
