import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typist from 'react-typist';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TitledContainer from '../../BuildingComponents/TitledContainer/TitledContainer';
import DrawerTitleContainer from '../../BuildingComponents/FixedDrawerMenu/DrawerTitleContainer';
import BannerImage from "../../features/BannerImage";
import BannerVideo from "../../features/BannerVideo";

import useStyles from "./styles";

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize ={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {renderMsg: false};
        this.myRef = React.createRef();                            
        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }


    render () {
        const { classes } = this.props;
        const { directory } = this.props;

        const handleChange = () => {
            // Set expansionPanel state, so if fading out main component, replace with expansionPanel
            //alert('Handle Change');
        };


        /**
         <BannerVideo style={{
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                    }}/>
         **/


        /**
         * <li
         style={{
                                                textAlignLast: 'end',
                                                marginRight: '2rem',
                                            }}>Restaurant Online Ordering Platform</li>
         * @returns {*}
         */
         const getContent = () => {
            return (

                <React.Fragment>



                    <BannerImage style={{
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                    }}/>

                    <div className={classes.sectionBackground}>

                        <div className={classes.boxContent}>

                            <div className={classes.subsectionTitleAndBody}>

                                <div className={classes.sectionBackgroundWhite}>

                                    <div className={classes.boxContent}
                                    style={{
                                        boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
                                    }}>

                                        <div className={classes.epDescriptionIndentForOpener}
                                             style={{
                                                 textAlign: 'center',
                                                 marginBottom: '0.5rem',
                                             }}
                                        >
                                            Happel Solutions is a technology solutions company specializing in various forms
                                            of development, tech services, and consultation options.
                                        </div>
                                    </div>

                                </div>
                                
                                <div className={classes.epDescriptionIndent1}>
                                    <h4 className={classes.epH4}
                                    style={{marginLeft:'0rem'}}> What can we do for you? </h4>
                                    <div className={classes.epListLeft}
                                         style={{
                                             marginLeft: '1rem',
                                             listStyle: 'circle'

                                         }}>
                                        <li>Website Development</li>
                                        <li>Software Development</li>
                                        <li>Data Backup Solutions</li>
                                        <li>Consulation Services</li>
                                        <li>Social Media Management</li>
                                        <li>and more</li>
                                    </div>
                                </div>

                                <div className={classes.whatCanWeDoForYouContainer} style={{display: 'inline-block'}}>
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>
                                    <h4 className={classes.epH4}> Our Goal </h4>
                                    <div className={classes.epDescriptionIndent1}
                                         style={{marginTop: '0vh'}}
                                    >


                                        Happel Solutions provides businesses, artists, and freelancers with technological
                                        solutions that fortify existing business practices and customer bases.
                                        Transitioning and adapting established, proven business practices into their
                                        respective digital analogs is a practice that can be trying and difficult given
                                        a lack of understanding. Equipped with a large degree of varied experience,
                                        Happel Solutions' approach to development permits an unmitigated sense of security
                                        and satisfaction via the means of both clear and concise communication with it's
                                        clients; of which comes coupled with a respect for time-sensitive milestones.
                                        <br/>
                                        <br/>
                                        <Divider style={{ marginRight: '6rem'}}/>
                                        <i style={{position: 'absolute'}}> Perfection is our goal, excellence will be tolerated </i> <br/>
                                        <i>~ J. Yahl</i>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>


                </React.Fragment>

            )
        };

        const content = getContent();

        const container_details = {
            title: 'About Us',
            emoji: '💬',
        };

        return (
            <> 
            {window.location.pathname === '/old' ? (
                <TitledContainer id={'AboutContainer'} className={classes.IntroContainer}
                    style={{ background: '#353535'}}
                    { ...
                        
                        {
                            classes: classes,
                            details: container_details,
                            content: content,
                            onchange: {handleChange}
                        }
                    }
                />
            ) : (
                <DrawerTitleContainer id={'AboutContainer'} className={classes.IntroContainer}

                    style={{
                        background: '#353535',
                    }}
                    {... {
                                classes: classes,
                                directory: directory,
                                details: container_details,
                                content: content,
                                onchange: {handleChange}
                    }}
                /> 
            )}
                </>
        );
    }


    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}
export default withStyles(useStyles, { withTheme: false })(Intro);


/**
 *  Removing when implementing styling from websites.js
 *
 <p className={classes.FirstBodyParagraph} id={'infoSectionBodyStart'}
 style={{
                           fontSize: '1.0rem',
                           wordSpacing: '0.19rem',
                       }}
 >


 Happel Solutions is a software and web development solutions company purposed on
 facilitating affordable access to a host of modern, advanced technologies ranging
 from websites to complete software packages.
 <br/>

 </p>

 **/

/**  Paper and Image Component*/
/*
      <Paper elevation={2}>
                    <img className={classes.TestingImage} src={'../../laptop.jpg'}/>
                </Paper>
 */

//<p className={classes.SubsectionHeaderFirst}> 💬 About Us </p>

/**
 <Divider className={classes.infoSectionEndDivider}/>
 <p className={classes.SubsectionHeader}> Our Location </p>
 <p className={classes.infoSectionBody} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 </p>
 <Divider className={classes.infoSectionEndDivider}/>

 <p className={classes.SubsectionHeader}> Learn More </p>
 <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tempor incididunt ut labore et dolore magna
 aliqua <Link href="/#">Lorem Ipsum</Link> page.
 </p>
**/